.buttonContainer {
  padding: 0 0 1rem;
}

.modalContent {
  & > * {
    margin-bottom: 0.5rem;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
}
