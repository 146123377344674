.core-ui-formik-dropdown-select {
  &__label {
    display: block;
    margin: 0 0 10px 0;
    padding: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: var(--light-grey-color);
    font-family: var(--font-family);
  }

  &__errorMessage {
    display: block;
    font-size: 14px;
    line-height: 18px;
    margin-top: 5px;
    color: var(--error-color);
  }
}
