.pdsError {
  align-items: center;
  display: flex;
  width: 100%;

  &__icon {
    display: block;
    fill: var(--error-color);
    margin-right: 4px;
  }

  &__retry {
    display: block;
    margin-left: 48px;
  }
}
