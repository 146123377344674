@import '../global-constants';

$icon-fill: var(--medkit-icon-fill, var(--medkit-primary, $primary));

.IconWrapper {
  display: flex;
  margin: 0;
}

.Icon {
  fill: $icon-fill;
}
