.core-page {
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 0 20px;
  }

  &__content {
    padding: 20px;
  }
}
