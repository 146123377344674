@import '../../global-constants';

$help-text-font-family: $font-family;
$help-text-color: $grey-700;

.HelpText {
  color: $help-text-color;
  margin-bottom: rem-calc(4);
  font-family: $help-text-font-family;
}
