.Alert {
  border: solid 2px var(--error-color);
  margin-bottom: 16px;
  position: relative;
  background-color: var(--active-grey);

  &:last-child {
    margin-bottom: 0;
  }

  &__items {
    flex-grow: 2;
    margin: 16px 0;
    overflow: auto;
  }

  &__icon {
    fill: var(--error-color);
    margin-bottom: 16px;
  }

  &__datetime {
    line-height: 22px;
    color: var(--grey-800);
  }

  &__timeRelative {
    font-size: 14px;
    margin-right: 4px;
  }

  &__message {
    margin: 8px 0 16px 0;
    font-weight: 900;
    line-height: 1.38;
    color: var(--error-color);
  }

  &__actionMenu {
    position: absolute;
    right: 10px;
    top: 10px;

    & :global(.core-ui-kebab__button) {
      border: none;
      background: transparent;
    }

    &:hover :global(.core-ui-kebab__button) {
      background-color: var(--white);
    }
  }
}
