@import '../../../../util/constants.scss';

.banner {
  @include buttonReset();
  width: 100%;

  & > * {
    align-items: flex-start;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    padding: 1rem 1.25rem;
  }
}

.content {
  margin-top: 0.75rem;
  text-align: left;
}
