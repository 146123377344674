@import '../global-constants';

// Local variables
$button-min-height: rem-calc(52);
$button-min-width: rem-calc(160);
$button-border-weight: rem-calc(4);
$button-font-weight: 600;
$button-font-size: rem-calc(16);
$button-font-family: $font;
$button-spacing-row: rem-calc(8);
$button-spacing-column: rem-calc(16);

// High level theme controls
$button-color-one: var(--medkit-button-color-one, $primary);
$button-color-two: var(--medkit-button-color-two, $purple50);
$button-color-three: var(--medkit-button-color-three, $background);
$button-color-four: var(--medkit-button-color-four, $grey-500);
$button-color-five: var(--medkit-button-color-five, $red120);
$button-color-six: var(--medkit-button-color-six, $red40);
$button-color-seven: var(--medkit-button-color-seven, 'transparent');

$button-outline-weight: var(--medkit-button-outline-weight, rem-calc(2));
$button-border-radius: var(--medkit-button-border-radius, rem-calc(8));
$button-line-height: var(--medkit-button-line-height, rem-calc(16));
$button-border-color: var(--medkit-button-border-color, transparent);

// Low level individual property theme controls
// Primary button
$button-primary-background-color: var(
  --medkit-button-primary-background-color,
  $button-color-one
);
$button-primary-background-color-active: var(
  --medkit-button-primary-background-color-active,
  $button-color-one
);
$button-primary-text-color: var(
  --medkit-button-primary-text-color,
  $button-color-three
);
$button-primary-border-color: var(
  --medkit-button-primary-border-color,
  $button-color-three
);
$button-primary-border-color-active: var(
  --medkit-button-primary-border-color-active,
  $button-color-two
);

// Secondary button
$button-secondary-background: var(
  --medkit-button-secondary-background-color,
  $button-color-three
);

$button-secondary-background-active: var(
  --medkit-button-secondary-background-color-active,
  $button-color-seven
);
$button-secondary-text-color: var(
  --medkit-button-secondary-text-color,
  $button-color-one
);
$button-secondary-border-color: var(
  --medkit-button-secondary-border-color,
  $button-color-one
);
$button-secondary-border-color-active: var(
  --medkit-button-secondary-border-color-active,
  $button-color-one
);

// Destructive button
$button-destructive-background: var(
  --medkit-button-destructive-background-color,
  $button-color-seven
);

$button-destructive-background-active: var(
  --medkit-button-destructive-background-color-active,
  $button-color-seven
);
$button-destructive-text-color: var(
  --medkit-button-destructive-text-color,
  $button-color-five
);
$button-destructive-border-color: var(
  --medkit-button-destructive-border-color,
  $button-color-five
);
$button-destructive-border-color-active: var(
  --medkit-button-destructive-border-color-active,
  $button-color-six
);

// Disabled button
$button-disabled-color: var(--medkit-button-disabled-color, $button-color-four);

/*
Base component for all buttons
---
The Button Generic is concerned only with the dimensions and placement of the block element and it's text
Colour should be controlled via one of the variants (i.e. Primary, Secondary, Destructive).
*/
.ButtonGeneric {
  align-items: center;
  background: transparent;
  box-sizing: border-box;
  border: none;
  border-radius: 0;
  cursor: pointer;
  display: inline-flex;
  font-family: $button-font-family;
  font-size: $button-font-size;
  font-weight: $button-font-weight;
  min-height: $button-min-height;
  justify-content: center;
  line-height: $button-line-height;
  min-width: $button-min-width;
  outline: none;
  padding-top: $button-border-weight;
  padding-bottom: $button-border-weight;
  padding-left: rem-calc(16);
  padding-right: rem-calc(16);
  position: relative;
  text-align: center;
  text-decoration: none;
  width: auto;
  border-top: solid $button-border-weight transparent;
  border-bottom: solid $button-border-weight transparent;
  margin-bottom: $button-spacing-row;
  margin-right: $button-spacing-column;
  vertical-align: text-bottom;
  z-index: 0;

  &::before {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    position: absolute;
    border-radius: $button-border-radius;
    z-index: -1;
  }

  &[disabled],
  &.ButtonIsLoading {
    cursor: not-allowed;
  }
}

.ButtonLabel,
.ButtonLoadingLabel {
  display: inline-flex;
  align-items: center;
}

.ButtonLoadingLabel {
  padding-left: rem-calc(4);
}

.ButtonPrimary,
.ButtonSecondary,
.ButtonDestructive {
  &:hover,
  &:focus {
    &:not([disabled]):not(.ButtonIsLoading) {
      top: 0;
      outline: none;
    }
  }
}

/*
Button varients
---
*/
.ButtonPrimary {
  color: $button-primary-text-color;

  &::before {
    background-color: $button-primary-background-color;
  }

  &:hover,
  &:focus {
    &:not([disabled]):not(.ButtonIsLoading) {
      outline: none;

      &::before {
        background-color: $button-primary-background-color-active;
        box-shadow: 0 0 0 $button-border-weight
          $button-primary-border-color-active;
      }
    }
  }

  .ButtonLoadingSpinner {
    stroke: $button-primary-text-color;
  }

  &[disabled],
  &.ButtonIsLoading {
    &::before {
      background-color: $button-disabled-color;
      box-shadow: 0 0 0 $button-border-weight $button-border-color;
    }
  }
}

.ButtonSecondary {
  color: $button-secondary-text-color;

  &::before {
    background-color: $button-secondary-background;
    color: $button-secondary-text-color;
    border: solid $button-outline-weight $button-secondary-text-color;
  }

  &:hover,
  &:focus {
    &:not([disabled]):not(.ButtonIsLoading) {
      &::before {
        background-color: $button-secondary-background-active;
        border-color: $button-secondary-border-color-active;
        box-shadow: 0 0 0 $button-border-weight
          $button-primary-border-color-active;
        outline: none;
      }
    }
  }

  .ButtonLoadingSpinner {
    stroke: $button-disabled-color;
  }

  &[disabled],
  &.ButtonIsLoading {
    color: $button-disabled-color;

    &::before {
      background-color: $button-secondary-background;
      border: solid $button-outline-weight $button-disabled-color;
      box-shadow: 0 0 0 $button-border-weight $button-border-color;
    }
  }
}

.ButtonDestructive {
  color: $button-destructive-text-color;

  &::before {
    background-color: $button-destructive-background;
    color: $button-destructive-text-color;
    border: solid $button-outline-weight $button-destructive-text-color;
  }

  &:hover,
  &:focus {
    &:not([disabled]):not(.ButtonIsLoading) {
      &::before {
        background-color: $button-destructive-background-active;
        box-shadow: 0 0 0 $button-border-weight
          $button-destructive-border-color-active;
        outline: none;
      }
    }
  }

  .ButtonLoadingSpinner {
    stroke: $button-disabled-color;
  }

  &[disabled],
  &.ButtonIsLoading {
    color: $button-disabled-color;

    &::before {
      background-color: $button-destructive-background;
      border: solid $button-outline-weight $button-disabled-color;
      box-shadow: 0 0 0 $button-border-weight $button-border-color;
    }
  }
}

.ButtonIcon {
  width: rem-calc(40);
  height: rem-calc(40);
  color: white;
  min-width: 0;
  margin: 0 0 0 rem-calc(8);

  &::before {
    border-radius: rem-calc(40);
  }

  .ButtonIconSvg {
    fill: $button-color-three;

    path {
      transform: translate(rem-calc(2), rem-calc(2));
    }
  }
}

/* Todo:
Remove these full-width / half-width styles, shift responsibility to layout component
*/
.ButtonHalfWidth {
  width: 49%;
  flex-grow: 1;

  @include less-than-tablet() {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: $size-small;
  }
}

.ButtonFullWidth {
  width: 100%;

  &.ButtonFullWidth + & {
    margin-top: 0;
    margin-left: 0;
  }
}
