.memberCard {
  position: relative;
}

.actions {
  display: flex;
  position: absolute;
  right: 0;
  top: 2rem;

  &__button {
    margin-right: 4px;
    width: 100%;
  }
}

.modal {
  width: 400px;
}

.actionSpinner {
  display: inline-block;
  margin-left: 5px;
}
