@import './BaseInput.variables';
@import './BaseInput.mixins';

.BaseInput {
  margin-bottom: $base-input-margin;
  margin-top: $base-input-margin;

  &_endAdornment {
    .BaseInput__input {
      padding-right: 3rem;
    }
  }

  &_disabled {
    opacity: 0.5;
  }

  &__container {
    position: relative;
  }

  &__input {
    box-sizing: border-box;
    width: $base-input-max-width;
    min-height: $base-input-height;
    padding-right: 1rem;
    padding-left: 1rem;
    border-width: $base-input-border-width;
    border-style: solid;
    border-color: transparent;
    margin-left: $base-input-border-width-offset;
    border-radius: $base-input-border-radius;
    font-size: $base-input-font-size;
    font-family: $base-input-font-family;
    background-color: $base-input-background;
    max-width: $base-input-max-width;
    box-shadow: inset rem-calc(0) rem-calc(0) rem-calc(0)
      $base-input-box-shadow-width $base-input-box-shadow-color;
    // webkit-appearence: none fixes a bug where our box-shadows do not render on iOS forms
    -webkit-appearance: none;

    &:hover {
      .BaseInput_disabled & {
        cursor: not-allowed;
      }
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border-color: white;
    }

    &:focus {
      border-color: $base-input-border-color-active;
      outline: none;
      box-shadow: inset 0 0 0 $base-input-box-shadow-width
        $base-input-box-shadow-color-active;
    }

    .BaseInput_error & {
      box-shadow: inset 0 0 0 $base-input-box-shadow-width
        $base-input-box-shadow-color-error-active;

      &:focus {
        border-color: $base-input-border-color-error-active;
      }
    }
  }

  &__label {
    @include BaseInputLabel();
  }

  &__adornment {
    position: absolute;
    bottom: 0;
    display: flex;
    height: $base-input-height;
    width: $base-input-height;
    justify-content: center;
    align-items: center;

    &_left {
      left: 0;
    }

    &_right {
      right: 0;
    }
  }

  &__visuallyHidden {
    @include visuallyHidden;
  }
}
