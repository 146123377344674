.commonSearches {
  margin-top: 2rem;
}

.pillContainer {
  margin: 1rem 0;

  @media (any-hover: none) {
    *:hover {
      background: none !important; /* stylelint-disable-line declaration-no-important */
      box-shadow: none !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}
