@import './../../styles/mixins.scss';

.UpdatePatientGpDetailsForm {
  @include box-sizing-border-box;

  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -7.5px 15px -7.5px;

    @media (max-width: 600px) {
      margin-bottom: 8px;
    }
  }

  &__column {
    padding: 0 7.5px;
    width: 100%;

    &--half {
      width: 50%;
    }

    &--gpConsent {
      padding-top: 35px;
    }

    @media (max-width: 600px) {
      & ~ & {
        margin-top: 8px;
      }

      &--half {
        width: 100%;
      }

      &--gpName {
        order: 2;
      }

      &--gpConsent {
        order: 1;
        padding-top: 0;
        margin-bottom: 13px;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__buttonWrapper {
    margin-right: 10px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}
