@import '../constants.scss';

.inputsWrapper {
  width: 100%;
  position: relative;
  display: flex;
  @include font();
}

.codeDisplay {
  position: absolute;
  z-index: 1;
  line-height: $input-height;
}

.countrySelect,
.phoneInput,
.selectDisplay {
  border: 1px solid $secondary-light;
  font-size: 17px;
  margin: 0;
  padding: 0;
  appearance: none;
  outline: none;
  @include font(300);

  &:focus,
  &.error:focus,
  &.success:focus {
    outline: none;
    border: 1px solid $focus-border;
  }

  &.error {
    border: 1px solid $error !important;
  }

  &.success {
    border: 1px solid $success !important;
  }
}

.selectDisplay {
  display: inline-block;
  width: calc(15% - 4px);
  text-align: center;
  background: white;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
  height: 46px;
  line-height: 48px;
  pointer-events: none;
  box-sizing: content-box;
  border-right: 0px;
}

.countrySelect {
  height: $input-height;
  width: 15%;
  opacity: 0;
  position: absolute;
  left: 0;
  border-right: 0px;

  &:focus {
    + .selectDisplay {
      border: 1px solid $focus-border;
    }
  }
}

.phoneInput {
  height: $input-height;
  position: relative;
  width: 85%;
  padding-top: 0;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 0;
  box-sizing: border-box;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    display: none;
  }
}
