@import '@babylon/design-tokens/all.scss';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5rem 0;
}

.description {
  color: $red120;
  margin-bottom: 1.5rem;
  max-width: 25rem;
  text-align: center;
}
