.Transaction {
  border: 1px solid var(--click-down-grey);
  margin: 8px 0;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 20px;

  &__Cell {
    div {
      width: 100%;
      text-overflow: ellipsis;
    }
  }

  a {
    color: var(--petroleum-teal);
    text-decoration: underline;
  }

  &__bold {
    font-weight: 500;
  }

  &__Status {
    text-transform: uppercase;
    font-weight: 900;
    display: inline;
    border: 1.5px solid black;
    border-radius: 4px;
    padding: 0 4px;
  }

  &__Positive {
    border-color: var(--success-color);
  }

  &__Negative {
    border-color: var(--error-color);
  }
}

.DropDown {
  background-color: var(--active-grey);
}
