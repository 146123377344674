@import '@babylon/design-tokens/all.scss';
@import '../../../../styles/helpers.scss';

.flow {
  border: 1px solid $grey50;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;

  &:last-of-type {
    margin-bottom: 2.5rem;
  }
}

.header {
  @include button-reset();
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  text-align: left;
  width: 100%;
}

.panel {
  margin: 0 1rem 1rem;
}

.arrow {
  background-color: var(--active-grey);
  border-radius: 0.25rem;
  height: 1.5rem;
  transform: rotate(90deg);
  width: 1.5rem;

  &_selected {
    transform: rotate(180deg);
  }

  & > * {
    fill: $grey-dark;
  }
}
