.container {
  margin: 3rem 0 0 0;
}

.header {
  margin-bottom: 0.25rem;
}

.title {
  margin-bottom: 1.25rem;
}

.stars {
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
}

.hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.starButton {
  cursor: pointer;
  display: flex;
  margin-right: 0.25rem;
}

.starContainer {
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  width: 2.75rem;
  height: 2.75rem;
}

.textarea {
  margin-bottom: 1.5rem;
}
