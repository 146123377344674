@import '@babylon/design-tokens/all.scss';

.risksContainer {
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  background-color: var(--active-grey);
  padding: 0.75rem 1rem 0.75rem 1rem;
  margin-bottom: 1.75rem;

  dl {
    margin: 0;
  }
}

.risksItemContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0.25rem 0 0.25rem 0;
}

.risksItem {
  margin: 0;
  display: flex;
  flex: 1;
  align-items: flex-start;
  line-height: 2rem;
}

.risksScoreColour {
  &_red {
    font-weight: bold;
    color: $red120;
  }

  &_amber {
    font-weight: bold;
    color: $orange100;
  }

  &_green {
    font-weight: bold;
    color: $green-d20;
  }
}
