.AlertDeleteConfirmationModal {
  & :global(.core-ui-modal__header) {
    display: none;
  }

  &__closeButton {
    align-self: flex-end;
    background-color: var(--active-grey);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    height: 40px;
    padding: 0;
    width: 40px;

    // visually hidden text, picked up by the screen-reader
    & > span {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }
  }

  &__header {
    grid-auto-rows: 40px;
  }

  &__heading {
    display: flex;
    align-items: center;

    & > h1 {
      font-weight: 300;
    }
  }

  &__body {
    margin: 24px 0;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
  }

  &__buttonReject {
    margin-right: 0;
  }
}
