.AddGuardian {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  &__label,
  &__input {
    margin-bottom: 8px;
  }

  &__label,
  &__input,
  &__button {
    width: 100%;
  }

  @media (min-width: 1280px) {
    flex-direction: row;
    flex-wrap: wrap;

    &__input {
      flex: 1;
      margin-bottom: 0;
      margin-right: 16px;
    }

    &__input,
    &__button {
      width: unset;
    }
  }
}
