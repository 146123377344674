.AddAlertButton {
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  min-width: 5rem;
}

.Alert__modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 70vh;
  min-width: 500px;
}

.Alert__items {
  flex-grow: 2;
  margin: 16px 0;
  overflow: auto;
}

.Alerts {
  margin: 0 0 56px 12px;
}

.AlertsList_Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loadingAlerts {
  margin-bottom: 56px;
}

.loadingBlock {
  height: 140px;
}

.ViewMore {
  grid-column-start: 2;
}
