$toggle-background: white;
$toggle-border-weight: 2px;
$toggle-border-color: var(--grey-50);
$toggle-border-color-checked: var(--primary-color);
$toggle-size: 20px;
$toggle-border-radius: calc(
  (#{$toggle-size} + #{$toggle-border-weight} / 2) - #{$toggle-border-weight}
);
$toggle-height: calc(#{$toggle-size} + #{$toggle-border-weight});
$toggle-width: calc(#{$toggle-size} * 1.8);
$toggle-padding: 0;

$toggle-background: var(--grey-50);
$toggle-background-checked: var(--primary-color);
$toggle-disabled-background: var(--grey-50);

$toggle-switch-background-color: white;
$toggle-switch-size: calc(#{$toggle-size} - #{$toggle-border-weight});

$toggle-switch-position-checked: calc(
  100% - (#{$toggle-size} - #{$toggle-border-weight})
);

$toggle-switch-position-checked-hover: calc(
  90% - (#{$toggle-size} - #{$toggle-border-weight})
);

$toggle-switch-position-unchecked-hover: 10%;

.core-checkbox {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &--inline {
    display: inline-flex;
  }

  &__input {
    width: 0;
    height: 0;
    opacity: 0;
  }

  &__element {
    display: block;
    position: relative;
    min-width: 14px;
    width: 14px;
    height: 14px;
    border-radius: 3px;
    border: 1px solid #dfe7eb;
    background: white;
    margin-right: 0.5em;

    &::after {
      display: table;
      position: absolute;

      transform-origin: center;

      transform: rotate(45deg) scale(0.5) translate(-50%, -50%);

      border: 2px solid #fff;
      border-top: 0;
      border-left: 0;

      content: '';
      opacity: 1;

      top: 50%;
      left: 20%;
      width: 3px;
      height: 7px;

      box-sizing: content-box;
    }

    &:hover {
      border: 1px solid #{darken(#dfe7eb, 10%)};
    }
  }

  &--disabled &__element {
    opacity: 0.6;
    pointer-events: none;

    * {
      pointer-events: none;
    }
  }

  &--checked &__element {
    background: var(--checkbox-checked-background-color);
    border-color: var(--checkbox-checked-background-color);

    &::after {
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
    }
  }

  &--indeterminate &__element::after {
    transform: rotate(0) scale(1) translate(0, 0);
    border: 0 solid #fff;
    border-bottom-width: 2px;
    width: 8px;
    left: 2px;
    top: -2px;
  }
}

.core-checkbox-pill {
  display: table;
  padding: 8px 12px;
  color: var(--grey-75);
  border: 1px solid var(--grey-50);
  font-size: 14px;
  letter-spacing: normal;
  outline: none;
  cursor: pointer;
  border-radius: 3px;

  &:focus {
    border: 1px solid var(--primary-60);
  }

  &--inline {
    display: inline-block;
    margin: 0 8px 8px 0;

    &:last-child {
      margin-right: 0;
    }
  }

  input[type='checkbox'] {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0;
    font: 0/0;
  }

  &--disabled {
    opacity: 0.6;
  }

  &--checked {
    color: var(--primary-100);
    background: var(--primary-50);
    border: 1px solid var(--primary-60);
  }

  & + & {
    margin-top: 8px;
  }

  &.core-checkbox-pill--inline + &.core-checkbox-pill--inline {
    margin-top: 0;
  }
}

.core-checkbox-switch {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__element {
    position: relative;
    width: $toggle-width;
    height: $toggle-height;
    margin-right: 12px;
    padding-top: 0;
    padding-right: $toggle-border-weight;
    padding-bottom: 0;
    padding-left: $toggle-border-weight;
    border: 0;
    border-radius: $toggle-border-radius;
    overflow: hidden;
    background: transparent;
    box-shadow: inset 0 0 0 $toggle-border-weight $toggle-border-color;

    &::before {
      position: absolute;
      top: 0;
      right: 100%;
      display: block;
      width: 250%;
      height: 100%;
      background-color: $toggle-background;
      content: '';
    }

    &::after {
      content: '';
      position: relative;
      top: $toggle-border-weight;
      left: 0;
      display: block;
      width: $toggle-switch-size;
      height: $toggle-switch-size;
      border-radius: $toggle-border-radius;
      background-color: $toggle-switch-background-color;
      box-shadow: 0 1px 4px 0 $toggle-border-color,
        0 3px 8px 0 $toggle-border-color;
      transition: left 280ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
  }

  &--checked &__element {
    &::before {
      background-color: $toggle-background-checked;
      right: 0;
    }

    &::after {
      box-shadow: 0 1px 4px 0 $toggle-border-color-checked,
        0 3px 8px 0 $toggle-border-color-checked;
      left: $toggle-switch-position-checked;
    }
  }

  &:not(&--disabled):hover:not(:focus) &__element {
    &::after {
      left: $toggle-switch-position-unchecked-hover;
    }
  }

  &--checked:not(&--disabled):hover:not(:focus) &__element {
    &::after {
      left: $toggle-switch-position-checked-hover;
    }
  }

  &--disabled {
    cursor: not-allowed;
  }

  &--disabled &__element {
    background: $toggle-disabled-background;
    opacity: 0.6;
  }
}
