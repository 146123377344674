.CreateNewPatientForm__backButton {
  min-width: 5rem;
}

.CreateNewPatientForm__submitButton {
  margin-right: 0;
}

.CreateNewPatientForm__instructions {
  padding-bottom: 24px;
  font-weight: 200;
  font-size: 14px;
  line-height: 20.33px;
}
