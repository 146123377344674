@import './legacy-ui-themes/style.scss';
@import './variables.scss';

@font-face {
  font-family: 'Avenir';
  src: url('./fonts/Avenir-Light.eot');
  src: url('./fonts/Avenir-Light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Avenir-Light.woff') format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'Avenir';
  src: url('./fonts/Avenir-Medium.eot');
  src: url('./fonts/Avenir-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Avenir-Medium.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Avenir';
  src: url('./fonts/Avenir-Heavy.eot');
  src: url('./fonts/Avenir-Heavy.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Avenir-Heavy.woff') format('woff');
  font-weight: 500;
}

@mixin flex-layout() {
  flex: 1;
  display: flex;
  flex-direction: column;
}

html,
body {
  @include flex-layout();
  color: var(--text);
  outline: none;
  margin: 0;
  padding: 0;
  font-family: 'Avenir', -apple-system, BlinkMacSystemFont, sans-serif;
  line-height: 1.6em;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
}

@media print {
  body:not(.allow-print) {
    display: none;
  }
}

html,
body,
#root {
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

*,
button,
input,
optgroup,
select,
textarea {
  font-family: 'Avenir', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

h2,
h3,
h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 500;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 22px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

button:focus {
  outline: none;
}

figure {
  margin: 0;
  padding: 0;
}

a {
  color: var(--anchor-color);
  text-decoration: none;
  cursor: pointer;
  outline: none;

  display: inline-flex;
  vertical-align: middle;

  &:hover {
    color: var(--hover-anchor-color);
  }
}

::-webkit-input-placeholder {
  font-weight: 300;
  color: $color-text-placeholder;
}

::-moz-placeholder {
  font-weight: 300;
  color: $color-text-placeholder;
}

:-ms-input-placeholder {
  font-weight: 300;
  color: $color-text-placeholder;
}

:-moz-placeholder {
  font-weight: 300;
  color: $color-text-placeholder;
}

button.babylon-component-library__Button__button {
  font-weight: 400;

  &.babylon-component-library__Button__button--outlined:hover {
    background-color: var(--hovered-outlined-button);
  }
}

.rc-tooltip {
  opacity: 1;
  background-color: var(--transparent);
  pointer-events: none;
  transition: opacity 100ms;

  * {
    /* stylelint-disable declaration-no-important */
    color: var(--white) !important;
    /* stylelint-enable declaration-no-important */
  }

  .rc-tooltip-inner {
    padding: 6px 10px;
    background-color: var(--tooltip);
    border: 0;
    border-radius: 4px;
    font-size: 12px;
    line-height: 1.5;
    font-weight: 500;

    &,
    * {
      font-size: 14px;
    }
  }
}

.rc-tooltip-hidden {
  opacity: 0;
}

.tooltip-fade-enter {
  opacity: 0;
}

.tooltip-fade-leave {
  opacity: 1;
}

.tooltip-fade-enter-active {
  opacity: 1;
}

.tooltip-fade-leave-active {
  opacity: 0;
}

.rc-tooltip-placement-top .rc-tooltip-content {
  position: relative;
  top: -5px;

  .rc-tooltip-arrow {
    border-top-color: var(--tooltip);
  }
}

.rc-tooltip-placement-left .rc-tooltip-content {
  position: relative;
  left: -5px;

  .rc-tooltip-arrow {
    border-left-color: var(--tooltip);
  }
}

.rc-tooltip-placement-right .rc-tooltip-content {
  position: relative;
  left: 5px;

  .rc-tooltip-arrow {
    border-right-color: var(--tooltip);
  }
}

.rc-tooltip-placement-bottom .rc-tooltip-content {
  position: relative;
  top: 5px;

  .rc-tooltip-arrow {
    border-bottom-color: var(--tooltip);
  }
}
