@import '../constants.scss';

.container {
  border: 1px solid $grey25;
  border-radius: 0.5rem;
  font-family: $font-family;
  padding: 1rem;

  &--busy {
    @include shimmerAnimation;
    min-height: 3.125rem;

    .title,
    .description {
      display: none;
    }
  }
}

.title,
.description {
  font-weight: normal;
  padding: 0;
  margin: 0;
}

.title {
  color: $grey100;
  font-size: 1rem;
  line-height: 1.375rem;
}

.description {
  color: $grey75;
  font-size: 0.875rem;
  line-height: 1.125rem;
  white-space: pre-line;
}

.title ~ .description {
  margin-top: 0.125rem;
}
