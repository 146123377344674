:root {
  --background-color: #f9faf9;
}

.noContent {
  background-color: var(--background-color);
  border-radius: 4px;
  color: var(--text-color);
  line-height: 22px;
  padding: 16px;
}

.error {
  border: red solid 1px;
}
