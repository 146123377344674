.tags {
  display: flex;
  flex-wrap: wrap;
}

.tag {
  background-color: var(--blue-grey);
  color: var(--white);
  font-size: 12px;
  font-weight: 900;
  line-height: 16px;
  margin-bottom: 0;
  margin-right: 8px;

  &--preferred {
    background-color: var(--preferred-color);
    color: var(--label-color);
  }
}
