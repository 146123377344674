.Form {
  padding: 24px 28px;
  position: relative;
}

.SearchButton {
  display: flex;
  width: 100%;
  flex: 1;
}

.ClearButton {
  color: var(--light-grey-color);
  font-size: 14px;
  text-decoration: underline;
  border: 0;
  background-color: var(--transparent);
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    color: var(--light-grey-color);
  }
}

.ActionsCell {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.switchButton {
  background-color: var(--transparent);
  border: 0;
  color: var(--grey-75);
  cursor: pointer;
  font-size: 14px;
  margin-right: 16px;
  position: absolute;
  right: 0;
  text-decoration: underline;

  &:hover {
    color: var(--primary-color);
  }
}
