.listViewWrapper {
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  background: #fff;

  tr:last-of-type td {
    border: 0;
  }

  td.actions {
    width: 1%;
  }

  tfoot tr:last-of-type td {
    border-top: 1px solid #dfdfdf;

    input {
      outline: none;
      position: relative;
      top: 2px;
    }
  }

  .insertRow {
    td {
      vertical-align: middle;
    }

    input {
      display: block;
      width: 100%;
      border: 0;
      background: transparent;
    }

    select {
      appearance: none;
      border: 0;
      outline: 0;
      background: transparent;
      padding-left: 0;
      font-weight: 300;
      color: #333;
      width: 100%;
    }

    &.insertAlert {
      background-color: rgba(255, 0, 0, 0.2);
    }

    &:not(.insertAlert) {
      background-color: rgba(255, 0, 0, 0);
      transition: background-color 1s linear;
    }

    .validationError input,
    .validationError input::placeholder {
      color: lighten(#f00, 10);
    }

    :global {
      .Select-control {
        width: 100%;
        height: 25px;
        line-height: 25px;

        .Select-input {
          padding-left: 0;
          height: 25px;
          line-height: 25px;

          input {
            padding: 4px 0 0 0;
            position: relative;
          }
        }
      }

      .Select-value {
        padding-left: 0;
        padding-right: 0;
        width: calc(100% - 12px);
      }
    }
  }
}
