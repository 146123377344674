@import '../../../design-tokens/all.scss';

.container {
  width: 100%;
  display: flex;

  &__barbell {
    .item:nth-child(2) {
      flex-grow: 1;
    }

    &--mediumSides {
      .item:nth-child(1),
      .item:nth-child(3) {
        flex-basis: 15%;
      }
    }

    &--largeSides {
      .item:nth-child(1),
      .item:nth-child(3) {
        flex-basis: 25%;
      }
    }
  }

  &__breakpoint--tablet {
    @include less-than-tablet {
      &.container {
        display: block;
      }

      .item:nth-child(1),
      .item:nth-child(2),
      .item:nth-child(3) {
        flex-basis: 100%;
      }
    }
  }

  &__spacing {
    &--xs {
      & > .item {
        margin: $space--xs;
      }
    }

    &--s {
      & > .item {
        margin: $space--s;
      }
    }

    &--m {
      & > .item {
        margin: $space--m;
      }
    }

    &--l {
      & > .item {
        margin: $space--l;
      }
    }

    &--xl {
      & > .item {
        margin: $space--xl;
      }
    }

    &--xxl {
      & > .item {
        margin: $space--xxl;
      }
    }
  }
}

.item {
  display: flex;
  position: relative;
  align-items: center;
}

.grow {
  flex-grow: 1;
}
