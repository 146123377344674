@import '../../util/constants';

.root {
  flex: 0 0 auto;
  overflow: hidden;
}

.inner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 56px;
  max-width: $header-width;
  margin: auto;
  padding: 0;
}

.leftAccessoryV2 {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  padding: 1rem 0 1rem 1rem;
  margin-right: auto;
}

.centreAccessory {
  display: flex;
  margin: 0 1rem;

  img {
    height: 1.875rem;
    max-width: 4rem;
  }
}

.rightAccessoryV2 {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding: 1rem 1rem 1rem 0;
  margin-left: auto;
}
