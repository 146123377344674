.regionsViewWrapper {
  li.newRegion {
    background-color: #e5ffeb;

    &:hover {
      background-color: darken(#e5ffeb, 3);
    }
  }

  .insertAlert tfoot tr td {
    background-color: rgba(255, 0, 0, 0.1);

    input {
      color: rgba(255, 0, 0, 0.6);
    }

    input::placeholder {
      color: rgba(255, 0, 0, 0.6);
    }
  }

  .deleteButton {
    border: 0;
    background: transparent;
    float: right;
    font-size: 12px;
    color: #919191;

    &:hover {
      color: #484848;
    }

    &:active {
      color: #000;
    }
  }

  :global {
    .list-wrapper > ul {
      min-height: 243px;
    }

    .widget-packaging td:nth-child(3) {
      width: 50%;
    }

    tr .restorePackaging {
      display: none;
    }

    tr.new-item {
      background-color: #e5ffeb;
    }

    tr.removed-item {
      background-color: rgba(255, 0, 0, 0.1);

      .removePackaging {
        display: none;
      }

      .restorePackaging {
        display: block;
      }
    }

    .toolbar-wrapper {
      padding: 0;

      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 10px;
      }
    }

    .widget-flags .babylon-component-library__Checkboxgroup__checkbox {
      display: inline-block;
      width: auto;
      margin-right: 30px;
    }

    .Select.is-focused:not(.is-open) > .Select-control {
      box-shadow: none;
      background: transparent;
    }

    .Select-control {
      border: 0;
      height: 30px;
      padding: 0;
      box-sizing: border-box;
      width: 80px;
      outline: none;
      background: transparent;

      .Select-value,
      .Select-input {
        line-height: 30px;
        height: 30px;
      }
    }

    .Select-menu-outer {
      width: 310px;
    }

    .Select-option {
      white-space: nowrap;
    }

    .Select-placeholder {
      line-height: 30px;
    }

    .Select-menu-outer {
      width: auto;
    }
  }
}
