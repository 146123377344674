.tagWrapper {
  line-height: 20px;
  display: inline-block;
}

.tag,
%tag {
  font-size: 12px;
  font-weight: 500;
  margin-right: 4px;
}

.consumerNetworkTag {
  @extend %tag;

  background-color: var(--primary-color);
  color: var(--white-color);

  &--queued {
    background-color: var(--cool-blue-grey-color);
  }
}

.familyTag {
  @extend %tag;

  background-color: var(--light-pink-color);
  color: var(--white-color);
}

.minorTag {
  @extend %tag;

  background-color: var(--warning-color);
  color: var(--white-color);
}

.idNotVerifiedTag {
  @extend %tag;

  background-color: var(--error-color);
  color: var(--white-color);
}

.ownerTag {
  @extend %tag;

  background-color: var(--white-color);
  border: 1px solid var(--light-pink-color);
  color: var(--text-color);
}
