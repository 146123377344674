.copiable__copyToClipboardButton {
  font-size: 16px;
  line-height: 24px;
}

.unconfirmedLabel {
  color: var(--grey-75);
  margin-bottom: 5px;
}

.IDList {
  display: grid;
  grid-template-columns: auto auto;
  line-height: 24px;
  margin: 0;

  & dt {
    font-weight: bold;
    margin-right: 5px;
  }

  & dd {
    margin: 0;
  }

  & .babylonUuid span {
    display: inline-block;
    width: 29%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
