.membersSearchView__header {
  margin-bottom: 24px;
}

.Snackbar {
  left: calc(50% + 100px);
}

.appLayout__inner {
  padding: 0 24px;
}

.searchResultHeaderContainer {
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  margin-top: 10px;
}

.searchResultHeader {
  display: flex;
  align-items: center;
}

.searchResultFooter {
  display: flex;
  justify-content: flex-end;
}
