.core-ui-filter-summary-heading {
  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  &__heading {
    display: block;
    font-size: 18px;
    line-height: 19px;
    font-weight: 900;
    margin: 0;
    padding: 0;
  }

  &__meta {
    list-style: none;
    margin: 0 0 0 15px;
    padding: 0;
    display: flex;
    flex-direction: row;
  }

  &__metaItem {
    display: flex;
    flex-direction: row;

    &:after {
      display: block;
      content: '\007C';
      margin: 0 5px;
      color: var(--core-ui-filter-summary-heading-meta-text-color);
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  &__metaText {
    display: block;
    font-size: 14px;
    line-height: 16px;
    color: var(--core-ui-filter-summary-heading-meta-text-color);

    &--number {
      margin-right: 5px;
      color: var(--core-ui-filter-summary-heading-meta-number-color);
      font-weight: 900;
    }
  }
}
