.core-card {
  background-color: var(--white);
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  box-shadow: 0 4px 9px 3px #ebf2f9;
  padding: 16px;

  &__header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    align-items: center;
  }

  &__title {
    display: flex;
    margin: 0;
    flex: 1;
  }

  & &__tag {
    // we need `& &` this to get property priority
    margin-left: 10px;
    align-self: center;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  &--padding-none {
    padding: 0;
  }

  &--padding-sm {
    padding: 8px;
  }

  &--padding-lg {
    padding: 24px;
  }

  &--padding-xlg {
    padding: 32px;
  }

  & + & {
    margin-top: 32px;
  }
}
