.core-ui-formik-date-picker {
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }

  &__label {
    display: block;
    margin: 0 0 10px 0;
    padding: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: var(--light-grey-color);
  }

  &__errorMessage {
    display: block;
    font-size: 14px;
    line-height: 18px;
    margin-top: 5px;
    color: var(--error-color);
  }
}
