/* stylelint-disable declaration-no-important */
@import '../constants.scss';

.richLink {
  border-radius: $border-radius;
  border: 1px solid $grey75;
  display: inline-block;
  margin: 0;
  padding: 15px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: background-color $transition-time $transition-ease;
  width: 100%;
  background: none;

  &:hover,
  &:focus {
    background: $grey10;
    cursor: pointer;

    /* this !important beats .chatbot a:hover styles when they are included last
    when the module is embedded in marketing */
    text-decoration: none !important;

    .richLink__title {
      color: $quaternary-d10;
    }
  }

  strong,
  span {
    display: block;
  }

  &__title,
  &__summary,
  &__extra {
    font-size: 16px;
    margin: 0;
    padding: 0;
  }

  &__title {
    color: $quaternary;
    margin-bottom: 5px;
    position: relative;
    transition: color $transition-time $transition-ease;

    &:only-child {
      margin-bottom: 0;
    }
  }

  &__summary {
    color: $secondary-dark;
    margin: 10px 0;
  }

  &__extra {
    color: $secondary;
    font-size: 14px;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}
