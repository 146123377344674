@mixin createInputIntentClass($intent) {
  .core-textarea--#{$intent} {
    border: 1px solid var(--input-#{$intent}-border-color);

    &.core-textarea--focused {
      box-shadow: 0 0 1px 2px var(--input-#{$intent}-active-outline-color);
    }
  }
}

.core-textarea {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 260px;
  font-size: 14px;
  border: 1px solid var(--input-border-color);
  border-radius: 3px;
  color: var(--input-color);
  background: var(--input-background);

  textarea {
    width: 100%;
    font-size: 13px;
    font-stretch: 100%;
    font-weight: 400;
    letter-spacing: normal;
    background: transparent;
    margin: 0;
    outline: 0;
    border: 0;
    color: var(--input-color);

    padding: 0.25em 0.5em;
    line-height: 2;

    text-size-adjust: 100%;
    box-sizing: border-box;
    resize: none;
  }

  &__prefixIcon,
  &__suffixIcon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__prefixIcon {
    padding-left: 0.5em;
  }

  &__suffixIcon {
    padding-right: 0.5em;
  }

  .core-spinner svg circle {
    // stylelint-disable-next-line declaration-no-important
    stroke: var(--input-color) !important;
  }

  &--fill {
    width: 100%;
  }

  &--disabled {
    opacity: 0.6;
    pointer-events: none;

    * {
      pointer-events: none;
    }
  }

  &--hover {
    border: 1px solid var(--input-hover-border-color);
  }

  &--focused {
    box-shadow: 0 0 0 2px var(--input-active-outline-color);
    border: 1px solid var(--input-active-border-color);
  }

  &--autoresize {
    height: auto;
    overflow: auto;

    textarea {
      height: auto;
      overflow: auto;
    }
  }

  ::-webkit-input-placeholder {
    font-weight: 400;
    color: var(--input-placeholder-color);
  }

  ::-moz-placeholder {
    font-weight: 400;
    color: var(--input-placeholder-color);
  }

  :-ms-input-placeholder {
    font-weight: 400;
    color: var(--input-placeholder-color);
  }

  :-moz-placeholder {
    font-weight: 400;
    color: var(--input-placeholder-color);
  }
}

@include createInputIntentClass('error');
@include createInputIntentClass('warning');
@include createInputIntentClass('success');
