.core-radio-group {
  margin-bottom: 12px;

  &__list {
    .core-radio {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
