.SickNote {
  padding: 16px;
  margin: 10px 0;
  background-color: var(--active-grey);
  border-radius: 8px;
}

.Container {
  padding-top: 0;
}

.TitleContainer {
  padding: 30px 10px 10px 10px;
}

.Title {
  color: var(--neutral-grey);
}

.NoNotesMessage {
  color: var(--grey-600);
  font-style: italic;
}
