$icon-width: 0.7em;

.core-expandable-anchor {
  &__icon {
    margin: 1px 4px 1px 1px;
    height: 0.5em;
    width: $icon-width;
    fill: var(--action-green-type);
    transition: all 0.2s ease;
    transform: rotate(-90deg);

    &--expanded {
      transform: rotate(0);
    }
  }

  &__expanded-section {
    height: auto;
    max-height: 0;
    box-sizing: border-box;
    overflow: hidden;
    transition: max-height 0.2s ease;

    &--left-pad {
      padding-left: calc(#{$icon-width} + 5px);
    }
  }
}
