.AddAppointmentCreditModal {
  &__closeButton {
    align-self: flex-end;
    background-color: var(--active-grey);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    height: 40px;
    padding: 0;
    width: 40px;

    // visually hidden text, picked up by the screen-reader
    & > span {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }
  }

  &__snackbar {
    // The snackbar will appear under the modal backdrop if this is
    // not set absurdly high!
    z-index: calc(var(--zindex-snackbar) * 20);
  }
}

.AddAppointmentCreditForm {
  &__Professions {
    border: 1px solid var(--grey-110);
    border-radius: 4px;
    overflow-y: scroll;
    height: 10rem;
    padding: 24px 16px;

    & > label {
      display: flex;
      margin-bottom: 8px;
      margin-top: 0;
    }
  }

  & > .FieldLabel {
    display: inline-block;
    margin-bottom: 4px;
    margin-top: 24px;
  }
}

.ModalContent {
  width: 464px;
  padding: 2px 10px;
}

.CreateCreditButton {
  background: var(--dark-purple);
  margin-top: 40px;

  &:hover {
    background: var(--babylon-violet);
  }
}
