.drugAudits {
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  background: #fff;
  margin-top: 20px;

  :global {
    tr:last-child td {
      border-bottom: 0;
    }
  }
}

.rightRow {
  text-align: right;
  padding-top: 20px;
}
