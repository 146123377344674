@import '../../../util/constants';

/* Export this value to the CSS Modules to access them in JS */
/* stylelint-disable */
@value marginBottom: #{$question-margin-bottom};
/* stylelint-enable */

.inputWrapper {
  position: relative;

  input {
    &::-ms-clear {
      display: none;
    }
  }
}

.inputWrapperV1 {
  margin-bottom: 0.75rem;
}

.inputWrapperV2 {
  margin: 1rem 0 2.25rem;
}

.clear {
  @include buttonReset();
  color: inherit;
  font-size: 15px;
  font-weight: 300;
  display: flex;
  align-items: center;
}

.letter {
  font-size: 19px;
  font-weight: bold;
  padding: 29px 0 0 9px;
}

.conditionContainer {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  display: flex;
}

.condition {
  @include buttonReset();
  align-items: center;
  color: inherit !important; /* stylelint-disable-line declaration-no-important */
  display: flex;
  font-size: 17px;
  justify-content: space-between;
  padding: 0 1rem;
  text-align: start;
  text-decoration: none !important; /* stylelint-disable-line declaration-no-important */
  width: 100%;

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.noResults {
  & > * {
    margin-bottom: 0.5rem;
  }
}

.conditionsList {
  display: block;
  height: 100%;
}

.letterTop {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding: 1rem;
  margin-top: 0.75rem;
}

.letterBottom {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
