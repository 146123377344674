@import '../../util/constants.scss';

.buttonListV2 {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.buttonV2 {
  margin-bottom: 1rem;
  margin-right: 0;
  width: 100%;

  @include fromTablet {
    width: auto;
    margin-right: 1rem;
  }
}
