@import '../BaseInput/BaseInput.mixins';

.Fieldset {
  border: 0;
  padding: 0;
  margin: 0;

  &Legend {
    @include BaseInputLabel();

    padding: 0;
  }
}
