.appBar {
  &__wrapper {
    background: var(--white-color);
    box-shadow: var(--white-color) 0 0 24px 20px;
    padding: 32px 24px 0 24px;
    display: flex;
    top: 0;
    z-index: var(--zindex-topBar);
    flex-direction: row;
    justify-content: space-between;
    position: sticky;
    width: 100%;
  }

  &__logo {
    height: 26px;
  }
}
