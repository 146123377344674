@import '../constants.scss';

.base {
  font-family: $font-family;
  margin: 0;
}

.size-1 {
  font-size: 48px;
  font-weight: bold;
  line-height: 1.33;
}

.size-2 {
  font-size: 40px;
  font-weight: bold;
  line-height: 1.4;
}

.size-3 {
  font-size: 34px;
  font-weight: bold;
  line-height: 1.4;
}

.size-4 {
  font-size: 24px;
  font-weight: normal;
  line-height: 1.33;
}

.size-5 {
  font-size: 20px;
  font-weight: lighter;
  line-height: 1.4;
}
