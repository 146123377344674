.ModalContainer {
  max-width: 450px;
  width: 100%;

  &__title {
    padding-bottom: 24px;
    color: var(--grey-900);
  }

  &__cta {
    background-color: var(--dark-purple);
  }
}
