@import './../../styles/mixins.scss';

.AllConsultationsFiltersForm {
  @include box-sizing-border-box;

  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -7.5px 15px -7.5px;
  }

  &__column {
    padding: 0 7.5px;
    width: calc(100% / 3);

    @media (max-width: 1000px) {
      width: 100%;

      & ~ & {
        margin-top: 8px;
      }
    }
  }

  &__columnHeading {
    display: block;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: var(--light-grey-color);
    margin: 0 0 15px 0;
    padding: 0;
  }

  &__dateField,
  &__statusField,
  &__genderField,
  &__consumerNetworkField,
  &__consultantTypeField,
  &__clinicianNameField {
    margin-bottom: 8px;
  }

  &__statusField,
  &__genderField,
  &__consultantTypeField {
    margin-top: 8px;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 15px;
  }

  &__button {
    margin-right: 10px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  label[for='consultation_id'],
  label[for='clinician_id'],
  label[for='patient_id'] {
    margin-top: 15px;
  }
}
