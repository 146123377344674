.copyToClipboardButton {
  color: inherit;
  font-size: inherit;
  padding: 0;
  border: none;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-color: var(--primary-color);
  cursor: pointer;
  text-align: left;
  background-color: var(--transparent);

  &:hover {
    color: var(--primary-color);
  }

  &:active {
    background-color: var(--primary-color);
    color: var(--white);
    border-bottom: none;
  }

  &__hiddenInput {
    border: none;
    clip: rect(1px, 1px, 1px, 1px);
    left: -999rem;
    margin: 0;
    opacity: 0;
    position: absolute;
    padding: 0;
    top: -999rem;
  }
}
