@import '@babylon/design-tokens/all.scss';
@import '../../../../styles/helpers.scss';

.flowList {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
}

.flowListItem {
  margin-bottom: 0.5rem;
  width: 100%;
}

.flowWrapper {
  @include button-reset();
  width: 100%;
}

.flow {
  align-items: center;
  border: 1px solid $grey50;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 100%;
}

.flowText {
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 0.25rem;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
}
