@import '../global-constants';

$avatar-text-background-color: var(
  --medkit-avatar-text-background-color,
  $grey-600
);
$avatar-text-color: var(--medkit-avatar-text-color, $sterile-white);
$avatar-icon-highlight-fill: var(
  --medkit-avatar-icon-highlight-fill,
  $sterile-white
);
$avatar-icon-highlight-background-color: var(
  --medkit-avatar-icon-highlight-background-color,
  $primary
);
$avatar-default-avatar-fill: var(
  --medkit-avatar-default-avatar-fill,
  $secondary
);
$avatar-icon-fill: var(--medkit-avatar-icon-fill, $primary);

.avatar {
  $avatar-size-xl: rem-calc(64);
  $avatar-size-l: rem-calc(56);
  $avatar-size: rem-calc(48);
  $avatar-size-s: rem-calc(40);
  $avatar-size-xs: rem-calc(32);

  $icon-size: rem-calc(16);
  $icon-size-l: rem-calc(20);

  height: $avatar-size;
  position: relative;
  width: $avatar-size;
  margin: 0;

  &__imgWrapper,
  &__default,
  &__text {
    border-radius: 0;
    display: block;
  }

  &__img,
  &__default {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__imgWrapper,
  &__text {
    height: 100%;
    width: 100%;
  }

  &__imgWrapper {
    position: relative;
    overflow: hidden;
    background-size: cover;
  }

  &__img {
    display: block;
    max-height: 100%;
    min-height: $avatar-size;
    min-width: $avatar-size;
  }

  &__default {
    fill: $avatar-default-avatar-fill;
    height: 120%;
    width: 120%;
    vertical-align: middle;
    background-color: $blue-grey-50;
    padding: $size-small;
  }

  &__iconWrapper {
    box-sizing: border-box;
    bottom: 0;
    height: $icon-size;
    position: absolute;
    right: 0;
    width: $icon-size;

    svg {
      fill: $avatar-icon-fill;
      height: 100%;
      width: 100%;
      vertical-align: top;
    }

    &--highlight {
      background: $avatar-icon-highlight-background-color;
      border-radius: 50%;
      padding: 0.125rem;

      svg {
        fill: $avatar-icon-highlight-fill;
      }
    }
  }

  &__text {
    background-color: $avatar-text-background-color;
    color: $avatar-text-color;
    font-size: $size-medium;
    line-height: $avatar-size;
    text-align: center;
    text-transform: uppercase;
    overflow: hidden;
  }

  // display variations
  &--rounded {
    .avatar__imgWrapper,
    .avatar__default,
    .avatar__text {
      border-radius: 50%;
    }
  }

  // size variations
  &--xl,
  &--l {
    .avatar__iconWrapper {
      height: $icon-size-l;
      width: $icon-size-l;

      &--highlight {
        padding: rem-calc(3);
      }
    }
  }

  &--xl {
    height: $avatar-size-xl;
    width: $avatar-size-xl;

    .avatar__img {
      min-height: $avatar-size-xl;
      min-width: $avatar-size-xl;
    }

    .avatar__text {
      font-size: $size-large;
      line-height: $avatar-size-xl;
    }
  }

  &--l {
    height: $avatar-size-l;
    width: $avatar-size-l;

    .avatar__img {
      min-height: $avatar-size-l;
      min-width: $avatar-size-l;
    }

    .avatar__text {
      line-height: $avatar-size-l;
    }
  }

  &--s {
    height: $avatar-size-s;
    width: $avatar-size-s;

    .avatar__text {
      line-height: $avatar-size-s;
    }

    .avatar__img {
      min-height: $avatar-size-s;
      min-width: $avatar-size-s;
    }
  }

  &--xs {
    height: $avatar-size-xs;
    width: $avatar-size-xs;

    .avatar__text {
      font-size: $size-small;
      line-height: $avatar-size-xs;
    }

    .avatar__img {
      min-height: $avatar-size-xs;
      min-width: $avatar-size-xs;
    }
  }
}
