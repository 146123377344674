.tabs {
  margin: 10px 30px 0 30px;
}

.buttonRow {
  display: flex;
  justify-content: flex-end;

  :global {
    .widget-wrapper {
      margin-bottom: 0;
    }
  }

  button {
    margin-left: 10px;
    min-width: 130px;
  }
}

.alert {
  :global {
    textarea.widget.border {
      border: 1px solid rgba(255, 0, 0, 0.7);
    }
  }
}

.commentDialog p {
  margin-top: 0;
}

.prescriptionsView {
  padding: 0 30px 30px 30px;

  th {
    white-space: nowrap;
  }

  .listHeader {
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .filterToolbar {
    display: flex;
    flex-direction: row;

    > * {
      margin-left: 10px;
    }
  }

  .templateFilters {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border-top: 1px solid #dfe7eb;
    padding-top: 5px;
    padding-bottom: 20px;

    .filterFields {
      display: flex;
      flex-direction: row;

      :global {
        div.widget-wrapper {
          float: left;
          margin-right: 20px;
        }

        .DateRangePicker {
          min-width: 140px;
        }

        .field-alert {
          display: none;
        }
        .date-range-picker .DateInput_input {
          padding: 9px 8px 7px 8px;
        }

        fieldset {
          padding-top: 10px;
          padding-bottom: 2px;

          .widget-wrapper {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .filters {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border-top: 1px solid #dfe7eb;
    padding-top: 5px;

    .filterContent {
      display: flex;

      > div {
        min-width: 235px;

        &:first-child {
          margin-right: 20px;
        }

        :global(.widget-wrapper) {
          margin-bottom: 10px;

          input {
            padding: 8px 14px;
          }

          input[name='patientId'] {
            padding: 10px 14px;
          }
        }

        :global(.field-alert) {
          display: none;
        }
      }

      fieldset {
        margin-left: 20px;
      }
    }
  }

  .statusFilterToolbar {
    @extend .buttonRow;
    border-bottom: 1px solid #dfe7eb;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .statusFilter {
    display: block;
    padding: 0 15px 15px 15px;

    > span {
      display: block;
      columns: 3;

      :global {
        .widget-wrapper {
          margin: 0 20px 0 0;
        }
      }
    }
  }

  .innerRow {
    border: 0;
  }

  .commentRow {
    font-size: 13px;
    font-weight: 600;
    text-align: right;
    vertical-align: bottom;
    padding-bottom: 0;

    div {
      text-align: left;
      line-height: 1.5em;
      display: inline-block;
      background: #ffa7a7;
      color: #fff;
      padding: 6px 15px;
      height: 1%;
      border-radius: 2px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .narrowRow {
    width: 1%;
  }

  hr {
    border: 0;
    border-top: 1px solid #dfe7eb;
    height: 0;
    margin: 5px 0;
  }

  .pageSize {
    display: flex;
    align-items: center;

    select {
      font-size: 15px;
      outline: none;
      border: 1px solid #dfe7eb;
      border-radius: 1px;
      background: #fff;
      font-weight: 300 !important;
      margin: 0 10px;
    }

    > span {
      margin-right: 10px;
    }
  }

  .bottomToolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0;

    @media (max-width: 1400px) {
      flex-direction: column;

      .rowRight {
        margin-bottom: 15px;
        text-align: right;
      }

      .download {
        order: 2;
      }
    }

    .download {
      p {
        margin: 0 0 6px 0;
        font-weight: 300;
      }

      > div {
        text-align: left;
        display: flex;
        flex-direction: row;
        margin-right: 15px;

        button {
          margin-left: 10px;
        }

        :global {
          .widget.autocomplete {
            width: 200px;
            padding: 0 !important;
          }
        }
      }
    }
  }

  .rowRight {
    text-align: right;

    > div {
      display: inline-block;
      margin-left: 10px;
    }
  }

  .icon {
    color: #8c6feb;
    cursor: pointer;
    outline: none;

    &:active {
      color: #b19feb;
    }
  }

  .noWrap {
    white-space: nowrap;
  }

  .wrap {
    white-space: normal;
  }

  .buttonLink {
    border: 1px solid #c19efa;
    border-radius: 3px;
    font-size: 12px;
    padding: 0px 5px;
    cursor: pointer;
    line-height: 1.4em;
  }
}

.addressWrap {
  white-space: pre-wrap;
}

.progressBar {
  width: 140px;
  border: 1px solid #96bde8;
  border-radius: 100px;
  height: 10px;
  padding: 1px;
  overflow: hidden;
  margin-left: 15px;
  margin-top: 10px;

  > div {
    height: 6px;
    border-radius: 100px;
    background: #96bde8;
  }
}

.upload {
  display: flex;
  flex-direction: row;
}

.error {
  color: #ff3b2f;
}

.ok {
  color: #2bbbbb;
}

.error,
.ok {
  border: 1px solid #dfe7eb;
  border-radius: 3px;
  float: left;
  margin-top: 20px;
  padding: 15px 15px 0 15px;

  :global .widget-wrapper {
    display: inline-block;
    padding-left: 15px;
  }
}

.errorMessage {
  position: fixed;
  color: #ff4743;
  border-radius: 3px;
  text-align: center;
  top: 40%;
  left: 0;
  padding-left: 250px;
  width: 100%;
}

.spinnerContainer {
  height: calc(100vh - 45px);
}

.Snackbar {
  left: calc(50% + 100px);
}
