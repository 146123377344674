@import '../constants.scss';
:export {
  primary: $primary;
}

.IconRow {
  padding: 16px 0;
  display: flex;
  align-items: center;
}

.iconLeft {
  flex-shrink: 0;
}

.iconRight {
  margin-left: auto;
}

.iconLeft + .content {
  margin-left: 16px;
}

.content {
  padding-right: 8px;
}
