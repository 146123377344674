@import 'styles/variables';

.sideNav {
  max-height: calc(100vh - 67px);
  width: 250px;
  overflow-y: auto;

  padding: 28px 20px 24px 24px;
  position: fixed;
  top: 67px;

  &__categories,
  &__links {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__category {
    margin-bottom: 24px;

    &--expanded {
      color: var(--dark-purple);
    }
  }

  &__categoryTitle,
  &__topLevelLink {
    border-radius: 4px;
    color: var(--grey-800);
    display: block;
    font-size: 14px;
    line-height: 24px;
    padding: 4px;

    &:hover {
      /* stylelint-disable */
      color: inherit;
      background: #f5f9fe;
    }

    &:active,
    &:focus {
      outline: none;
    }

    &--expanded {
      color: var(--dark-purple);

      &:hover {
        color: var(--dark-purple);
      }

      svg {
        fill: $babylon-violet;
      }
    }
  }

  &__topLevelLink {
    margin-bottom: 24px;
  }

  &__links {
    overflow: hidden;
    transition: max-height 0.4s;
    max-height: 0;

    &--expanded {
      max-height: 800px;
    }

    li:first-child {
      margin-top: 12px;
    }
  }

  &__title {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    &Wrapper {
      display: flex;
    }

    &--inline {
      display: inline;
    }
  }

  &__icon {
    align-items: center;
    color: var(--grey-75);
    display: flex;
    margin-right: 8px;
    width: 20px;

    &:empty {
      display: none;
    }

    &--expanded {
      color: var(--babylon-violet);

      svg {
        fill: var(--babylon-violet);
      }
    }
  }

  &__link {
    color: var(--grey-75);
    display: block;
    font-size: 14px;
    line-height: 18px;
    padding: 4px 0 4px 8px;
    margin-bottom: 8px;
    margin-left: 24px;
  }

  &__toggle {
    align-self: flex-end;
  }
}
