/* stylelint-disable at-rule-no-unknown, at-rule-empty-line-before */

@import '../global-constants.scss';

$properties: 'padding', 'margin';

$spaces: (
  'xsmall': $space--xs,
  'small': $space--s,
  'medium': $space--m,
  'large': $space--l,
  'xlarge': $space--xl,
  'xxlarge': $space--xxl,
);

// generates classes like
// .padding--xsmall {
//   padding: 4px; // $space--xs
// }
@each $property in $properties {
  @each $space, $variable in $spaces {
    .#{$property}--#{$space} {
      #{$property}: $variable;
    }
  }
}

$directions: 'X', 'Y';

// generates classes like
// .paddingX--xsmall {
//   padding-left: 4px; // $space--xs
//   padding-right: 4px; // $space--xs
// }
@each $direction in $directions {
  @each $property in $properties {
    @each $space, $variable in $spaces {
      .#{$property}#{$direction}--#{$space} {
        @if $direction == X {
          #{$property}-left: $variable;
          #{$property}-right: $variable;
        }

        /* prettier-ignore */
        @else {
          #{$property}-top: $variable;
          #{$property}-bottom: $variable;
        }
      }
    }
  }
}
