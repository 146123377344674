@import '../global-constants';

.modalBackButton {
  display: flex;
  align-items: center;
  padding: 0;
  border: 0;
  border-radius: none;
  font-size: inherit;
  font-family: inherit;
  line-height: 1;
  background-color: transparent;
  cursor: pointer;
  appearance: none;

  &:focus {
    text-decoration: underline;
  }

  &__text {
    font-size: 0.875rem;
  }

  &__icon {
    fill: var(--medkit-primary, $primary);
  }
}
