@import '../global-constants';
@import '../../../design-tokens/functions.scss';

$text-heading-weight: var(--medkit-text-heading-weight, bold);
$text-base-color: var(--medkit-text-base-color, $grey-900);
$text-base-single-line-break: var(
  --medkit-text-single-line-break,
  rem-calc(24)
);
$text-base-double-line-break: var(
  --medkit-text-double-line-break,
  rem-calc(48)
);
$text-base-margin-bottom: var(
  --medkit-text-heading-margin-bottom,
  rem-calc(12)
);

$text-h1-font-size: var(--medkit-text-h1-font-size, rem-calc(36));
$text-h1-line-height: var(--medkit-text-h1-line-height, rem-calc(48));
$text-body-margin-top-after-h1: var(
  --medkit-body-margin-top-after-h1,
  rem-calc(32)
);

$text-h2-font-size: var(--medkit-text-h2-font-size, rem-calc(30));
$text-h2-line-height: var(--medkit-text-h2-line-height, rem-calc(40));
$text-body-margin-top-after-h2: var(
  --medkit-body-margin-top-after-h2,
  rem-calc(26)
);

$text-h3-font-size: var(--medkit-text-h3-font-size, rem-calc(24));
$text-h3-line-height: var(--medkit-text-h3-line-height, rem-calc(32));
$text-body-margin-top-after-h3: var(
  --medkit-body-margin-top-after-h3,
  rem-calc(19)
);

$text-h4-font-size: var(--medkit-text-h4-font-size, rem-calc(20));
$text-h4-line-height: var(--medkit-text-h4-line-height, rem-calc(28));
$text-body-margin-top-after-h4: var(
  --medkit-body-margin-top-after-h4,
  rem-calc(16)
);

$text-h5-font-size: var(--medkit-text-h5-font-size, rem-calc($base-font-size));
$text-h5-line-height: var(--medkit-text-h5-line-height, rem-calc(24));
$text-body-margin-top-after-h5: var(
  --medkit-body-margin-top-after-h5,
  rem-calc(12)
);

$text-body-font-size: var(
  --medkit-text-body-font-size,
  rem-calc($base-font-size)
);
$text-body-line-height: var(--medkit-text-body-line-height, rem-calc(24));

$text-small-font-size: var(
  --medkit-text-small-font-size,
  rem-calc($base-font-size)
);
$text-small-line-height: var(--medkit-text-small-line-height, rem-calc(24));

@mixin bodyAfterHeading($body-margin-top) {
  & + .Text__body {
    margin-top: $body-margin-top;
  }
}

@mixin bodyAfterBody {
  & + .Text__body {
    margin-top: $text-base-single-line-break;
  }
}

@mixin firstElementDoubleLineBreak {
  &:first-child {
    margin-top: $text-base-double-line-break;
  }
}

// enabling external use
@mixin heading1 {
  font-size: $text-h1-font-size;
  line-height: $text-h1-line-height;
  font-weight: $text-heading-weight;
}

@mixin heading2 {
  font-size: $text-h2-font-size;
  line-height: $text-h2-line-height;
  font-weight: $text-heading-weight;
}

@mixin heading3 {
  font-size: $text-h3-font-size;
  line-height: $text-h3-line-height;
  font-weight: $text-heading-weight;
}

@mixin heading4 {
  font-size: $text-h4-font-size;
  line-height: $text-h4-line-height;
  font-weight: $text-heading-weight;
}

@mixin heading5 {
  font-size: $text-h5-font-size;
  line-height: $text-h5-line-height;
  font-weight: $text-heading-weight;
}

.Text {
  padding: 0;
  font-weight: normal;
  color: $text-base-color;
  margin-bottom: 0;
  margin-top: 0;

  &__h1 {
    @include heading1;
  }

  &__h2 {
    @include heading2;
  }

  &__h3 {
    @include heading3;
  }

  &__h4 {
    @include heading4;
  }

  &__h5 {
    @include heading5;
  }

  &__body {
    font-size: $text-body-font-size;
    line-height: $text-body-line-height;
  }

  &__smallBody {
    font-size: $text-small-font-size;
    line-height: $text-small-line-height;
  }

  &__caption {
    font-size: $text-body-font-size;
    line-height: $text-body-line-height;
  }
}

.EditorialLayout > .Text {
  max-width: rem-calc(500);

  &__h1,
  &__h2,
  &__h3,
  &__h5 {
    margin-top: $text-base-double-line-break;
    margin-bottom: $text-base-margin-bottom;
  }

  &__h1 {
    @include bodyAfterHeading($text-body-margin-top-after-h1);
  }

  &__h2 {
    @include bodyAfterHeading($text-body-margin-top-after-h2);
  }

  &__h3 {
    @include bodyAfterHeading($text-body-margin-top-after-h3);
  }

  &__h4 {
    @include bodyAfterHeading($text-body-margin-top-after-h4);
  }

  &__h5 {
    @include bodyAfterHeading($text-body-margin-top-after-h5);
  }

  &__body {
    @include bodyAfterBody();
    @include firstElementDoubleLineBreak();
  }

  &__body,
  &__smallBody,
  &__caption {
    margin-bottom: $text-base-margin-bottom;
  }
}
