@font-face {
  font-family: 'Icons';
  src: local('Icons'), url(./webfonts/fa-solid-900.woff) format('woff');
}

.icon {
  font-style: normal;
  font-weight: normal;
  font-family: Icons;
}

.icon:before {
  display: inline-block;
}

.sort:before {
  content: '\f0dc';
}

.sort-up:before {
  content: '\f0de';
}

.sort-down:before {
  content: '\f0dd';
}

.search:before {
  content: '\f002';
}

.edit:before {
  content: '\f303';
}

.delete:before {
  content: '\f2ed';
}

.add:before {
  content: '\f055';
}

.chevron-right:before {
  content: '\f054';
}

.undo:before {
  content: '\f0e2';
}

.logs:before {
  content: '\f022';
}

.ban:before {
  content: '\f05e';
}

.allow:before {
  content: '\f057';
}

.download:before {
  content: '\f381';
}

.check:before {
  content: '\f14a';
}

.fax:before {
  content: '\f1ac';
}

.done:before {
  content: '\f11e';
}

.stop:before {
  content: '\f256';
}

.plus:before {
  content: '\f0fe';
}

.redo:before {
  content: '\f2f9';
}

.resend:before {
  content: '\f14d';
}

.pdf:before {
  content: '\f1c1';
}

.delivery:before {
  content: '\f0d1';
}

.ok:before {
  content: '\f058';
}

.approve:before {
  content: '\f00c';
}

.status:before {
  content: '\f46c';
}

.spinner:before {
  content: '\f110';
  animation: rotation 3s infinite linear;
}

.video:before {
  content: '\f03d';
}

.voice:before {
  content: '\f095';
}

.physical:before {
  content: '\f0c0';
}

.location:before {
  content: '\f3c5';
}

.warning:before {
  content: '\f06a';
}

.calendar:before {
  content: '\f073';
}

.clipboard:before {
  content: '\f0c5';
}

.externalLink:before {
  content: '\f35d';
}

.exclamation-triangle:before {
  content: '\f071';
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
