.view {
  max-width: 30rem;
  width: 100%;
}

.instruction {
  margin: 0.25rem 0 1.5rem;
}

.actions {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-top: 1.75rem;
}
