.Duration {
  display: flex;
  flex-direction: row;
  color: var(--grey-75);
  line-height: 16px;
  margin: 5px 0;
  align-items: center;

  &__icon {
    display: flex;
    margin-right: 4px;
  }
}
