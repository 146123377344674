.AppointmentCard {
  width: 100%;

  &__wrapper {
    padding-bottom: 16px;
    padding-left: 37px;
    position: relative;
    display: flex;
    flex-direction: row;

    &::after {
      background: var(--timeline-path);
      bottom: 0;
      content: '';
      position: absolute;
      top: 45px;
      width: 1px;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }

  &__summary {
    display: grid;
    grid-row-gap: 8px;
  }

  &__menu {
    position: absolute;
    right: 22px;
    top: 16px;
  }

  &__viewMore {
    width: 100%;
    justify-content: center;
    margin-top: 15px;
  }

  @media (min-width: 1280px) {
    &__summary {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: repeat(2, 6fr) 1fr;
      grid-template-rows: repeat(3, min-content);
      gap: 8px 5px;
      grid-template-areas:
        '. . menu'
        '. . menu'
        'viewmore duration menu';
      align-items: center;
    }

    &__menu {
      grid-area: menu;
      position: relative;
      right: inherit;
      top: inherit;
    }

    &__durationCell {
      grid-area: duration;
    }

    &__viewMoreCell {
      grid-area: viewmore;
    }

    &__viewMore {
      width: auto;
      justify-content: left;
      margin-top: auto;
    }
  }

  &__title {
    font-weight: 900;
  }

  &__cardNetworkTag {
    max-width: 200px;
  }
}
