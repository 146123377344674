@import './../../styles/variables';

.globalActionButtons {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  background-color: #f3f7fb;
  position: fixed;
  bottom: 0;
  width: calc(100% - 248px);
  z-index: var(--zindex-content);

  :global {
    .widget-wrapper {
      margin-bottom: 0;
    }

    .widget.button {
      margin-left: 10px;
      padding: 5px;
    }
  }
}

.pageStyle {
  padding-bottom: 100px;
}

.filterSummaryWrapper {
  padding: 15px;
}

.broadWarningMessage {
  display: block;
  color: $red-color;
  padding: 15px;
}

.listView {
  margin: 8px 0;

  :global {
    .list-item {
      display: flex;
      padding: 16px 24px;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        background-color: rgba(74, 144, 226, 0.15);
      }

      .widget-wrapper {
        margin-bottom: 0;
      }

      input[type='checkbox'].widget + label,
      input[type='radio'].widget + label {
        padding-left: 32px;
      }

      .widget + label::before {
        top: 0;
        background-color: $white;
      }

      .widget:checked + label {
        &::before {
          border-color: $accent-color;
          background-color: $accent-color;
        }

        &::after {
          top: 3px;
        }
      }
    }
  }
}

.spacer {
  flex-grow: 1;
}
