@import '../../global-constants';

$background: var(--medkit-pill-background, transparent);
$background-hover: var(
  --medkit-pill-background-hover,
  var(--medkit-primary, $primary-light)
);
$background-active: var(
  --medkit-pill-background-active,
  var(--medkit-primary, $primary)
);
$border: var(--medkit-pill-border, $grey-100);
$border-hover: var(--medkit-pill-border-hover, var(--medkit-primary, $primary));
$border-active: var(
  --medkit-pill-border-active,
  var(--medkit-primary, $primary)
);
$color: var(--medkit-pill-color, $grey-900);
$color-hover: var(--medkit-pill-color-hover, var(--medkit-primary, $primary));
$color-active: var(--medkit-pill-color-active, $sterile-white);

.defaultPillContainer {
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
}

.stackedPillContainer {
  display: flex;
  flex-direction: column;
}

.pillElement {
  margin-right: 1rem;
  margin-bottom: 1rem;
  flex-shrink: 0;
}

.pillInput {
  position: absolute;
  opacity: 0;

  /*
   * Defining the following separately because if the pseudo-selector is invalid (or not yet supported),
   * the entire selector will be ignored.
   * cf. https://css-tricks.com/one-invalid-pseudo-selector-equals-an-entire-ignored-selector/
   */
  &:focus-visible + .pillLabel {
    background-color: $background-hover;
    border-color: $border-hover;
    color: $color-hover;
  }

  &[data-focus-visible-added] + .pillLabel {
    background-color: $background-hover;
    border-color: $border-hover;
    color: $color-hover;
  }

  &:checked + .pillLabel {
    color: $color-active;
    background-color: $background-active;
    border-color: $border-active;
  }
}

.pillLabel {
  color: $color;
  cursor: pointer;
  border-radius: 1rem;
  border-width: 1px;
  border-style: solid;
  border-color: $border;
  padding: 0.5rem 1rem 0.45rem;
  display: block;
  line-height: 1em;
  transition-property: background-color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  width: fit-content;

  &:hover {
    background-color: $background-hover;
    border-color: $border-hover;
    color: $color-hover;
  }

  .pillDisabled & {
    opacity: 0.8;
    cursor: not-allowed;

    &:hover {
      color: $color;
      border-color: $border;
      background-color: $background;
    }
  }
}
