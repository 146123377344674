.view {
  max-width: 30rem;
  width: 100%;
}

.instruction {
  margin: 0.25rem 0 1.5rem;
}

.instructionTitle {
  margin-top: 0.25rem;
  font-weight: bold;
}
