@import '../util/constants';

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* stylelint-disable */
@value transitionDuration: 750ms;
/* stylelint-enable */

:global {
  /* Style the root HTML tag when chatbot is used standalone */
  html.chatbot-standalone body {
    margin: 0;
  }
}

.overlayRoot {
  will-change: opacity;
  animation: fadeIn transitionDuration;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.content {
  width: 100%;
  max-width: $content-width;
  padding: 1rem;
  margin: auto;
}

.questionCardPopup {
  color: inherit;
}

.questionCardPopup-enter {
  opacity: 0;
}

.questionCardPopup-enter-active {
  opacity: 1;
  transition: all transitionDuration;
}

.questionCardPopup-exit {
  opacity: 1;
}

.questionCardPopup-exit-active {
  opacity: 0;
  transition: all transitionDuration;
}
