@import '../../../../styles/helpers.scss';

.sidePanel {
  padding: 4rem 2.5rem 0;
}

.header {
  display: flex;
  flex-direction: column;
}

.title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.25rem;
}

.closeButton {
  @include button-reset();
  background-color: var(--active-grey);
  height: 2.5rem;
  width: 2.5rem;
}
