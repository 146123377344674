.membersSearchView__header {
  margin-bottom: 24px;
}

.Snackbar {
  left: calc(50% + 100px);
}

.appLayout__inner {
  padding: 0 24px;
}
