@import '../../util/constants.scss';

.cardContent {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  padding: 0 $spacing--l $spacing--m;

  &__title,
  &__body,
  &__icon {
    color: $text-color;
    text-align: center;
  }

  &__title {
    font-size: $font-size--m;
    font-weight: bold;
    margin-bottom: $spacing--m;
  }

  &__body {
    flex: 1;
    font-size: 17px;
    line-height: 28px;
  }

  &__icon {
    height: 200px;
    width: 200px;

    svg {
      fill: #fff;
      height: 100%;
      width: 100%;
    }
  }
}
