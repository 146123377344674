.registrationButton {
  margin-bottom: 8px;
  margin-right: 4px;
  white-space: nowrap;
  width: 100%;
}

.sendEmailButton {
  background-color: var(--dark-purple);
}

.sendEmailModal {
  width: 80%;
  max-width: 550px;
}
