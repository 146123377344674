.core-date-range {
  --focus-color: #45576e;
  --border-color: var(--button-secondary-border-color);
  --white: #fff;

  display: inline-flex;
  align-items: center;
  border: 1px solid var(--input-border-color);
  border-radius: 3px;
  overflow: hidden;
  background: #fff;

  &--focused {
    border-color: var(--button-secondary-border-color);
    box-shadow: 0 0 0 2px var(--input-active-outline-color);
  }

  &--hasError {
    border-color: var(--input-error-border-color);
  }

  &--fill {
    width: 100%;

    .react-datepicker-wrapper {
      display: flex;
      flex: auto;
    }

    .react-datepicker__input-container {
      flex: 1 0 auto;
    }

    .core-date-range__input {
      max-width: 100%;
    }
  }

  &__input {
    max-width: 120px;

    &,
    &:focus {
      border: 0 rgba(255, 255, 255, 0) solid;
      border-width: 2px 0;
      border-radius: 0;
      box-shadow: none;
    }

    &:focus {
      border-bottom-color: var(--focus-color);
    }
  }

  &__separator {
    display: flex;
    width: 40px;
    justify-content: center;
  }

  .core-datepicker__input {
    border: none;

    &:focus {
      border-bottom-color: var(--focus-color);
      border-width: 0 0 2px 0;
      border-radius: 0;
      box-shadow: none;
    }
  }

  .react-datepicker__month-container + .react-datepicker__month-container {
    /* stylelint-disable-next-line selector-max-compound-selectors */
    .react-datepicker__month::before {
      left: 0;
    }
  }

  .react-datepicker__month-container .react-datepicker__month {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 18px;
      right: -1px;
      width: 1px;
      height: calc(100% - 36px);
      background: var(--border-color);
    }
  }
}
