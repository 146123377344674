.core-confirmation-modal {
  &__heading {
    margin-bottom: 16px;
  }

  &__inner-content {
    font-size: 16px;
    line-height: 24px;
  }

  &__buttons {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;
  }

  &__buttons--left {
    justify-content: flex-end;
  }

  &__buttons--center {
    justify-content: center;
  }

  &__buttons--right {
    justify-content: flex-start;
  }

  &__confirm-button {
    margin-left: 8px;
  }
}
