@import '../../styles/variables.scss';

.dim {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog {
  background: var(--white);
  display: flex;
  flex-direction: column;
  width: 600px;
  padding: 30px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: 10%;
  border-radius: 4px;

  :global {
    .babylon-component-library__asFormControl__control {
      margin-bottom: 15px;
    }

    select {
      font-weight: 200;
    }
  }

  &.placeholder select {
    color: $color-text-placeholder;
  }

  &.alert {
    input,
    .alone select {
      border-color: var(--warning-tag-border);
    }
  }

  .otherReason {
    margin-top: 8px;
  }

  .buttonBar {
    margin-top: 16px;
    text-align: right;

    button {
      margin-left: 10px;
    }
  }
}
