.MemberRelationshipsCard {
  &__wrapper {
    padding: 12px;
  }

  &__header {
    align-items: flex-start;
    color: var(--text-color);
    display: flex;
    justify-content: space-between;
    line-height: 22px;
  }
}

.AddRelationshipButton {
  width: 100%;
  height: 44px;
  border-radius: 8px;
}

.AddRelatedPersonsDrawer {
  width: 45%;
  max-width: 500px;
}

.AddRelatedPersonsDrawer__body {
  padding-bottom: 24px;
  font-weight: 200;
  font-size: 14px;
  line-height: 20.33px;
}

.AddRelatedPersonsDrawer__header {
  padding-bottom: 0;
}

.AddRelatedPersonsForm__searchButton {
  height: 44px;
  border-radius: 8px;
  margin-right: 0;
  margin-top: 20px;
  padding-left: 0;
  padding-right: 0;
  min-width: 105px;
  width: 4rem;
}

.AddRelatedPersonsForm__createNewPatientButton {
  min-width: 300px;
  height: 44px;
  padding-left: 0;
  padding-right: 0;
}
