@import '../constants.scss';

.control {
  &--select {
    // select specific
    user-select: none;
    appearance: none;
    cursor: pointer;
    // end select specific
    background: transparent;
    padding-left: 16px;

    &::-ms-expand {
      display: none;
    }

    &:invalid {
      color: $secondary-dark;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.select-wrapper {
  position: relative;

  &__arrow:after {
    bottom: 25%;
    right: 15px;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-top-color: rgba(0, 0, 0, 0.45);
    border-width: 0.4rem;
    margin-left: 0.4rem;

    @media screen and (max-width: 468px) {
      display: none;
    }
  }
}
