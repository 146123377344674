.textFieldWrapper {
  padding: 1rem 0;
}

.title {
  padding: 1rem;
}

.header {
  display: flex;
}

.createButton {
  margin-left: auto;
  margin-right: 2.5rem;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50rem;
  background: #f7fafb;
  display: flex;
  flex-direction: column;
  padding: 3rem;
  max-height: 80%;
  box-sizing: content-box;
}

.modalContent {
  overflow-y: auto;
}

.formLabel {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
}

.buttonWrapper {
  padding-top: 1.5rem;
  margin-left: auto;
}

.footer {
  display: flex;
  flex-direction: column;
}

.invalidFormWarning {
  margin-left: auto;
  font-size: 0.8rem;
}

.fillContent {
  width: 100%;
}

.dataTable {
  width: 95%;
  height: 100%;
  margin: auto;
}

.dataView {
  width: 95%;
  height: 100%;
  margin: auto;
}

.flexRowContainer {
  display: flex;
  flex-direction: row;
}

.filterInputContainer {
  display: flex;
  flex: 1;

  > .textField {
    margin: 8px;
  }
}

.flexContainer {
  display: flex;
  flex: 1;
}

.filterInputLabel {
  margin: 4px;
}

.accordionDetails {
  padding: 0 0.5rem 1rem;
  display: flex;
}

.pharmaciesTitle {
  padding: 1rem;
}

.newButtonWrapper {
  margin-left: auto;
  margin-right: 2rem;
  display: flex;
}

.propertyName {
  font-size: 0.8rem;
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.propertyValue {
  font-size: 1rem;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 700;
}
