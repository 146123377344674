.RelatedPerson {
  background-color: var(--off-white);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 12px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &__header {
    align-items: flex-start;
    color: var(--text-color);
    display: flex;
    justify-content: space-between;
    line-height: 22px;
  }

  &__type {
    align-self: unset;
    background-color: var(--grey-25);
    color: var(--grey-75);
    font-weight: 900;
    margin: 0;
  }

  &__info {
    line-height: 1.13;
    margin: 8px 0 16px;
  }
}
