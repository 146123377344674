@mixin createButtonIntentClass($intent) {
  .core-button--#{$intent} {
    background: var(--button-#{$intent}-background);
    color: var(--button-#{$intent}-color);
    border: 1px solid var(--button-#{$intent}-border-color);

    &:hover {
      color: var(--button-#{$intent}-color);
      background: var(--button-#{$intent}-hover-background);
    }

    &:active {
      transform: translateY(1px);
      background: var(--button-#{$intent}-active-background);
    }

    .core-spinner circle {
      stroke: var(--button-#{$intent}-color);
    }
  }
}

.core-button {
  display: flex;
  width: fit-content;
  text-decoration: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: content-box;
  height: auto;
  line-height: 16px;
  border-radius: 3px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  outline: none;
  cursor: pointer;
  transition: transform 0.2s, opacity 0.2s;

  &[type='button'],
  &[type='reset'],
  &[type='submit'] {
    -webkit-appearance: none;
  }

  &[disabled],
  &--disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  > *:not(:last-child) {
    margin-right: 14px;
  }

  &--fill {
    width: 100%;
  }

  &--link {
    color: var(--link-color);
    text-decoration: underline;
    background: none;
    border: 0;
  }

  &--inline {
    display: inline-flex;

    & + & {
      margin-left: 16px;
    }
  }
}

@include createButtonIntentClass('primary');
@include createButtonIntentClass('secondary');
@include createButtonIntentClass('error');
@include createButtonIntentClass('warning');
@include createButtonIntentClass('success');
