.tabs {
  margin: 10px 30px 0 30px;
}

.externalLink i {
  margin-left: 8px;
  font-size: 12px;
}

.progressBar {
  width: 140px;
  border: 1px solid #96bde8;
  border-radius: 100px;
  height: 10px;
  padding: 1px;
  overflow: hidden;
  margin-left: 15px;
  margin-top: 10px;

  > div {
    height: 6px;
    border-radius: 100px;
    background: #96bde8;
  }
}

.drugsView {
  padding: 10px 30px 30px 30px;

  .highlight {
    color: #353a46;
    font-weight: 400 !important;
  }

  .badge {
    background-color: #96bde8;
    font-size: 10pt;
    font-weight: bold;
    color: #ffffff;
    display: inline-block;
    padding: 0 25px 0 5px;
    line-height: 20px;
    margin: 3px 5px 0 0;
    height: 19px;
    vertical-align: top;
    cursor: default;
    position: relative;

    &.noButton {
      padding-right: 5px;
    }

    button {
      width: 20px;
      padding: 0;
      line-height: 20px;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.7);
      background: rgba(0, 0, 0, 0.1);
      border: 0;
      border-left: 1px solid rgba(255, 255, 255, 0.3);
      cursor: pointer;

      &:hover {
        color: rgba(255, 255, 255, 0.9);
        background: rgba(0, 0, 0, 0.2);
      }

      &:active {
        color: rgba(255, 255, 255, 2);
        background: rgba(0, 0, 0, 0.3);
      }
    }
  }

  .blacklisted {
    background-color: #666;
  }

  .generic {
    padding-right: 5px;
    background-color: #63dbc1;
  }

  .branded {
    padding-right: 5px;
    background-color: #f47a21;
  }

  .searchBar {
    text-align: right;
    margin-bottom: 30px;

    label {
      float: left;

      select {
        outline: none;
        border: 1px solid #dfe7eb;
        border-radius: 1px;
        background: #fff;
        font-weight: 300 !important;
      }
    }
  }

  .searchBox {
    float: left;
    margin-right: 20px;
  }

  .listFooter {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    justify-content: space-between;

    @media (max-width: 1300px) {
      flex-direction: column;

      .pagination {
        margin-bottom: 15px;
        text-align: right;
      }

      .download {
        order: 2;
      }
    }

    .download {
      text-align: left;
      display: flex;
      flex-direction: row;
      margin-right: 15px;

      button {
        margin-left: 10px;
      }

      :global {
        .widget.autocomplete {
          width: 200px;
          padding: 0 !important;
        }
      }
    }
  }

  .listView td {
    height: 61px;

    &.actions button {
      display: block;
    }
  }

  .listView td.actions button {
    margin: 0;
    padding: 0;
    background: transparent;
    border: 0;
    font-size: 12px;
    font-weight: 200;
    line-height: 2em;
    color: #919191;
    cursor: pointer;
    white-space: nowrap;

    i {
      color: #666;
    }

    &:hover {
      color: #484848;
      i {
        color: #484848;
      }
    }

    &:active {
      color: #000;
      i {
        color: #000;
      }
    }
  }

  .addButton {
    font-size: 14px;
    border: 0;
    padding: 0px 10px;
    border-radius: 4px;
    background-color: #fff;
    font-weight: 200;
    line-height: 28px;
    border: solid 1px #ddd;

    &,
    i {
      color: #666;
    }

    &:hover {
      background: darken(#fff, 5);
    }

    &:active {
      background: darken(#fff, 10);
    }
  }
}

.alertView {
  padding: 10px 30px 30px 30px;

  .listHeader {
    padding-bottom: 30px;

    :global {
      .widget-wrapper {
        margin: 0;
      }
    }
  }

  .searchBox {
    flex: none;
    margin-right: 15px;
  }

  .listFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 30px;
  }

  .pagination {
    display: flex;
    flex-direction: row;

    .spinner {
      margin-right: 10px;
    }
  }

  .badge {
    background-color: #8ab4e4;
    font-size: 10pt;
    font-weight: bold;
    color: #ffffff;
    display: inline-block;
    padding: 0 5px;
    line-height: 20px;
    height: 19px;
    cursor: default;
    margin-right: 10px;
  }
}

.error {
  color: #ff4743;
  font-size: 14px;
}
