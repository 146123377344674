.link {
  color: #2bbbbb;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: #219292;
  }
}
