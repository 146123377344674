.Container {
  max-width: 700px;
  margin: 0 auto;
}

.CopyIcon {
  text-align: right;
  margin-right: 10px;
}

.Device {
  background-color: var(--active-grey);
  border-radius: 8px;
  font-family: 'Visuelt', sans-serif;

  &__copyAll {
    margin-left: auto;
  }
}

.Grid {
  padding: 10px;
  margin: 10px 0;

  &__title {
    color: var(--neutral-grey);
  }
}
