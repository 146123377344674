@import '../../util/constants.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.legend {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.fieldset {
  box-shadow: 0 0 0.0625rem rgba(0, 0, 0, 0.21),
    0 0.1875rem 0.5rem -0.0625rem rgba(0, 0, 0, 0.04);
  border-radius: 0.125rem 1rem 1rem 1rem;
  overflow: hidden;

  border: none;
  outline: none;
  width: 100%;
}

.content {
  // !important for override hacky & > * { padding rules
  // in QuestionCard.module.scss
  /* stylelint-disable-next-line declaration-no-important */
  padding: 0.25rem 1rem 1rem 1rem !important;
}

.group {
  margin-bottom: 1.5rem;
}

.optionItem {
  padding: 1rem 0;
}
