@mixin createRadioIntentClass($intent) {
  .core-radio--#{$intent} {
    .core-radio__element {
      border: 1px solid var(--input-#{$intent}-border-color);
    }
  }
}

.core-radio {
  display: flex;
  position: relative;

  flex-direction: row;
  align-items: center;

  cursor: pointer;

  &__input {
    display: none;
  }

  &__element {
    display: block;
    position: relative;

    flex-shrink: 0;

    width: 14px;
    height: 14px;

    border-radius: 100%;
    border: 1px solid #dfe7eb;

    background: white;

    margin-right: 0.5em;

    &::after {
      display: none;
      position: absolute;

      transform-origin: center;

      background: var(--input-background);

      content: '';
      opacity: 1;

      top: 50%;
      left: 50%;
      width: 6px;
      height: 6px;

      margin-left: -3px;
      margin-top: -3px;

      border-radius: 100%;
    }

    &:hover {
      border: 1px solid #{darken(#dfe7eb, 10%)};
    }
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;

    * {
      pointer-events: none;
    }
  }

  &--checked &__element {
    border: 1px solid var(--radio-checked-background-color);

    &::after {
      display: block;
      background: var(--radio-checked-background-color);
      border-color: var(--radio-checked-background-color);
    }
  }
}

@include createRadioIntentClass('error');
@include createRadioIntentClass('warning');
@include createRadioIntentClass('success');

.core-radio-pill {
  display: table;
  padding: 8px 12px;
  color: var(--grey-75);
  border: 1px solid var(--grey-50);
  font-size: 14px;
  letter-spacing: normal;
  outline: none;
  cursor: pointer;
  border-radius: 3px;

  input[type='radio'] {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0;
    font: 0/0;
  }

  &--checked {
    color: var(--primary-100);
    background: var(--primary-50);
    border: 1px solid var(--primary-60);
  }

  &:focus {
    border: 1px solid var(--primary-60);
  }

  &--disabled {
    opacity: 0.6;
  }

  &--inline {
    display: inline-block;
  }

  & + & {
    margin-top: 8px;
  }

  &.core-radio-pill--inline + &.core-radio-pill--inline {
    margin-top: 0;
    margin-left: 8px;
  }
}
