.profileHeader {
  background-color: var(--active-grey);
  border-radius: 8px;
  margin-bottom: 24px;

  &:empty {
    height: 235px;
  }
}

.profileHero {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.profileDetails {
  padding: 32px 32px 12px;
}

.profileAccount {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.profileContent {
  color: var(--text-color);
  line-height: 24px;
  margin-bottom: 8px;
}

.profileEmail {
  word-break: break-all;
}

.useOldPortal {
  background-color: var(--transparent);
  border: none;
  color: var(--primary-color);
  cursor: pointer;
  display: block;
  font-size: 14px;
  margin-bottom: 16px;
  padding-right: 0;
  text-align: right;
  text-decoration: underline;

  &:hover {
    color: var(--primary-color);
  }
}

.actions {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &__menu {
    display: flex;
  }

  &__button {
    margin-bottom: 8px;
    margin-right: 4px;
    white-space: nowrap;
    width: 100%;
  }
}

:global(.core-button--inline) {
  + .actions__button {
    margin-left: 4px;
  }
}

.loadingBlock {
  height: 235px;
  margin-bottom: 20px;
}

.ownerLink {
  font-size: 14px;
  margin-bottom: 10px;

  & a {
    color: var(--primary-color);
  }
}
