@import '../constants.scss';

.control {
  color: #343a46;
  position: relative;
  @include font();

  &__success {
    margin-top: 8px;
    color: $success;
    font-size: 12px;
  }

  &__tip {
    margin-top: 8px;
    font-size: 12px;
  }
}

.errors {
  list-style: none;
  margin: 0;
  padding: 0;

  &__item {
    margin-top: 8px;
    font-size: 12px;
    color: $error;

    &:first-letter {
      text-transform: uppercase;
    }
  }
}
