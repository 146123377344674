@import '@babylon/design-tokens/all.scss';

.stateColour {
  &_none_collected {
    color: $red120;
  }

  &_partially_collected {
    color: $orange100;
  }

  &_all_collected {
    color: $green-d20;
  }
}
