@import '../constants.scss';

.autoSelect {
  padding: 0;
  margin: 0;
  border: 0;

  &--hideLabel label {
    width: 1px;
    height: 1px;
    position: absolute;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
  }

  &__suggestions {
    list-style: none outside none;
    margin: 0;
    padding: 0;
    border: 1px solid $unfocus-border;
    border-top-width: 0;
    border-radius: 0 0 8px 8px;
    overflow: auto;
    max-height: 320px;
    font-family: $font-family;
    font-weight: normal;

    &:empty {
      border: 0;
    }

    strong {
      font-weight: normal;
      color: #000;
    }
  }

  &__suggestion {
    padding: 8px 16px;
    cursor: pointer;

    &--selected {
      background: $grey10;
    }

    &__label {
      color: black;
      font-weight: 500;
    }

    &__description {
      color: $grey75;
    }

    & + & {
      border-top: 1px solid rgba($unfocus-border, 0.4);
    }
  }

  &--open input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
