.SearchResults {
  position: relative;
  margin-top: 20px;
}

.SpinnerOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 248px;
  right: 0;
  pointer-events: none;
}

.LoadingSearchResults {
  opacity: 0.5;
}

.HideSearchResults {
  display: none;
}

.SearchResultItemWrapper {
  margin: 20px 0;
}

.Error {
  display: block;
  font-size: 18px;
  line-height: 19px;
  font-weight: 900;
  margin: 0;
  padding: 0;
  color: var(--error-color);
}

.Alert {
  margin-top: 20px;
}
