$color-text-grey: #637280;

@function escapecolor($color) {
  @return '%23' + str-slice('#{$color}', 2, -1);
}

@function icon($hex) {
  $color: escapecolor($hex);
  $icon: '<svg width="7" height="8" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M-9-8h24v24H-9z"/><path d="M2.648 7.67L6.03 4.784c.51-.434.51-1.136 0-1.57L2.648.327C1.825-.374.414.127.414 1.118V6.89c0 .991 1.41 1.482 2.234.78z" fill="#{$color}" fill-rule="nonzero"/></g></svg>';

  @return url('data:image/svg+xml;utf8,#{$icon}');
}

.button {
  align-items: center;
  background: #f5f9fe;
  border: 0;
  border-radius: 4px;
  color: var(--text-color);
  cursor: pointer;
  display: flex;
  font-size: 16px;
  min-height: 28px;
  padding: 2px 12px;
  text-align: left;

  &::before {
    background-image: icon(rgba($color-text-grey, 1));
    content: '';
    display: block;
    height: 8px;
    margin-right: 8px;
    transition: transform 0.15s linear, margin 0.15s linear;
    width: 7px;
  }

  &:disabled {
    pointer-events: none;
  }

  &--no-content {
    height: 24px;
    width: 24px;
    min-height: 0;
    justify-content: center;
    padding: 0;

    &::before {
      margin-right: 0;
    }
  }

  &--open::before {
    transform: rotateZ(90deg);
  }

  &--dark {
    background: var(--white);
    color: $color-text-grey;

    &::before {
      background-image: icon($color-text-grey);
    }
  }

  &--small {
    font-size: 14px;
  }
}
