@import '../constants.scss';

.LinkButton {
  display: flex;
  align-items: center;
  color: $primary;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  position: relative;
  cursor: pointer;

  &:hover {
    color: $primary-d10;
  }

  &__anchor {
    display: block;
    text-decoration: none;
  }

  &_neutral &__anchor {
    color: $grey100;
  }
}
