@import '../../util/constants';

.root {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  width: 100%;
}

.feedbackLinkContainer {
  margin-top: 5rem;
}

.error {
  color: $error;
}

.bodyHTML {
  margin-bottom: 1rem;

  & :global {
    h1 {
      font-size: 1.1em;
      margin: 1em 0 0.5em 0;
      line-height: 1.5;
    }

    ul {
      padding-left: 30px;
      margin: 0;
    }

    li {
      list-style: disc;
    }
  }
}

.outcomeBox {
  box-shadow: 0 3px 8px -1px rgba(0, 0, 0, 0.04);
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.21);
  border-radius: 1rem;
  padding: 1.25rem;
}
