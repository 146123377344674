@import '../constants.scss';

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* stylelint-disable */
.button {
  @include font();
  background: transparent;
  border-radius: $border-radius;
  border: 1px solid transparent;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  height: 48px;
  letter-spacing: -0.4px;
  line-height: 1;
  outline: none;
  padding: 0 15px;
  position: relative;
  text-rendering: antialiased;
  transition: all $transition-time $transition-ease;

  & + & {
    margin-left: 8px;
  }

  &--primary {
    background-color: $primary;
    &:hover,
    &:focus {
      background-color: $primary-dark;
    }
  }

  &--primary-inverse {
    background-color: white;
    color: $primary;

    &:hover,
    &:focus {
      color: $primary-dark;
    }
  }

  &--secondary {
    background-color: $secondary;
    &:hover,
    &:focus {
      background-color: $secondary-dark;
    }
  }

  &--outlined {
    color: $outline-dark;
    background-color: transparent;
    border-color: $unfocus-border;

    &:hover,
    &:focus {
      border-color: currentColor;
    }
  }

  &--outlined-negative {
    color: $negative;
    background-color: transparent;
    border-color: $unfocus-border;

    &:hover,
    &:focus {
      border-color: $negative;
      background-color: $negative-light;
      color: white;
    }
  }

  &--positive {
    background-color: $traffic-light-positive-green-400;

    &:hover,
    &:focus {
      background-color: lighten($traffic-light-positive-green-400, 5%);
    }
  }

  &--negative {
    background-color: $traffic-light-risk-red-500;

    &:hover,
    &:focus {
      background-color: lighten($traffic-light-risk-red-500, 5%);
    }
  }

  &--small {
    font-size: 14px;
    height: 32px;
  }

  &--medium {
    min-width: 160px;
    height: 40px;
  }

  &--large {
    padding-left: 26px;
    padding-right: 26px;
    height: 48px;
    min-width: 160px;
  }

  &[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

a.button {
  display: inline-block;
  line-height: 48px;
  text-align: center;
  text-decoration: none;

  &--small {
    line-height: 32px;
  }

  &--medium {
    line-height: 40px;
  }

  &--large {
    line-height: 48px;
  }
}

.loader {
  animation: loader 0.85s linear infinite;
  transform-origin: center;
}
/* stylelint-enable */
