.core-ui-cell {
  height: 100%;
  min-width: 0;

  &--center {
    text-align: center;
  }

  &--middle {
    display: inline-flex;
    flex-flow: column wrap;
    justify-content: center;
    justify-self: stretch;
  }
}
