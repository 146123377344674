@keyframes LoadingAnimation {
  0% {
    left: -120px;
  }

  100% {
    left: 100%;
  }
}

.loading {
  &Header,
  &Block {
    background-color: var(--active-grey);
    overflow: hidden;
    position: relative;

    &::before {
      animation: LoadingAnimation 4s ease-in-out infinite;
      background: linear-gradient(
        270deg,
        var(--active-grey),
        #fff,
        #fff,
        var(--active-grey)
      );
      bottom: 0;
      content: '';
      display: block;
      opacity: 0.3;
      position: absolute;
      top: 0;
      width: 120px;
    }
  }

  &Header {
    border-radius: 8px;
    height: 24px;
    margin-bottom: 16px;
    width: 200px;
  }

  &Block {
    border-radius: 4px;
    position: relative;
    width: 100%;
    height: 100%;
  }
}
