.EnableNewViewButton {
  float: right;
  margin: 15px 40px 0 0;
  clear: both;
}

.oldViewWrapper {
  background-color: var(--off-white);
}

.BannerSpace {
  height: 65px;
}

.BannerWrapper {
  position: absolute;
  left: 270px;
  right: 20px;
}

.BannerLink {
  flex: 1;
  cursor: pointer;
  text-align: left;
  color: var(--white);
  border: none;
  background-color: var(--transparent);
  align-self: stretch;
  padding-left: 15px;
}

.content {
  padding: 0 32px;
}

.Snackbar {
  z-index: var(--zindex-snackbar);
}

.Error {
  flex: 1;
  text-align: center;
}

.noConversationHistoryHeader {
  display: flex;
  justify-content: space-between;
}

.TimelineHeader {
  display: flex;
  margin-bottom: 30px;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 10px;

  * {
    flex-shrink: 0;
  }
}

.timelineTab {
  display: block;
  color: var(--primary-color);
  text-decoration: none;
  font-size: 20px;
  line-height: 28px;
}

.timelineTab_active {
  // TODO: text-decoration underline OR add a margin to the block element?
  color: var(--button-secondary-color);
  text-decoration: none;
  font-size: 20px;
  line-height: 28px;
  border-bottom: 1px solid var(--babylon-violet);
}

.innerLayout {
  padding: 0 24px;
}

.membersTimeToggle {
  margin-left: auto;
}
