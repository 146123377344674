@import '@babylon/design-tokens/all.scss';

.dataPoints {
  background-color: var(--active-grey);
  border-radius: 0.25rem;
}

.list {
  margin: 0;
}

.dataPointKey {
  color: $darkgray;
  margin-top: 1rem;
}

.dataPointValue {
  margin: 0.25rem 0 0;
}

.dataPointValueHighlighted {
  margin: 0.25rem 0 0;
  border-radius: 0.3rem;
  color: var(--white);
  padding: 0.3rem;
  display: inline-block;
  background-color: var(--highlight-blue);
}
