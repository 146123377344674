:root {
  --modal-border-color: #326fec;
  --overlay-color: tranparent;
}

.InteractionModalOverlay {
  background-color: var(--overlay-color);
}

.InteractionModal {
  border: 2px solid var(--modal-border-color);
  width: 90vw;
  top: 90%;

  & > div {
    margin: 24px 40px;
  }
}

.InteractionModalContentWrapper {
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & > p {
    margin-right: auto;
  }
}

.ModalActions {
  display: flex;
  flex-direction: row;
}
