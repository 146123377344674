.formSection {
  &__title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
  }
}

.fieldRow {
  margin-bottom: 28px;

  &--small {
    min-width: 100px;
    width: 50%;
  }
}

.datePicker {
  text-align: left;
}
