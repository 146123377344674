.form {
  margin-top: 2rem;
}

.buttons {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-top: 1.75rem;
}
