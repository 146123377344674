.heading {
  margin-bottom: 0.75rem;
}

.headingTwo {
  margin-top: 2rem;
}

.diagnosisReportParagraph {
  margin-top: 0.25rem;
}

.relatedSymptoms {
  margin-top: 1rem;

  ul li {
    list-style: inherit;
  }
}

.extraOverview {
  margin-top: 1.5rem;

  ul li {
    list-style: inherit;
  }
}

.desc {
  margin-top: 0.5rem;
}

.leafletLink {
  margin-top: 1.5rem;
}

.ctas {
  margin-top: 2rem;
}

.nhsChoices {
  margin-left: 0.5rem;
}
