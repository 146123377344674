.Messages {
  &__spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }

  &__backToList {
    height: 24px;
    left: 28px;
    border: none;
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    color: var(--babylon-violet);
  }

  &__backToList_icon {
    padding-top: 10px;
    height: 24px;
  }

  &__error {
    padding: 0 20px;
  }

  &__outcome {
    border: 1px solid var(--grey-200);
    border-radius: 8px;
    margin-top: 30px;
    margin-bottom: 16px;
    padding: 24px;
  }

  &__outcomeHeading {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;

    h3 {
      padding-right: 10px;
    }
  }
}
