.AccountServices {
  margin: 0 0 56px 12px;

  &__card {
    background-color: var(--active-grey);
    border-radius: 8px;
    padding: 16px 8px;
  }

  &__link {
    width: 100%;
    color: var(--dark-purple);
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    text-decoration: underline;
    text-align: left;
    padding: 1px 6px;
  }
}
