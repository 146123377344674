.core-dropdown-select {
  position: relative;

  &--fill {
    width: 100%;
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.6;
    cursor: pointer;
  }

  &__input {
    display: flex;
    position: relative;

    flex-direction: row;
    align-items: center;

    border: 1px solid var(--input-border-color);
    border-radius: 3px;
    user-select: none;
    background-color: white;
    font-size: 14px;
    line-height: 16px;
    padding: 0;

    &[role='button']:focus {
      border: 1px solid var(--input-border-color);
    }

    &:hover {
      border-color: var(--input-hover-border-color);
      cursor: pointer;
    }

    &--error {
      border-color: var(--error-color);

      &:hover,
      &[role='button']:focus {
        border-color: var(--error-color);
      }
    }

    abbr[title] {
      text-decoration: none;
    }
  }

  &--focused &__input {
    border-radius: 3px 3px 0 0;
    box-shadow: 0 0 1px 2px var(--input-active-outline-color);
  }

  &__input-search-wrapper {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
  }

  &__input-search-placeholder {
    display: block;
    color: var(--input-placeholder-color);
    opacity: 0.8;
    padding: 7px 25px 7px 7px;

    .core-dropdown-select--has-search-value & {
      opacity: 0;
    }
  }

  &__display-value {
    display: inline-block;
    min-height: 30px;
    position: relative;
    flex: 1;
    text-decoration: none;
    color: var(--input-placeholder-color);
    padding: 7px 25px 7px 7px;

    .core-dropdown-select--has-selected-option & {
      color: inherit;
    }

    .core-dropdown-select--has-search-value & {
      opacity: 0;
    }
  }

  &__input-field {
    display: block;
    border: none;
    margin: 0;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    padding: 7px 25px 7px 7px;
    font-size: 14px;
    line-height: 16px;
  }

  &__input-arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;

    .core-dropdown-select--focused & {
      transform: translateY(-50%) rotate(-180deg);
    }

    .core-dropdown-select--show-clear-button & {
      display: none;
    }
  }

  &__input-clear-button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    display: flex;
    appearance: none;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    line-height: 1;

    &:hover {
      cursor: pointer;
    }

    &__icon {
      width: 12px;
      height: 12px;
    }
  }

  &__options-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: white;
    border: 1px solid var(--input-border-color);
    border-radius: 0 0 3px 3px;
    border-top: none;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.12);
    z-index: 500;
  }

  &__options-message {
    display: block;
    padding: 8.25px;
    color: var(--light-grey-color);
    font-size: 14px;
    line-height: 16px;
  }

  &__option {
    border-bottom: 1px solid var(--input-border-color);
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &[role='button'] {
      display: flex;
    }

    &:hover {
      cursor: pointer;
      background-color: var(--dropdown-select-option-hover-color);
    }

    &:last-child {
      border-bottom-color: transparent;
    }
  }

  &__option-text {
    display: block;
    font-size: 14px;
    line-height: 16px;
    color: var(--input-color);
    padding: 8.25px;
  }
}
