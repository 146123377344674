:root {
  --body-grey: #54606a;
}

.RedeemCodeForm {
  margin-left: 32px;
  max-width: 536px;
  width: 100%;

  &__title {
    padding-bottom: 24px;
    font-size: 24px;
    font-weight: 300;
    color: var(--label-color);
  }

  &__radioGroup {
    --radio-checked-background-color: var(--babylon-violet);
  }

  :global(.core-radio-group__list) {
    display: flex;
    flex-direction: row;
  }

  &__radio {
    &:first-child {
      margin-bottom: 0;
      margin-right: 16px;
    }
  }

  &__CodeInputWrapper {
    display: inline-block;

    & label {
      color: var(--body-grey);
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 4px;
    }
  }

  &__CodeInput {
    display: inline-block;
    margin-right: 24px;
    max-width: 300px;
    height: 40px;
  }

  &__cta {
    align-self: center;
    height: 40px;
    margin-top: 13px;
  }
}
