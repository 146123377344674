.RelatedPersonCard {
  background-color: var(--active-grey);
  margin-bottom: 0.75rem;
  max-width: 300px;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__content {
    display: flex;
    flex-direction: column;
    color: var(--grey-75);
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    margin-top: 10px;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
  }

  &__tag {
    font-size: 12px;
    white-space: normal;
    padding-right: 5px;
  }

  &__familyTag {
    color: var(--white);
    background-color: var(--light-pink-color);
  }

  &__email {
    word-break: break-all;
  }
}

.RelatedPersonCardMenu {
  position: relative;
}
