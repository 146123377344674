.selectedSuggestion {
  margin-top: 2rem;
}

.suggestionButton {
  margin-top: 1.5rem;
}

.heading {
  margin-bottom: 0.5rem;
}

.card {
  text-align: left;
  margin-bottom: 0.5rem;
}

.bannerList {
  & > * {
    margin-top: 1.5rem;
  }
}
