.EnableNewSearchButton {
  float: right;
  margin: 40px 40px 10px 10px;
  clear: both;
}

.BannerSpace {
  height: 80px;
}

.BannerWrapper {
  position: absolute;
  left: 248px;
  right: 0;
}

.BannerLink {
  flex: 1;
  cursor: pointer;
  text-align: left;
  color: var(--white);
  border: none;
  background-color: var(--transparent);
  align-self: stretch;
  padding-left: 15px;
}
