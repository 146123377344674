@import '../../util/constants';

.root {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: $z-index-overlay;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
