.core-ui-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 4px;

  &__overlay {
    background-color: rgba(80, 80, 80, 0.75);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9000;
  }

  &__content {
    margin: 30px;
  }

  &__header {
    display: flex;
  }

  &__spacer {
    flex-grow: 1;
  }

  &__close-button {
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 8px;

    svg {
      display: block;
    }
  }
}
