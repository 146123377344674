@import '../constants.scss';

.starRating {
  &__label {
    font-size: 1rem;
    margin: 0 0 0.25rem;
    padding: 0 0.25rem;
  }

  &__list {
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;

    &:hover,
    &--focus {
      .starRating__control {
        opacity: 0.5;

        &:hover,
        &:focus,
        &[disabled] {
          opacity: 1;
          outline: 0;
        }
      }
    }
  }

  &__item {
    float: left;
  }

  &__control {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0.25rem;
    margin: 0;
    transition: opacity $transition-time $transition-ease;

    &[disabled] {
      cursor: default;
    }
  }
}
