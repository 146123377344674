.AppointmentCardDetails {
  &__section {
    border-radius: 4px;
    background: var(--active-grey);
    padding: 16px;
    margin-top: 16px;
  }

  @media (min-width: 1280px) {
    &__section {
      display: grid;
      grid-template-columns: 5fr 6fr;
      grid-column-gap: 10px;
    }
  }

  &__onecolumn {
    display: block;
  }

  &__label {
    font-weight: 700;
  }

  &__value {
    margin-bottom: 24px;
  }
}
