.Header {
  &__header {
    font-size: 12px;
    margin-bottom: 20px;
  }

  &__heading {
    display: inline-block;
    margin-right: 8px;
  }

  &__tooltip {
    display: inline-block;
    vertical-align: middle;
  }

  &__actions {
    margin-top: 4px;
  }
}
