.title {
  margin-bottom: 2rem;
}

.description {
  margin-bottom: 1.5rem;
}

.buttons {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-top: 1.75rem;
}
