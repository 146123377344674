.core-ui-pagination {
  &__wrapper {
    display: flex;
    flex-direction: row;
  }

  &__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    appearance: none;
    margin: 0;
    background-color: white;
    outline: none;
    user-select: none;
    font-size: 14px;
    line-height: 19px;
    color: var(--input-color);
    padding: 7px 10px;
    border: 1px solid var(--core-ui-pagination-border-color);
    border-right: none;
    border-radius: 0;

    &:first-child {
      border-radius: 3px 0 0 3px;
    }

    &:last-child {
      border-radius: 0 3px 3px 0;
      border-right: 1px solid var(--core-ui-pagination-border-color);
    }

    &:not(.core-ui-pagination__button--seperator):hover {
      cursor: pointer;
      border-color: var(--core-ui-pagination-hover-border-color);

      &:not(:last-of-type) {
        border-right-color: transparent;
      }
    }

    &:hover:not(:last-of-type):not(.core-ui-pagination__button--selected):not(.core-ui-pagination__button--seperator)
      + &:not(.core-ui-pagination__button--selected) {
      border-left-color: var(--core-ui-pagination-hover-border-color);
    }

    &--selected {
      border-color: var(--core-ui-pagination-selected-background-color);
      background-color: var(--core-ui-pagination-selected-background-color);
      color: var(--core-ui-pagination-selected-text-color);
      border-right-color: transparent;

      &:hover {
        border-color: var(--core-ui-pagination-selected-hover-border-color);
      }
    }

    &--selected + & {
      border-left-color: var(--core-ui-pagination-selected-background-color);
    }

    &--selected:hover:not(:last-child) + & {
      border-left-color: var(--core-ui-pagination-selected-hover-border-color);
    }

    &-icon {
      &--next {
        transform: rotate(180deg);
      }
    }
  }
}
