.core-ui-filter-panel {
  --white: #fff;
  --type-grey: #6b7785;

  background-color: var(--light-blue-color);

  &__wrapper {
    padding: 15px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .core-ui-filter-panel--hasFilters &,
    .core-ui-filter-panel--open & {
      margin-bottom: 15px;
    }

    &__openButton {
      display: flex;
      flex-direction: row;
      align-items: center;
      appearance: none;
      background-color: transparent;
      border: 0;
      padding: 0;
      text-align: left;
      outline: none;
      user-select: none;

      &:hover {
        cursor: pointer;
      }
    }

    &__buttonIcon {
      width: 20px;
      height: 20px;
    }

    &__buttons {
      display: flex;
      flex-direction: row;
    }

    &__buttonWrapper {
      margin-right: 5px;

      &:last-of-type {
        margin-right: 0;
      }

      &--sortButton {
        position: relative;
      }
    }

    &__sortOptions {
      position: absolute;
      top: calc(100% + 10px);
      right: 0;
      background-color: var(--white);
      width: 150px;
      z-index: 100;
      box-shadow: 0 0 16px 2px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      overflow: hidden;
      display: none;

      .core-ui-filter-panel--sortPopupOpen & {
        display: block;
        animation: sort-popup-in-animation 100ms ease-in;
      }
    }

    &__sortList {
      list-style: none;
      margin: 0;
      padding: 0;
      border: 1px solid var(--core-ui-filter-panel-popup-border-color);
    }

    &__sortButton {
      padding: 0 10px 8px;
      border: 0;
      margin: 0;
      appearance: none;
      background-color: transparent;
      width: 100%;
      outline: none;
      text-align: left;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;

      &:first-child {
        padding-top: 8px;
      }

      &:hover {
        cursor: pointer;
        background-color: var(--light-blue-color);
      }
    }

    &__button {
      padding: 5px;
    }

    &__chevron {
      width: 12px;
      height: 12px;
      padding: 0;
      fill: var(--type-grey);
      margin: 0 6px;
      line-break: 1;
      transition: transform 0.2s ease;
      transform: rotate(-90deg);

      .core-ui-filter-panel--open & {
        transform: rotate(0deg);
      }
    }

    &__heading {
      display: block;
      font-size: 14px;
      line-height: 18px;
      font-weight: 300;
      color: var(--light-grey-color);
    }
  }

  &__form {
    display: none;

    .core-ui-filter-panel--open & {
      display: block;
    }
  }

  &__body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    &--hasButtons {
      margin: 0 -7.5px;
    }

    .core-ui-filter-panel--open & {
      display: none;
    }
  }

  &__filtersWrapper {
    width: 100%;

    .core-ui-filter-panel__body--hasButtons & {
      width: 60%;
      padding: 0 7.5px;
    }
  }

  &__filtersBox {
    padding: 10px 10px 5px;
    background-color: var(--white);
    border: 1px solid var(--core-ui-filter-panel-filters-border-color);
  }

  &__filtersBoxTag {
    margin: 0 5px 5px 0;
  }
}

@keyframes sort-popup-in-animation {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
