.WarningCard {
  width: 100%;
  box-shadow: none;

  &__wrapper {
    padding-bottom: 16px;
    padding-left: 37px;
    position: relative;
    display: flex;
    flex-direction: row;

    &::after {
      background: var(--timeline-path);
      bottom: 0;
      content: '';
      position: absolute;
      top: 45px;
      width: 1px;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }

  &__summary {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__summaryContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: left;
  }

  &__menu {
    position: absolute;
    right: 22px;
    top: 16px;
  }

  @media (min-width: 1280px) {
    &__summaryContent {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      align-items: center;
      justify-content: left;
    }

    &__summaryContent > * {
      margin-right: 5px;
    }

    &__menu {
      position: relative;
      right: inherit;
      top: inherit;
    }
  }

  &__inlineToggler {
    margin-right: 5px;
  }

  &__title {
    font-weight: 900;
  }

  &__cardNetworkTag {
    max-width: 200px;
  }

  &__warningTag {
    background: var(--white);
    border: 1.5px solid var(--warning-tag-border);
    font-size: 12px;
    font-weight: 900;
    color: var(--label-color);
    margin-right: 8px;
  }

  &__date {
    color: var(--grey-75);
    font-size: 16px;
    line-height: 20px;
  }
}

.Details {
  &__section {
    border-radius: 4px;
    background: var(--active-grey);
    padding: 16px;
    margin-top: 16px;
  }

  @media (min-width: 1280px) {
    &__section {
      display: grid;
      grid-template-columns: 5fr 6fr;
      grid-column-gap: 10px;
    }
  }

  &__onecolumn {
    display: block;
  }

  &__label {
    font-weight: 700;
  }

  &__value {
    margin-bottom: 24px;
  }

  &__cancellationReason {
    color: var(--error-color);
    margin: 0;
  }

  &__link {
    display: inline;
    color: var(--primary-color);
    font-weight: normal;
    text-decoration: underline;
    margin-right: 4px;
    vertical-align: top;
  }
}
