.membership {
  border-bottom: 1px solid var(--card-border);
  padding: 16px;
}

.currentMembership__heading {
  &Wrapper {
    display: flex;
    align-items: center;
  }

  &Icon {
    margin-right: 8px;
  }

  &Label {
    font-size: 14px;
    color: var(--babylon-violet);
  }
}

.membership--grouped {
  &:last-child {
    border: none;
    padding-bottom: 0;
  }
}

.membershipData {
  display: flex;
}

.membershipData__name {
  align-items: center;
  color: var(--grey-75);
  display: flex;
  font-weight: 500;
}

.membershipData__value {
  color: var(--grey-75);
}

.consumerNetwork {
  color: var(--white);
}

.consumerNetwork__buttons {
  grid-column-start: 2;
  margin-top: 4px;
}

.consumerNetwork__button {
  display: block;
  margin: 8px 0;
}

.moreHistory {
  grid-column-start: 2;
  margin-bottom: 4px;
}

.moreHistory__button {
  text-align: left;
}

.moreHistory__date {
  border-bottom: 1px solid var(--card-border);
  padding: 4px 0 2px;

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

.tag {
  color: var(--white);
  display: inline-flex;
  justify-content: center;
  min-width: 80px;
  text-align: center;
  white-space: normal;
}

.tag--active {
  background-color: var(--primary-color);
}

.tag--removed {
  background-color: var(--blue-grey);
}
