.core-pill {
  --pill-selected-color: var(--primary-100);
  --pill-selected-background-color: var(--primary-50);
  --pill-selected-border-color: var(--primary-60);
  --pill-color: var(--grey-75);
  --pill-border-color: var(--grey-50);
  --pill-disabled-background-color: var(--grey-50);

  display: inline-block;

  text-decoration: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: relative;
  box-sizing: content-box;
  line-height: 1;
  border-radius: 3px;
  padding: 8px 14px;
  color: var(--pill-color);
  border: 1px solid var(--pill-border-color);

  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;

  outline: none;
  cursor: pointer;

  transition: transform 0.2s, opacity 0.2s;

  &--selected {
    color: var(--pill-selected-color);
    background: var(--pill-selected-background-color);
    border: 1px solid var(--pill-selected-border-color);
  }

  &:focus {
    border: 1px solid var(--pill-selected-border-color);
  }

  &[disabled],
  &--disabled {
    opacity: 0.6;
    color: var(--pill-color);
    background: var(--pill-disabled-background-color);
    border: 1px solid var(--pill-border-color);
    cursor: not-allowed;
  }

  & + & {
    margin-left: 10px;
  }
}

.core-pill-container {
  margin: 4px -4px;

  .core-pill {
    margin: 4px;
  }
}
