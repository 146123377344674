.accountTags {
  display: flex;
  margin-bottom: 16px;
}

.accountTag {
  color: var(--white);
  font-size: 12px;
  font-weight: 900;
  margin-right: 8px;
}

.accountTag--family {
  @extend .accountTag;
  background-color: var(--light-pink-color);
}

.accountTag--idVerified {
  @extend .accountTag;
  background-color: var(--babylon-violet);
}

.accountTag--idVerificationPending {
  @extend .accountTag;
  background-color: var(--warning-color);
}

.accountTag--idVerificationFailed {
  @extend .accountTag;
  background-color: var(--error-color);
}

.accountTag--noIdVerificationRecord {
  @extend .accountTag;
  background-color: var(--error-color);
}

.accountTag--owner {
  @extend .accountTag;
  background-color: var(--transparent);
  border: 1px solid var(--light-pink-color);
  color: var(--text-color);
}

.accountTag--minor,
.accountTag--warning {
  @extend .accountTag;
  background-color: var(--warning-color);
}

.accountTag--error {
  @extend .accountTag;
  background-color: var(--error-color);
}

.accountTag--preregistered {
  @extend .accountTag;
  background-color: var(--info-tag-bg);
  color: var(--white);
}

.accountTag--isSmsMFAActive {
  @extend .accountTag;
  background-color: var(--button-secondary-disabled-color);
}

.accountTag--legalConsent {
  @extend .accountTag;
  background-color: var(--error-color);
}

.accountTag--bupa {
  @extend .accountTag;
  background-color: var(--medical-blue);
}

.accountTag--preferredCN {
  @extend .accountTag;
  background-color: var(--yellow-color);
  color: var(--white);
}
