@import '../constants.scss';

:export {
  primary: $primary;
  primaryLight: $primary-light;
}

.radio {
  display: block;
  width: 100%;
  padding-left: 15px;
  position: relative;

  + .radio {
    margin-top: 16px;
  }

  &__label {
    cursor: pointer;
    padding-left: 8px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      border-width: 1px;
      border-style: solid;
      height: 16px;
      width: 16px;
      border-radius: 100%;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      transition-property: border-color;
      transition-duration: 0.15s;
      transition-timing-function: linear;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      transform: scale(0.1);
      height: 10px;
      width: 10px;
      border-radius: 100%;
      left: 3px;
      top: 6px;
      background-color: var(--main-colour, $primary);
      opacity: 0;
      transition-property: transform, opacity;
      transition-duration: 0.15s;
      transition-timing-function: linear;
    }
  }

  &--disabled &__label {
    color: $secondary-dark;
    cursor: not-allowed;
  }

  &__input {
    position: absolute;
    opacity: 0;

    &:checked + .radio__label {
      &:before {
        padding: 0;
        border-width: 1px;
        border-color: var(--main-colour, $primary);
        background-color: #fff;
      }

      &:after {
        transform: scale(1);
        opacity: 1;
      }
    }

    &:focus + .radio__label::before {
      border-color: var(--main-colour, $primary);
    }

    & + .radio__label {
      &:hover {
        &:before {
          border-color: var(--main-colour, $primary);
          background-color: var(--main-colour-light, $primary-light);
        }
      }
      &:before {
        border-color: $unfocus-border;
        background-color: transparent;
      }
    }
  }

  &--disabled &__input + .radio__label:hover:before {
    border-color: $unfocus-border;
  }
}

.pills,
.pillsJoined {
  color: #777;
  display: inline-block;
  margin-bottom: 1rem;

  &__input {
    opacity: 0;
    transform: scale(0.1);
    position: absolute;
    top: 0;
    left: 0;
  }

  &__input:checked + &__label,
  &__input:focus + &__label {
    color: white;
    background-color: var(--main-colour, $primary);
    border-color: var(--main-colour, $primary);
  }

  &__label {
    border-width: 1px;
    border-style: solid;
    border-color: #dfdfdf;
    padding: 0.5rem 1rem 0.45rem;
    display: block;
    cursor: pointer;
    line-height: 1em;

    transition-property: background-color;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;

    &:hover {
      background-color: var(--main-colour-light, $primary-light);
      border-color: var(--main-colour, $primary);
      color: var(--main-colour, $primary);
    }

    &--loading {
      width: 100px;
      @include shimmerAnimation;

      .pills__labelContent,
      .pillsJoined__labelContent {
        visibility: hidden;
        white-space: pre;
      }

      cursor: initial;
      &:hover {
        border-color: #dfdfdf;
      }
    }
  }
}

.pills {
  margin-right: 1rem;

  &__label {
    border-radius: 1rem;
  }
}

.pillsJoined {
  &__label {
    margin-right: -1px;
    position: relative;
    z-index: 1;
  }

  &__input:checked + &__label,
  &__input:focus + &__label,
  &__label:hover {
    z-index: 2;
  }

  &:first-child &__label {
    border-radius: 1rem 0 0 1rem;
  }

  &:last-child &__label {
    border-radius: 0 1rem 1rem 0;
    margin-right: 0;
  }
}

.control-items-scroll {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
