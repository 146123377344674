.selectWidget {
  :global {
    .Select.is-focused:not(.is-open) > .Select-control {
      box-shadow: none;
    }

    .Select {
      outline: none;

      &:focus .Select-control {
        border-color: #319bf5;
      }
    }

    .Select-input {
      height: 30px;
    }

    .Select-control {
      border: 1px solid #dfdfdf;
      border-radius: 3px;
      background: #fff;
      height: 44px;
      padding: 6px 2px;
      box-sizing: border-box;
    }

    .Select-multi-value-wrapper {
      height: 0;
    }

    .Select-clear {
      color: #666;

      &:hover {
        color: darkred;
      }
    }

    .Select-arrow {
      border-top-color: #666;
    }
    .Select-arrow-zone:hover .Select-arrow {
      border-top-color: #484848;
    }

    .Select--multi .Select-value {
      background-color: #e9f0fb;
      font-size: 10pt;
      color: #3861bf;
      display: inline-block;
      line-height: 20px;
      margin: 3px 5px;
      vertical-align: top;
      border: 0;
      border-radius: 5px;
      padding: 0;
    }

    .Select--single .Select-value-label {
      line-height: 48px;
      padding-left: 4px;
    }

    .Select-value-label {
      padding: 2px 8px;
    }

    .Select-value-icon {
      color: #3861bf;
      border-right: 1px solid rgba(56, 97, 191, 0.1);
      padding: 0px 8px;
      line-height: 24px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;

      &:hover {
        color: #3861bf;
        background-color: rgba(56, 97, 191, 0.1);
      }
    }

    .Select-placeholder {
      line-height: 44px;
    }
  }

  .error {
    font-size: 14px;
    color: #ff4743;
  }
}
