.conditionSummary {
  box-shadow: 0 3px 8px -1px #0000000a, 0 0 1px 0 #0c1a4b3d;
  border: 0;
  outline: none;
  margin-bottom: 1rem;
  border-radius: 1rem;
  text-align: left;
  width: 100%;
}

.heading {
  margin-bottom: 0.25rem;
}

.inner,
.bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.inner {
  padding: 1rem 1rem 0.75rem 1rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.left {
  flex: 1;
  margin-right: 2rem;
}

.bottom {
  padding: 1rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;

  svg {
    margin-right: 0.6rem;
  }
}
