.core-breadcrumbs {
  display: block;
  list-style: none;
  margin: 0;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 16px;

  &--separator {
    border-bottom: 1px solid var(--grey-25);
  }

  &__item {
    color: var(--grey-75);
    display: inline-block;
    line-height: 24px;

    &::after {
      content: '';
      color: var(--grey-100);
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url('./chevron.svg');
      transform: rotate(-90deg);
      vertical-align: top;
    }

    a {
      color: inherit;
      text-decoration: none;
      display: inline-block;

      &:hover {
        color: inherit;
        text-decoration: underline;
      }
    }

    &:last-child {
      color: var(--grey-100);

      &::after {
        display: none;
      }
    }
  }
}
