.consumerNetworks {
  border-radius: 4px;
  border: solid 1px var(--card-border);
  padding: 8px 16px 16px 16px;

  &--active {
    margin-bottom: 20px;
  }

  &--past__heading {
    margin-top: 40px;
    margin-bottom: 8px;
    text-transform: capitalize;
  }

  &__showPastNetworks {
    padding: 0;
  }

  &__hidePastNetworks {
    margin-top: 16px;
    padding: 0;
  }
}

.separateConsumerNetwork {
  border: solid 1px var(--card-border);
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.loadingBlock {
  height: 188px;
}
