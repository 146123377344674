.searchBox {
  display: inline-block;
  height: 28px;
  border-radius: 14px;
  background-color: #ffffff;
  border: solid 1px #dddddd;
  color: #637280;
  padding: 1px 8px 1px 10px;

  input {
    border: 0;
    margin: 0;
    padding: 0;
    outline: none;
    vertical-align: top;
    margin-top: 3px;
  }

  button {
    border: 0;
    background: transparent;
    margin: 0;
    padding: 0;
  }

  .icon {
    color: #637280;
  }
}
