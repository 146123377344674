.familyMember {
  background-color: #f3fafd;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.spinner {
  height: 80px;
}
