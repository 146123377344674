.EntriesTimelineWrapper {
  max-width: 600px;
}

.EntriesTimelineGroup {
  display: flex;
  flex-direction: column;

  &__heading {
    margin-bottom: 24px;
  }
}
