@import '../global-constants';
@import '../../../design-tokens/functions.scss';

$list-color: var(--medkit-list-base-color, $grey-900);
$list-single-line-break: var(--medkit-list-single-line-break, rem-calc(24));

$list-body-font-size: var(--medkit-list-body-font-size, $base-font-size);
$list-indent: var(--medkit-list-indent, rem-calc(25));
$list-body-line-height: var(--medkit-list-body-line-height, rem-calc(24));
$list-item-margin-bottom: var(--medkit-list-margin-bottom, rem-calc(10));

.List {
  padding: 0;
  font-weight: normal;
  color: $list-color;
  margin-top: 0;
  margin-left: 0;
  margin-bottom: $list-single-line-break;
  text-indent: rem-calc(6);
  padding-left: $list-indent;

  &__item {
    font-size: $list-body-font-size;
    line-height: $list-body-line-height;
    margin-bottom: $list-item-margin-bottom;
  }
}
