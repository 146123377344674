.header {
  background-color: var(--off-white);
  display: flex;
  justify-content: space-between;
  padding: 32px 72px 16px 44px;

  &__name {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 12px;
  }

  &__edit {
    font-weight: 600;

    &::after {
      content: ': ';
    }
  }

  &__lastName {
    text-transform: uppercase;
  }

  &__buttons {
    display: grid;
    grid-auto-rows: minmax(min-content, max-content);
    grid-template-columns: auto auto;
    grid-column-gap: 16px;
  }
}
