:root {
  --wrapper-bg: #e8f7f5;
}

.ConsultationNote {
  &__wrapper {
    background-color: var(--wrapper-bg);
    border-radius: 3px;
    padding-bottom: 20px;
  }

  &__wrapper div:first-child {
    padding-top: 1px;
  }

  &__heading {
    padding: 0 0 10px 0;
    display: flex;
  }

  &__subheading {
    margin: 0 10px;
    padding: 10px;
    display: flex;
  }

  &__card {
    margin: 5px 20px;
    padding: 10px;
    box-shadow: 0 0 0 0;
  }

  &__scheduledTime {
    margin-bottom: 20px;
  }
}
