@import '../BaseInput/BaseInput.variables';

.Select {
  &__select {
    cursor: pointer;
    appearance: none;
    user-select: none;

    &::-ms-expand {
      display: none;
    }
  }

  &__icon {
    pointer-events: none;
  }

  &__iconImage {
    fill: $label-color;
  }
}
