@import '../global-constants';

$card-border-radius: var(
  --medkit-size-card-border-radius,
  var(--medkit-size-border-radius, $border-radius-large)
);
$card-background: var(--medkit-card-background, $sterile-white);
$card-shadow: var(--medkit-card-shadow, $grey-100);
$card-padding: var(--medkit-card-padding, $size-medium);
$card-image-height: var(--medkit-card-image-height, 10rem);
$card-title-color: var(--medkit-card-title-color, $grey-900);
$card-text-color: var(--medkit-card-text-color, $note-black);

.Card {
  border-radius: $card-border-radius;
  font-family: $font;
  background-color: $card-background;
  box-shadow: 0 2px $size-small 0 $card-shadow;
  margin-bottom: $size-medium;
  width: 100%;

  &_layout {
    display: flex;
    width: 100%;
  }

  &_interactive {
    &:focus,
    &:hover {
      border-radius: $card-border-radius;
      box-shadow: 0 2px $size-small 0 $card-shadow,
        0 4px $size-small 0 $card-shadow;
    }
  }

  &_vertical {
    flex-direction: column;

    .Card__image {
      min-height: $card-image-height;
      border-radius: $card-border-radius $card-border-radius 0 0;
    }
  }

  &_callToAction {
    display: flex;
    justify-content: center;
    border-top: 1px solid $card-shadow;
    padding: calc(#{$card-padding} - #{rem-calc(10)});
  }

  &__thumbnail {
    flex-basis: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: rem-calc(20);
    padding-left: rem-calc(5);
    padding-bottom: rem-calc(20);
    margin-right: -#{rem-calc(16)};
  }

  &__image {
    flex: 1;
    border-radius: $card-border-radius 0 0 $card-border-radius;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &__title,
  &__subTitle {
    margin-top: 0;
    margin-bottom: 0;
  }

  &__title {
    color: $card-title-color;
  }

  &__subTitle {
    color: $card-text-color;
  }

  &__content {
    flex: 1;
    padding: $card-padding;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 0;
  }
}
