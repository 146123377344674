.Entry {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 24px;

  &__time {
    color: var(--grey-600);
    margin-bottom: 6px;
    font-size: 16px;
    line-height: 24px;
  }

  &__preview {
    flex: 1;
    text-align: left;
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 50%;
    padding-left: 10px;
  }

  &__wrapper {
    min-height: 56px;
    border-radius: 8px;
    padding: 0 16px;
    border: 1px solid var(--click-down-grey);
    background-color: var(--white);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &--btn {
      cursor: pointer;
    }
  }

  &__flexend {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
}
