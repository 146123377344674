.core-text {
  &,
  &--medium {
    font-size: 14px;
    line-height: 20px;
  }

  &--small {
    font-size: 12px;
    line-height: 16px;
  }

  &--large {
    font-size: 16px;
    line-height: 20px;
  }

  &--bold {
    font-weight: 600;
  }

  &--not-bold {
    font-weight: 400;
  }

  &--italic {
    font-style: italic;
  }

  &--not-italic {
    font-style: initial;
  }

  &--ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--not-uppercase {
    text-transform: none;
  }

  &--align {
    &-left {
      text-align: left;
    }

    &-center {
      text-align: center;
    }

    &-right {
      text-align: right;
    }
  }
}
