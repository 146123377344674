:root {
  --tag-success-bg: rgba(75, 217, 99, 0.05);
  --tag-fail-bg: rgba(255, 158, 2, 0.05);
}

.RepeatTemplateCard {
  width: 100%;

  &__wrapper {
    padding-bottom: 16px;
    padding-left: 37px;
    position: relative;
    display: flex;
    flex-direction: row;

    &::after {
      background: var(--timeline-path);
      bottom: 0;
      content: '';
      position: absolute;
      top: 45px;
      width: 1px;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }

  &__icon {
    fill: var(--grey-75);
  }

  &__summary {
    display: grid;
    grid-row-gap: 8px;
  }

  &__menu {
    position: absolute;
    right: 22px;
    top: 16px;
  }

  &__viewMore {
    width: 100%;
    justify-content: center;
    margin-top: 15px;
  }

  @media (min-width: 1280px) {
    &__summary {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: repeat(2, 6fr) 1fr;
      grid-template-rows: repeat(3, min-content);
      gap: 8px 5px;
      grid-template-areas:
        '. . menu'
        '. . menu'
        'viewmore duration menu';
      align-items: center;
    }

    &__menu {
      grid-area: menu;
      position: relative;
      right: inherit;
      top: inherit;
    }

    &__durationCell {
      grid-area: duration;
    }

    &__viewMoreCell {
      grid-area: viewmore;
    }

    &__viewMore {
      width: auto;
      justify-content: left;
      margin-top: auto;
    }
  }

  &__title {
    font-weight: 900;
  }

  &__cardNetworkTag {
    max-width: 200px;
  }

  &__date {
    color: var(--grey-75);
    font-size: 16px;
    line-height: 20px;
  }

  &__label {
    @mixin genericLabel {
      border-radius: 2px;
      font-size: 12px;
      margin-left: 8px;

      @media (min-width: 1280px) {
        margin: 3px;
        padding: 0 5px;
      }
    }

    &--success {
      @include genericLabel;

      background-color: var(--tag-success-bg);
      border: 1px solid var(--success-color);
      color: var(--success-color);
    }

    &--warning {
      @include genericLabel;

      background-color: var(--tag-fail-bg);
      border: 1px solid var(--warning-color);
      color: var(--warning-color);
    }

    &--error {
      @include genericLabel;

      background-color: var(--tag-fail-bg);
      border: 1px solid var(--error-color);
      color: var(--error-color);
    }
  }

  &__pharmacyName,
  &__pharmacyPhoneNumber {
    font-size: 16px;
    line-height: 20px;
    padding: 0;
  }

  &__pharmacyLink {
    color: var(--petroleum-teal);
    text-decoration: underline;
  }
}

.Details {
  &__section {
    border-radius: 4px;
    background: var(--active-grey);
    padding: 16px;
    margin-top: 16px;
  }

  @media (min-width: 1280px) {
    &__section {
      display: grid;
      grid-template-columns: 5fr 6fr;
      grid-column-gap: 10px;
    }
  }

  &__onecolumn {
    display: block;
  }

  &__label {
    font-weight: 700;
  }

  &__value {
    margin-bottom: 24px;
  }
}
