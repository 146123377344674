@import '../../util/constants';

.root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex: 1;
  overflow: auto;
  justify-content: center;
  -webkit-overflow-scrolling: scroll;
}

.contentMain {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $content-width;
  padding: 0 1rem 0.625rem;
}

.contentAccessory {
  @extend .contentMain;
  margin-left: 100px;
}
