.MediumIcon {
  &__medium {
    flex-shrink: 0;
    left: 0;
    z-index: 1;
    margin-left: -12px;
    margin-right: 24px;
    width: 24px;
  }

  &__iconWrapper {
    background: var(--white-color);
    color: var(--grey-75);
    display: flex;
    justify-content: center;
    padding: 14px 0 12px;
    position: relative;
    z-index: 2;
    line-height: 0;

    &--warning {
      padding-top: 16px;
      color: var(--error-color);
    }
  }

  &__icon {
    display: block;
    fill: var(--grey-75);
    height: 24px;
    width: 24px;

    &--warning {
      fill: var(--error-color);
    }
  }
}
