@import '../../util/constants';

/* Export these values to the CSS Modules to access them in JS */
@value transitionDuration: 400ms; /* stylelint-disable-line at-rule-no-unknown */
@value transitionDelay: 400ms; /* stylelint-disable-line at-rule-no-unknown */

$distance: 100px;

.root {
  display: flex;
  flex-grow: 1;
  margin-bottom: $question-margin-bottom;
  position: relative;
}

.enter {
  opacity: 0;
  transform: translateX($distance);

  .didGoBack & {
    transform: translateX($distance * -1);
  }

  // Disable the slide animation when the question changes for users who prefer reduced motion,
  // but leave the opacity cross fade on to help users understand the question changed
  @media (prefers-reduced-motion: reduce) {
    transform: none !important; /* stylelint-disable-line declaration-no-important */
  }
}

.enterActive {
  opacity: 1;
  transform: translateX(0);
  transition: all transitionDuration ease-out;
  transition-delay: transitionDelay;

  .didGoBack & {
    transform: translateX(0);
  }

  @media (prefers-reduced-motion: reduce) {
    transform: none !important; /* stylelint-disable-line declaration-no-important */
  }
}

.exit {
  opacity: 1;
  transform: translateX(0);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.exitActive {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: all transitionDuration ease-in;
  transform: translateX($distance * -1);

  .didGoBack & {
    transform: translateX($distance);
  }

  @media (prefers-reduced-motion: reduce) {
    transform: none !important; /* stylelint-disable-line declaration-no-important */
  }
}
