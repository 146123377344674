/* stylelint-disable custom-property-empty-line-before */
@import './mixins';

[class*='core-'] {
  @include component();
}

:root {
  --primary-color: #{$primary-color};

  --error-color: #{$error-color};
  --warning-color: #{$warning-color};
  --success-color: #{$success-color};
  --light-grey-color: #{$light-grey-color};
  --light-blue-color: #{$light-blue-color};
  --teal-color: #{$teal-color};
  --light-pink-color: #{$light-pink-color};
  --yellow-color: #{$yellow-color};

  --cool-blue-grey-color: #{$cool-blue-grey-color};
  --white-color: #{$white-color};

  --grey-10: #fafafa;
  --grey-25: #dddfe1;
  --grey-50: #bfc3cb;
  --grey-75: #637280;
  --grey-100: #282824;

  --red-10: #ffebea;
  --red-100: #ff3b2f;

  --green-10: #edfbef;
  --green-100: #4bd963;

  --yellow-10: #fefbe9;
  --yellow-100: #fcd920;

  --primary-10: #e9f8f8;
  --primary-50: #{lighten($teal-color, 50%)};
  --primary-60: #{lighten($teal-color, 40%)};
  --primary-100: #2bbbbb;
  --action-green-type: #26a6a6;
  --action-green: var(--primary-100);

  /* standardized colors */
  //TODO: add remaining colors during color 'cleanup'
  --type-positive: #26a6a6;
  --type-negative: #e94032;
  --type-neutral: #ee9300;
  --type-blue: #3761bf;

  --background-blue: #e4effb;
  --background-blue-light: #f1f7fd;
  --medical-blue: #4a90e2;

  --violet: #400099;
  --violet-dark: #2d0c59;
  --violet-light: #ece6f5;

  --background-positive: #e9f8f8;
  --background-negative: #ffebea;
  --background-neutral: #fff5e5;

  /* Fonts */
  --font-family: 'Avenir', -apple-system, sans-serif;

  /*
    Button
  */

  /* -- Primary */
  --button-primary-color: #fff;
  --button-primary-background: #{$primary-color};
  --button-primary-border-color: transparent;
  --button-primary-hover-background: #{lighten($primary-color, 2%)};
  --button-primary-active-background: #{darken($primary-color, 3%)};

  /* -- Secondary */
  --button-secondary-color: #000;
  --button-secondary-disabled-color: #9ea9b3;
  --button-secondary-background: #fff;
  --button-secondary-border-color: #c5d0de;
  --button-secondary-hover-background: #f9f9f9;
  --button-secondary-active-background: #f3f3f3;

  /* -- Error */
  --button-error-color: #fff;
  --button-error-background: #{$error-color};
  --button-error-border-color: #{$error-color};
  --button-error-hover-background: #{lighten($error-color, 2%)};
  --button-error-active-background: #{darken($error-color, 3%)};

  /* -- Warning */
  --button-warning-color: #fff;
  --button-warning-background: #{$warning-color};
  --button-warning-border-color: #{$warning-color};
  --button-warning-hover-background: #{lighten($warning-color, 2%)};
  --button-warning-active-background: #{darken($warning-color, 3%)};

  /* -- Success */
  --button-success-color: #fff;
  --button-success-background: #{$success-color};
  --button-success-border-color: #{$success-color};
  --button-success-hover-background: #{lighten($success-color, 2%)};
  --button-success-active-background: #{darken($success-color, 3%)};

  /*
    Link
  */
  --link-color: var(--primary-color);

  /*
    Input
  */
  --input-color: #{$input-text-color};
  --input-placeholder-color: #aab7c4;
  --input-background: #fff;

  /* -- None */
  --input-border-color: #{$border-color};
  --input-hover-border-color: #{darken($border-color, 5%)};
  --input-active-border-color: #{darken($border-color, 10%)};
  --input-active-outline-color: #{rgba($border-color, 0.3)};
  --input-border-color-light: #e0e7ea;

  /* -- Error */
  --input-error-border-color: #{$error-color};
  --input-error-active-outline-color: #{rgba($error-color, 0.2)};

  /* -- Warning */
  --input-warning-border-color: #{$warning-color};
  --input-warning-active-outline-color: #{rgba($warning-color, 0.2)};

  /* -- Success */
  --input-success-border-color: #{$success-color};
  --input-success-active-outline-color: #{rgba($success-color, 0.2)};

  /* Checkbox */
  --checkbox-checked-background-color: #{$primary-color};
  --checkbox-border: 1px solid #{$border-color};
  --checkbox-hover-border: 1px solid #{darken($border-color, 5%)};

  /* Radio */
  --radio-checked-background-color: #{$primary-color};

  /* Spinner */
  --spinner-color: #{$input-text-color};

  /* Tables */
  --table-header-color: var(--grey-75);
  --table-header-border-color: var(--grey-75);
  --table-striped-row-background-color: var(--grey-10);
  --table-row-background-color: #fff;
  --table-row-divider-color: #{$border-color};

  /* Tags */
  --tag-black-text-color: #34495e;
  --tag-primary-color: #{$primary-color};
  --tag-primary-text-color: #2bbbbb;
  --tag-highlight-violet-color: #{rgba($highlight-violet-color, 0.2)};
  --tag-highlight-violet-text-color: #{$highlight-violet-color};
  --tag-primary-border-color: #{darken($primary-color, 5)};
  --tag-primary-light-color: #{$primary-light-color};
  --tag-primary-light-border-color: #{darken($primary-light-color, 5)};

  /* Dropdown Select */
  --dropdown-select-option-hover-color: #e8f0fb;

  /* Filter panel */
  --core-ui-filter-panel-filters-border-color: $light-blue-border-color;
  --core-ui-filter-panel-popup-border-color: $light-blue-border-color;

  /** Pagination **/
  --core-ui-pagination-border-color: #{$border-color};
  --core-ui-pagination-hover-border-color: #{darken($border-color, 5%)};
  --core-ui-pagination-selected-background-color: #{$teal-color};
  --core-ui-pagination-selected-text-color: #fff;
  --core-ui-pagination-selected-border-color: #{darken($teal-color, 5%)};
  --core-ui-pagination-selected-hover-border-color: #{darken($teal-color, 5.5%)};

  /** FilterSummaryHeading */
  --core-ui-filter-summary-heading-meta-text-color: #{lighten(
      $input-text-color,
      30%
    )};
  --core-ui-filter-summary-heading-meta-number-color: #{$teal-color};

  /** FilterSummary */
  --core-ui-filter-summary-show-per-page-text-color: #{lighten(
      $input-text-color,
      30%
    )};

  /*
    Tooltip
  */
  --tooltip-background: #273142;
}

code {
  // stylelint-disable-next-line declaration-no-important
  font-family: monospace !important;
}
