.audits {
  :global {
    .widget.data-table {
      min-width: 1000px;
    }

    tr:last-child td {
      border-bottom: 0;
    }
  }
}

.rightRow {
  text-align: right;
  padding-top: 20px;
}
