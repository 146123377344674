@import '../global-constants';
@import '../global-mixins';
@import './link.variables.scss';

// need to make these available for manual application to
// CMS provided markup (i.e. anchor tags without classes!)
@mixin medkit-link-styles {
  font: $font;
  line-height: $link-line-height;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: $link-color;
  cursor: pointer;
  text-decoration: underline;
  position: relative;

  &:disabled {
    text-decoration: none;
    opacity: 0.6;
    cursor: not-allowed;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:focus {
    @include medkitFocusOutline;
  }
}

.Link {
  @include medkit-link-styles;

  &Standalone {
    box-sizing: border-box;
    display: table;
    margin-left: -#{$link-spacing};
    margin-right: -#{$link-spacing};
    min-height: rem-calc(48);
    padding: $link-spacing;
    text-align: left;
    white-space: pre-line;

    &.LinkActive {
      color: $link-color-active;
      background-color: $link-color;
      text-decoration: underline;
    }
  }
}
