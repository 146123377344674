@import './../../styles/variables';

$menu-bg-color: #fff;
$primary-color: #2bbbbb;
$color-text-grey-light: #8896a2;
$color-text-grey: #454d54;
$profile-focus-bg-color: #f5f9fe;

.topBarButton {
  &__icon {
    vertical-align: middle;
    margin-right: 8px;
    color: $babylon-violet;

    &--patientSearch {
      position: relative;
      bottom: 4px;
    }

    &--openMenuToggle {
      position: relative;
      bottom: 2px;
    }

    &--closeMenuToggle {
      position: relative;
      bottom: 2px;
    }
  }

  &__link {
    padding: 8px 12px 6px 12px;
    border-radius: 4px;
    font-size: 16px;
    color: $color-text-grey;
    display: inline-block;
    vertical-align: middle;
    user-select: none;
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__link:hover {
    background-color: $active-grey;
    color: $color-text-grey;
  }

  &__link:active {
    background-color: $click-down-grey;
  }

  &--active {
    background-color: $active-grey;

    &:hover {
      background-color: $click-down-grey;
    }
  }
}

.profile {
  &__menu {
    background-color: $menu-bg-color;
    box-shadow: 0 2px 8px 0 rgba(40, 40, 36, 0.3);
    border-radius: 4px;
    display: none;
    margin-top: 4px;
    position: absolute;
    top: 70px;
    right: 0;
    min-width: 166px;

    &_icon {
      display: flex;
      justify-content: flex-end;

      &_svg {
        fill: $color-text-grey;
      }

      &:hover {
        fill: $color-text-grey-light;
      }
    }

    &_submenu {
      position: relative;

      &:hover {
        .submenu {
          @extend .profile__menu--expanded;
        }
      }
    }

    &_item {
      border: 0;
      border-radius: 4px;
      color: var(--grey-100);
      cursor: pointer;
      display: block;
      font-size: 16px;
      line-height: 1.4;
      text-align: left;
      text-decoration: none;
      padding: 4px 8px;
      width: 100%;

      &:hover,
      &:focus {
        background-color: $profile-focus-bg-color;
        color: var(--grey-100);
      }

      a,
      :hover,
      :focus {
        color: var(--grey-100);
      }
    }

    &--expanded {
      display: block;
    }
  }
}

.language {
  &__menu {
    &_item {
      @extend .profile__menu_item;

      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        margin-left: auto;
      }
    }
  }
}

.submenu {
  @extend .profile__menu;
  position: static;

  &_container {
    position: absolute;
    top: -8px;
    right: 100%;
    padding-right: 8px;
  }
}
