.Message {
  display: inline-flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 24px;
  min-width: 35%;
  max-width: 60%;

  &--right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 24px;
  }

  &__name {
    color: var(--grey-900);
    flex: 0.7;
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;

    &--user {
      text-decoration: underline;
      color: var(--petroleum-teal);
      font-weight: 400;
    }
  }

  &__date {
    color: var(--grey-600);
    margin: 54px 0;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
  }

  &__time {
    color: var(--grey-600);
    margin: 8px 0;
    font-size: 12px;
    line-height: 20px;
  }

  &__body {
    white-space: pre-wrap;
  }

  &__choices {
    list-style: none;

    & > li {
      display: flex;
      flex-direction: row;

      &::before {
        content: '';
        border: 1.5px solid var(--babylon-violet);
        border-radius: 50%;
        display: inline-block;
        flex-shrink: 0;
        height: 16px;
        margin-top: 4px;
        margin-right: 8px;
        width: 16px;
      }
    }
  }

  &__bubble {
    background: --white;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.15);
    border-radius: 8px 24px 24px 24px;
    padding: 24px;
    margin-bottom: 8px;
  }

  &__bubble--right {
    min-width: 35%;
    max-width: 60%;
    border-radius: 24px 8px 24px 24px;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.15);
    background: var(--active-grey);
    padding: 24px;
    margin-bottom: 8px;
  }
}

.ConnectToAgent {
  color: var(--grey-600);
  text-align: center;
  margin: 24px 0;

  &__agentName {
    font-weight: 600;
    color: var(--grey-900);
  }

  &__time {
    font-weight: 500;
    margin-bottom: 8px;
  }
}
