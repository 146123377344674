.ModalContent {
  width: 464px;

  & > p {
    width: 368px;
  }

  & > .ModalContent__actions {
    margin-top: 40px;
    display: flex;
    flex-direction: row;

    & > button {
      width: 100%;
    }
  }

  &__closeButton {
    align-self: flex-end;
    background-color: var(--active-grey);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    height: 40px;
    padding: 0;
    width: 40px;
  }
}

.UnsubscribeButton {
  display: flex;
  justify-content: flex-end;
}
