@import '../global-constants';

$stroke-color: var(--medkit-spinner-stroke-color, white);
$stroke-color-primary: var(
  --medkit-spinner-stroke-color-primary,
  var(--medkit-primary, $primary)
);

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.Spinner {
  transform-origin: center;
  animation: loader 0.85s linear infinite;
  stroke: $stroke-color;

  &_primary {
    stroke: $stroke-color-primary;
  }
}

.center {
  display: flex;
  align-self: center;
  margin: auto;
}
