.core-badge {
  position: relative;
  vertical-align: top;

  background-color: var(--primary-color);
  color: #fff;

  font-size: 9px;
  font-weight: 900;
  padding: 0 4px;
  border-radius: 100px;

  cursor: default;
}
