.core-ui-formik-checkbox {
  & ~ & {
    margin-top: 8px;
  }

  &__errorMessage {
    display: block;
    font-size: 14px;
    line-height: 18px;
    margin-top: 5px;
    color: var(--error-color);
  }
}
