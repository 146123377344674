.ModalContainer {
  width: calc(100% - 62px);
  height: calc(100% - 62px);
  overflow: hidden;
  position: relative;

  &__overlay {
    z-index: var(--zindex-overlay);
  }

  :global {
    .core-ui-modal__content {
      height: calc(100% - 32px);
      margin: 0;
    }
  }

  &__iframe {
    width: 100%;
    height: 100%;
  }

  &__title {
    padding-bottom: 24px;
    color: var(--grey-900);
  }

  &__cta {
    background-color: var(--dark-purple);
  }

  &__copyButton {
    position: absolute;
    right: 26px;
    top: 64px;
  }
}
