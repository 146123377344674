@import './../../styles/mixins.scss';

.core-ui-grid {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: minmax(8px, auto);
  grid-gap: 8px;
  height: auto;

  @for $i from 1 through $desktop-grid-columns {
    &--template-columns-#{$i} {
      grid-template-columns: repeat($i, 1fr);
    }
  }

  &--responsive {
    //mobile
    grid-gap: 16px;
    grid-template-columns: repeat($mobile-grid-columns, 1fr);
    //tablet
    @media (min-width: $tablet-min-width) {
      grid-gap: 16px;
      grid-template-columns: repeat($tablet-grid-columns, 1fr);
    }
    //desktop
    @media (min-width: $desktop-min-width) {
      grid-gap: 24px;
      grid-template-columns: repeat($desktop-grid-columns, 1fr);
    }
  }

  &--margin {
    //mobile
    margin: 0 16px;
    //tablet
    @media (min-width: $tablet-min-width) {
      margin: 0 24px;
    }
    //desktop
    @media (min-width: $desktop-min-width) {
      margin: 0 48px;
    }
    //ultra-hd
    @media (min-width: 1920px) {
      margin: 0 auto;
      max-width: $max-layout-width - 96px;
    }
  }
}
