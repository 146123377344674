.core-alert {
  display: flex;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  font-size: 14px;
  background-color: var(--grey-10);
  color: var(--input-text-color);
  transition: all 0.2s ease 0s;

  &__prefix {
    display: flex;
    align-items: center;
    background-color: var(--grey-75);
    color: var(--grey-10);
    padding: 8px;
  }

  &__content {
    padding: 12px;
    flex: 1;
  }

  &__title {
    font-weight: 600;
    padding-bottom: 8px;
  }

  &__message {
    font-size: 1em;
    font-weight: 400;
  }

  &--interactive {
    cursor: pointer;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.12) 0 2px 5px 0;
    }
  }

  &--primary &__prefix {
    background-color: var(--primary-color);
  }

  &--success &__prefix {
    background-color: var(--success-color);
  }

  &--warning &__prefix {
    background-color: var(--warning-color);
  }

  &--error &__prefix {
    background-color: var(--error-color);
  }
}
