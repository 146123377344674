@import '../constants.scss';

.alert {
  padding: 16px;
  font-size: 17px;
  line-height: 1.29;
  font-family: Visuelt, inherit;
  display: flex;
  align-items: center;

  // Variants
  // These correspond to the `variant` prop in the component
  &--rounded {
    border-radius: $border-radius;
  }

  a,
  button {
    transition: color $transition-time $transition-ease;
    text-decoration: underline;
  }

  button {
    background: none;
    border: 0;
    cursor: pointer;
    font-size: 1em;
    padding: 0;
  }
}

.alert + .alert {
  margin-top: 16px;
}

.icon {
  flex-shrink: 0;
  fill: inherit;
}

.icon + .message {
  margin-left: 16px;
}

.message {
  white-space: pre-wrap;
}

// These correspond to the `type` prop. If you add one here, add it to the types
// hash inside the component module

.error {
  background: $red-d10;
  color: $white100;
  fill: $white100;

  a,
  button {
    color: $white100;
  }
}

.info {
  background: #eff3f7;
  color: $grey75;
  fill: $grey75;

  a,
  button {
    color: $grey75;

    &:hover,
    &:focus {
      color: $grey100;
    }
  }
}

.warn {
  background: $yellow10;
  color: #353a46;
  fill: $grey75;

  a,
  button {
    color: #353a46;

    &:hover,
    &:focus {
      color: $grey100;
    }
  }
}

// has priority over variant backgrounds above
.alert--busy {
  @include shimmerAnimation;
  min-height: 3.375rem;
}
