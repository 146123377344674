@import '../constants.scss';

.checkbox {
  display: block;
  width: 100%;
  cursor: pointer;
  padding-left: 15px;
  position: relative;

  @include font();

  + .checkbox {
    margin-top: 16px;
  }

  &__label {
    cursor: pointer;
    display: block;
    padding-left: 8px;

    &::before {
      content: '';
      display: block;
      border-width: 1px;
      border-style: solid;
      height: 16px;
      width: 16px;
      border-radius: 2px;
      position: absolute;
      left: 0;
      top: 2px;
      transition-property: border-color, background-color;
      transition-duration: 0.15s;
      transition-timing-function: linear;
    }

    &__content {
      display: block;
    }
  }

  &__icon {
    display: block;
    fill: $white100;
    height: 16px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 2px;
    transform: rotate(45deg) scale(0.1);
    transition-duration: 0.15s;
    transition-property: transform, opacity;
    transition-timing-function: linear;
    width: 16px;
  }

  &__input {
    position: absolute;
    opacity: 0;

    &:checked + .checkbox__label {
      &::before,
      &:hover::before {
        border-color: $primary;
        background-color: $primary;
      }

      .checkbox__icon {
        opacity: 1;
        transform: rotate(0deg) scale(1);
      }
    }

    &:focus + .checkbox__label::before {
      border-color: $primary;
    }

    + .checkbox__label {
      &:hover::before {
        border-color: $primary;
        background-color: $primary-light;
      }

      &::before {
        border-color: $unfocus-border;
        background-color: transparent;
      }
    }

    &:disabled + .checkbox__label::before {
      border-color: $unfocus-border;
    }

    &:disabled:checked + .checkbox__label::before {
      background-color: $unfocus-border;
    }
  }
}
