.Referrals {
  &__heading {
    margin-top: 48px;
    margin-bottom: 48px;
    font-size: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h1 {
      font-size: 30px;
      line-height: 40px;
      font-weight: normal;
    }
  }

  &__closeButton {
    border: none;
    background-color: var(--active-grey);
    align-self: flex-end;
    cursor: pointer;
    padding: 0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__sendButton,
  &__sendButton:hover {
    border: none;
    background-color: var(--transparent);
    color: var(--babylon-violet);
    padding: 0;
    cursor: pointer;
    font-size: 14px;
  }

  &__referralHeader {
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 28px;
    font-weight: normal;
  }

  &__referral {
    background-color: var(--active-grey);
    border-radius: 8px;
    padding: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  &__link {
    color: var(--petroleum-teal);
    font-size: 16px;

    svg {
      margin-right: 8px;
      fill: var(--petroleum-teal);
    }

    &:hover {
      svg {
        fill: var(--anchor-color);
      }
    }
  }
}
