.appLayout {
  &__main {
    display: flex;
    flex-grow: 1;
  }

  &__nav {
    flex-shrink: 0;

    &--entered-width,
    &--entering-width {
      width: 250px;
      transition: width 300ms ease-in;
    }

    &--exiting-width,
    &--exited-width {
      width: 0;
      transition: width 300ms ease-in;
    }

    &--entered-opacity,
    &--entering-opacity {
      opacity: 1;
      transition: opacity 100ms 200ms ease-in;
    }

    &--exiting-opacity,
    &--exited-opacity {
      opacity: 0;
      transition: opacity 0ms 300ms ease-in;
    }

    &--exited-opacity {
      visibility: hidden;
    }
  }

  &__content {
    flex-grow: 2;
    padding-top: 28px;
    min-width: 0;
    background-color: var(--white);
    z-index: var(--zindex-content);
    // Fixes an issue where if the context overflows, the entire page scrolls horizontally (including the
    // nav & header) instead of just the content
    overflow-x: scroll;
  }

  &__wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  &__inner {
    padding: 0 24px;
  }
}
