.headingV2 {
  margin-bottom: 0.5rem;
}

.bodyV2 {
  margin-bottom: 0.5rem;
}

.questionContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;

  button {
    text-align: start;
    max-width: 100%;
    word-break: break-all;
  }
}
