@import '../../components/constants.scss';

.container {
  font-family: $font-family;
}

.main {
  align-items: center;
  background: transparent;
  border: 0;
  display: flex;
  flex-direction: row-reverse;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-decoration: none;
  width: 100%;

  &__aside {
    height: 3.125rem;
    margin-right: 1rem;
    width: 3.125rem;

    &--icon {
      background-color: $grey75;
      border-radius: 50%;
      position: relative;

      svg {
        fill: #fff;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }

  &__content {
    flex: 1 0;
    max-width: 100%;
  }

  &__decoration {
    fill: $grey75;
    margin-left: 1rem;
  }
}

.footer {
  padding: 0;

  .detail,
  .features {
    position: relative;
    z-index: 1; // sometimes this footer is pulled up over .main
  }
}

.title,
.detail {
  display: block;
  font-style: normal;
  margin: 0;
  padding: 0;
  text-align: left;
}

.title {
  color: $grey100;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
  margin-bottom: 0.125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &--sub {
    font-size: 0.875rem;
  }
}

.subtitle {
  font-size: 0.875rem;
}

.detail,
.features {
  color: $grey75;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.125rem;

  &--strong {
    color: $grey100;
    font-weight: 500;
    margin-top: 0.5rem;
  }
}

.secondaryAction {
  a {
    // colour used is the lightest shade of primary
    // that has an acceptable WCAG contrast ratio on a white background
    color: $primary-d10;
    display: inline-block;
    font-weight: 400;
    text-decoration: none;
    transition: color $transition-time $transition-ease;
    vertical-align: top;

    &:hover,
    &:focus {
      color: $primary;
    }
  }
}

.cta {
  margin-top: 1rem;
}

.features {
  border-top: 1px solid rgba($grey50, 0.3);
  margin: 1rem 0 0;
  padding: 1rem 0 0;

  &__inner {
    display: flex;
    flex-wrap: wrap;
    margin: 0.5rem 0 0;
    padding: 0;
    max-width: 20rem;
  }

  &__label {
    flex: 1 0 50%;
    margin: 0;
    padding: 0;
    width: 20%;
  }

  &__detail {
    flex: 0 1 50%;
    margin: 0;
    padding: 0 0 0 1rem;
    white-space: pre;
  }

  & ~ .cta {
    margin-top: 1.25rem;
  }
}

.container--busy {
  @include shimmerAnimation;
  min-height: 3.75rem;

  .main,
  .footer {
    display: none;
  }
}

.container--busy.container--padded {
  min-height: 5.75rem;
}

.container--framed {
  border: 1px solid $grey25;
  border-radius: 0.5rem;
}

.container--padded {
  .main {
    padding: 1rem;
  }

  .footer {
    margin-top: -1rem;
    padding: 1rem;

    .detail--strong:first-child {
      margin-top: -0.5rem;
    }
  }
}

.container--hasSecondaryAction {
  .main {
    padding-bottom: 1rem;
  }

  .footer {
    margin-top: -1rem;
  }
}

.container--hasSecondaryAction.container--padded {
  .main {
    padding-bottom: 2.125rem;
  }

  .footer {
    margin-top: -2.125rem;
    padding-top: 0;
  }
}

.container--hasFooter.container--padded {
  .main .detail--strong {
    margin-bottom: -1rem;
  }
}

.container--selectable {
  .main {
    cursor: pointer;
    transition: background-color $transition-time $transition-ease;

    &:hover,
    &:focus {
      background-color: $grey10;
    }
  }
}
