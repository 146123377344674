.core-button-switch__container {
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.core-button-switch {
  --button-switch-grey: var(--grey-75);
  border: solid 1px var(--button-switch-grey);
  border-radius: 4px;
  display: flex;
  height: 36px;
  overflow: hidden;

  &__button {
    border-right: 1px solid var(--button-switch-grey);
    text-align: center;
    padding: 8px;
    font-size: 14px;
    overflow: hidden;
    height: 100%;
    flex: 1;

    cursor: pointer;

    &:hover {
      background-color: #fafafa;
    }
  }

  &__button:last-child {
    border-right: 0;
  }

  &--selected {
    background-color: var(--button-switch-grey);
    color: white;
    font-weight: 900;
    cursor: default;

    &:hover {
      background-color: var(--button-switch-grey);
    }
  }

  &--disabled {
    pointer-events: none;
    color: #333844;
    opacity: 0.35;
  }
}
