/* Date Picker Styles */
.core-datepicker {
  --schedule-rgb: 225, 229, 232;
  --schedule: #{'rgb(var(--schedule-rgb))'};
  --schedule-hover: #{'rgba(var(--schedule-rgb), 0.7)'};
  --hover-color: #e5effb;
  --dark-grey-rgb: 51, 63, 82;
  --dark-grey: #{'rgb(var(--dark-grey-rgb))'};
  --dark-grey-hover: #{'rgba(var(--dark-grey-rgb), 0.9)'};
  --range-color: #637280;
  --heading-color: #282824;
  --text-color: #637280;
  --border-color: var(--button-secondary-border-color);
  --white: #fff;
  --error: #ff3b2f;
  --error-background: #ffebea;

  font-family: 'Avenir', sans-serif;

  &.react-datepicker {
    border-radius: 4px;
    color: var(--text-color);
    border: solid 1px var(--border-color);
    display: inline-flex;
  }

  &--error.react-datepicker {
    border: 1px solid var(--error);
    box-shadow: 0 0 0 8px var(--error-background);
    margin-bottom: 8px;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__header {
    background: var(--white);
    border-bottom: 1px solid var(--border-color);
    padding: 12px 12px 0;
  }

  .react-datepicker__current-month {
    color: var(--heading-color);
    font-size: 16px;
    line-height: 28px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
  }

  .react-datepicker__triangle {
    //stylelint-disable-next-line declaration-no-important
    border-bottom-color: var(--white) !important;
  }

  .react-datepicker__month {
    margin: 0;
    padding: 12px;
  }

  .react-datepicker__navigation--next,
  .react-datepicker__navigation--previous {
    border: 1px solid var(--border-color);
    height: 28px;
    width: 38px;
    top: 12px;
    border-radius: 2px;
    background-image: url('../../../icons/right-arrow.svg');
    background-position: center;
    background-repeat: no-repeat;
    outline: none;

    &:active {
      transform: translateY(1px);
    }
  }

  .react-datepicker__navigation--next {
    right: 12px;
  }

  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 127px;
  }

  .react-datepicker__navigation--previous {
    left: 12px;
    transform: scaleX(-1);

    &:active {
      transform: scaleX(-1) translateY(1px);
    }
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    width: 36px;
    color: var(--text-color);
    margin: 0;
  }

  .react-datepicker__day-name {
    font-size: 14px;
    line-height: 40px;
  }

  //Day Styles
  .react-datepicker__day {
    font-size: 16px;
    line-height: 36px;
    border-radius: 0;
    margin: 0;
    color: var(--text-color);

    &--keyboard-selected {
      // TODO review this with design
      // position: relative;
      // z-index: 2;
      // box-shadow: 0px 0px 4px #4195fc;
      background: inherit;
    }

    &:hover {
      background-color: var(--hover-color);
      border-radius: 0;
    }

    &--in-range {
      background-color: var(--range-color);
      color: var(--white);
    }

    &--range-start,
    &--range-end,
    &--selected,
    &--selecting-range-start,
    &--selecting-range-end {
      background-color: var(--dark-grey);
      color: var(--white);

      &:hover {
        background-color: var(--dark-grey-hover);
      }
    }

    &--in-selecting-range:not(.react-datepicker__day--in-range) {
      background-color: var(--hover-color);
      color: var(--text-color);
    }

    &--disabled {
      opacity: 0.5;
    }

    &--today {
      font-weight: normal;
      position: relative;

      &::after {
        content: '';
        display: block;
        background: currentColor;
        height: 4px;
        width: 4px;
        position: absolute;
        bottom: 4px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50%;
      }
    }

    &--outside-month {
      opacity: 0.3;
    }
  }

  .react-datepicker__month--selecting-range
    .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
    color: var(--text-color);
  }

  //Time Styles
  .react-datepicker__time-container {
    width: 115px;
    border-left: 1px solid var(--border-color);
    border-radius: 0 4px 4px 0;
    overflow: hidden;

    .react-datepicker__header {
      padding-top: 16px;
      padding-bottom: 12px;
      height: 81px;
    }

    .react-datepicker-time__header {
      font-size: 16px;
    }
    //Disabling the following rules for this section as we need to match or better the specificity of the lib's css
    //A better approach may be to use `!important`
    /* stylelint-disable selector-max-compound-selectors, selector-no-qualifying-type, selector-max-type */
    .react-datepicker__time .react-datepicker__time-box {
      width: 114px;
    }

    .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item {
      height: 36px;
      color: var(--text-color);
      font-size: 16px;
      line-height: 28px;

      &:hover {
        background-color: var(--hover-color);
      }
    }

    .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item--disabled {
      opacity: 0.5;
    }

    .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item--selected {
      background-color: var(--dark-grey);
      color: var(--white);
      font-weight: 400;

      &:hover {
        background-color: var(--dark-grey-hover);
      }
    }
    /* stylelint-enable selector-max-compound-selectors, selector-no-qualifying-type */
  }

  &__container {
    position: relative;
  }

  &__icon {
    position: absolute;
    left: 7px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;

    > * {
      color: var(--light-grey-color);
      fill: var(--light-grey-color);
    }
  }
}

.core-datepicker__input {
  appearance: none;
  background-color: transparent;
  padding: 7px 7px 6px 7px;
  font-size: 14px;
  line-height: 17px;
  outline: none;
  color: var(--input-color);
  width: 100%;
  border: 1px solid var(--input-border-color);
  border-radius: 3px;

  &:focus {
    box-shadow: 0 0 0 2px var(--input-active-outline-color);
    border: 1px solid var(--input-active-border-color);
  }

  ::placeholder {
    font-weight: 400;
    color: var(--input-placeholder-color);
  }

  &--error {
    border-color: var(--error-color);

    &:hover,
    &:focus {
      border-color: var(--error-color);
    }
  }

  &--icon {
    padding-left: 28px;
  }
}
