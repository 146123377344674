.noWrap {
  white-space: nowrap;

  &:first-child hr {
    display: none;
  }
}

.wrap {
  white-space: normal;
}

.wrapSmall {
  white-space: normal;
  font-size: 14px;
  font-style: italic;
}

.badge {
  background-color: var(--grey-600);
  font-size: 10pt;
  font-weight: bold;
  color: var(--white);
  display: inline-block;
  padding: 0 5px;
  line-height: 20px;
  margin: 3px 5px 0 0;
  height: 19px;
  vertical-align: top;
  cursor: default;
  white-space: nowrap;

  /* prettier-ignore */

  &.NOT_CHOSEN { background-color: var(--warning-tag-border); }

  &.DISPENSED {
    background-color: var(--neutral-grey);
  }

  &.PRESCRIPTION_REQUESTED {
    background-color: var(--neutral-grey);
  }

  &.DELIVERY_PENDING {
    background-color: var(--neutral-grey);
  }

  &.APPROVAL_PENDING {
    background-color: var(--warning-tag-border);
  }

  &.REJECTED {
    background-color: var(--dark-grey);
  }

  &.APPROVED {
    background-color: var(--neutral-grey);
  }

  &.PATIENT_DECISION_PENDING {
    background-color: var(--neutral-grey);
  }

  &.PRESCRIPTION_CREATED {
    background-color: var(--neutral-grey);
  }

  &.PHARMACY_SELECTED {
    background-color: var(--neutral-grey);
  }

  &.PHARMACY_SUPPLIER_SELECTED {
    background-color: var(--neutral-grey);
  }

  &.FAX_FAILED_WILL_RETRY {
    background-color: var(--warning-tag-border);
  }

  &.FAX_PERMANENTLY_FAILED {
    background-color: var(--warning-tag-border);
  }

  &.FAX_NUMBER_MISSING {
    background-color: var(--warning-tag-border);
  }

  &.FAX_PHARMACY_BLOCKED {
    background-color: var(--warning-tag-border);
  }

  &.FAX_SENDING {
    background-color: var(--neutral-grey);
  }

  &.FAXED {
    background-color: var(--teal-color);
  }

  &.E_PRESCRIPTION_CODE_SENT {
    background-color: var(--teal-color);
  }

  &.CONFIRMED_RECEIVED {
    background-color: var(--teal-color);
  }

  &.VOIDED {
    background-color: var(--dark-grey);
  }

  &.PRINTED {
    background-color: var(--dark-grey);
  }

  &.EXPIRED {
    background-color: var(--dark-grey);
  }

  &.PRESCRIPTION_NOT_READY {
    background-color: var(--neutral-grey);
  }

  &.NOT_REQUIRED {
    background-color: var(--teal-color);
  }

  &.SIGNATURE_PENDING {
    background-color: var(--warning-tag-border);
  }

  &.DIGITALLY_SIGNED {
    background-color: var(--teal-color);
  }

  &.ELECTRONICALLY_HAND_SIGNED {
    background-color: var(--teal-color);
  }

  &.FAILED_TO_SEND {
    background-color: var(--warning-tag-border);
  }
}
