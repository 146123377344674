$textarea-height: var(--medkit-textarea-height, 10rem);
$textareaTextColor: var(--medkit-textarea-base-color, none);

.Textarea {
  &__textarea {
    height: $textarea-height;
    padding-top: 0.5rem;

    // For partners ex: lime
    @if $textareaTextColor != none {
      color: $textareaTextColor;
    }

    &_withLabel {
      padding-top: 1.5rem;
    }
  }
}
