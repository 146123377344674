.serviceErrors {
  margin-bottom: 12px;

  &__services {
    margin: 0 4px;
    padding: 0;
  }

  &__service {
    font-weight: 600;
    list-style: none;

    &::before {
      content: '- ';
    }
  }
}
