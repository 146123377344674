.Link {
  background: none;
  color: var(--primary-color);
  border: none;
  border-radius: 0px;
  padding: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    background: none;
    color: var(--button-primary-hover-background);
  }
}
