@import './../../../styles/mixins.scss';

.core-responsive-table {
  $crt: &;
  display: grid;

  // TODO: add test for this class
  &--full-width {
    min-width: 100%;
  }

  &--striped {
    #{$crt}__data-row:nth-child(odd) > * {
      background-color: var(--table-striped-row-background-color);
    }
  }

  &--row-dividers {
    #{$crt}__data-row > * {
      border-top: 1px solid var(--table-row-divider-color);
    }
  }

  &--scroll {
    overflow: auto;
    // add padding for scrollbar
    margin-bottom: 15px;
  }

  &--border {
    border: 1px solid var(--table-row-divider-color);

    .core-responsive-table__rowgroup {
      .core-responsive-table__row {
        &:first-child {
          .core-responsive-table__cell {
            border-top: 0;
          }
        }
      }
    }
  }

  &--no-top-border {
    border-top: 0;
  }

  &--collapsed {
    #{$crt}__data-row > * {
      border-top: none;
    }

    #{$crt}__data-row:not(:first-child) > :first-child {
      border-top: 1px solid var(--table-row-divider-color);
    }
  }

  &--cell-headers {
    #{$crt}__cell__header {
      display: block;
    }
  }

  &__container {
    width: auto;
  }

  &__rowgroup {
    display: contents;
  }

  &__row {
    display: contents;
    background-color: var(--table-row-background-color);

    &--clickable {
      cursor: pointer;
    }
  }

  &__cell {
    overflow: hidden;
    word-wrap: break-word;
    display: flex;
    align-items: center;

    &__header {
      font-weight: 600;
      display: block;
    }
  }

  &__header {
    font-weight: 600;
    border-bottom: 1px solid var(--table-header-border-color);

    #{$crt}__row > * {
      background-color: var(--table-header-background-color);
    }
  }
}
