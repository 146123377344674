.buttonRow {
  display: flex;
  justify-content: flex-end;

  :global {
    .widget-wrapper {
      margin-bottom: 0;
    }
  }

  button {
    margin-left: 10px;
    min-width: 160px;
  }
}

.alert {
  :global {
    textarea.widget.border {
      border: 1px solid rgba(255, 0, 0, 0.7);
    }
  }
}

.commentDialog p {
  margin-top: 0;
}

.prescriptionsView {
  padding: 0 30px 30px 30px;

  :global {
    .tab i {
      position: absolute;
      margin-left: 5px;
      position: relative;
      top: 2px;
    }
  }

  th {
    white-space: nowrap;
  }

  tr.removing * {
    color: #ddd !important;
    pointer-events: none;
  }

  .listHeader {
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .filterToolbar {
    display: flex;
    flex-direction: row;

    > * {
      margin-left: 10px;
    }
  }

  .innerRow {
    border: 0;
  }

  .narrowRow {
    width: 1%;
  }

  hr {
    border: 0;
    border-top: 1px solid #dfe7eb;
    height: 0;
    margin: 5px 0;
  }

  .bottomToolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0;

    p {
      margin: 0 0 6px 0;
      font-weight: 300;
    }

    .download {
      text-align: left;
      display: flex;
      flex-direction: row;
      width: 400px;

      button {
        margin-left: 10px;
      }

      :global {
        .widget.autocomplete {
          width: 200px;
          padding: 0 !important;
        }
      }
    }
  }

  .rowRight {
    text-align: right;

    > div {
      display: inline-block;
      margin-left: 10px;
    }
  }
}

.addressWrap {
  white-space: pre-wrap;
}
