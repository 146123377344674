@import '@babylon/design-tokens/all.scss';

.buttons {
  background-color: $white100;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1.5rem 0 2.5rem;
  position: sticky;

  & > * {
    margin: 0;
    max-width: calc(50% - 0.5rem);
  }
}
