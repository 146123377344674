.core-table {
  border-collapse: collapse;

  &__head {
    color: var(--table-header-color);
    border-bottom: 1px solid var(--table-header-border-color);
  }

  &__cell {
    padding: 8px 12px;
    text-align: left;
  }

  &--full-width {
    width: 100%;
  }

  &--striped &__body &__row:nth-child(even) {
    background-color: var(--table-striped-row-background-color);
  }
}
