.TableHeading {
  border-bottom: 1px solid var(--grey-110);
  color: var(--neutral-grey);
  font-size: 14px;
  margin-bottom: 24px;
  padding: 0 16px 8px;
}

.RedeemedCodeEntries {
  &__Heading {
    color: var(--label-color);
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 24px;
  }

  &__Entry {
    font-size: 14px;
    padding: 16px;

    &:nth-child(even) {
      background: var(--active-grey);
    }
  }

  &__action {
    min-width: 100%;
  }
}

.StatusTag {
  border-radius: 4px;
  border-style: solid;
  border-width: 1.5px;
  color: var(--grey-900);
  text-transform: uppercase;

  &__Inactive {
    border-color: var(--warning-tag-border);
  }

  &__Active {
    border-color: var(--border-success-green);
  }
}

.StackedCell {
  display: flex;
  flex-direction: column;

  & span {
    display: block;
  }
}
