.InfiniteScroller {
  &__spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }

  &__error {
    padding: 0 20px;
  }
}
