.ClinicianName {
  font-size: 16px;
  line-height: 24px;
  color: var(--grey-900);

  &__name {
    text-decoration: underline;
    text-decoration-skip-ink: none;
    color: var(--petroleum-teal);
  }
}
