@import '@babylon/design-tokens/all.scss';

.container {
  margin: 1rem 0;
}

.title {
  padding: 1rem 0;
}

.scores {
  background-color: var(--active-grey);
  border-radius: 0.25rem;
}

.list {
  margin-top: 1rem;
}

.scoreName {
  color: var(--neutral-grey);
}

.scoreInformation {
  margin: 0.25rem 0 0;
}
