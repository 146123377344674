.core-heading {
  margin-top: 0;
  font-weight: 500;

  &--no-margin {
    margin-bottom: 0;
  }

  &--align {
    &-left {
      text-align: left;
    }

    &-center {
      text-align: center;
    }

    &-right {
      text-align: right;
    }
  }
}

h1.core-heading {
  font-size: 24px;
  line-height: 32px;
}

h2.core-heading {
  font-size: 20px;
  line-height: 24px;
}

h3.core-heading {
  font-size: 16px;
  line-height: 20px;
}

h4.core-heading {
  font-size: 14px;
  line-height: 16px;
}

h5.core-heading {
  font-size: 12px;
  line-height: 16px;
}
