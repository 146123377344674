.Container {
  width: 100%;
  height: calc(100vh - 237px);
  overflow: hidden;
  position: relative;

  &__iframe {
    width: 100%;
    height: 100%;
  }
}
