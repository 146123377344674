@import '../../global-constants.scss';

$medkitErrorMessageTextColor: var(
  --medkit-appointment-reason-error-message-text-color,
  $red120
);

.error {
  &Messages {
    display: block;
    width: 100%;
  }

  &Message {
    align-items: flex-start;
    color: $medkitErrorMessageTextColor;
    display: flex;
    line-height: rem-calc(24);
    margin: 0 0 rem-calc(4);

    &::before {
      background-image: url('./Alert.svg');
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      display: inline-block;
      flex-shrink: 0;
      height: rem-calc(22);
      margin-right: rem-calc(9);
      margin-top: rem-calc(2);
      width: rem-calc(22);
    }
  }
}
