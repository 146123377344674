.timeline {
  &__block {
    margin-bottom: 64px;

    &:first-child {
      padding-top: 36px;
    }
  }

  &__heading {
    display: flex;
    margin-bottom: 12px;
  }

  &__content {
    min-height: 120px;
    position: relative;

    &--loading {
      opacity: 0.1;
    }

    &:empty {
      display: none;
    }
  }
}

.loadingBlock {
  height: 120px;
}
