@import 'styles/variables';

.Summary {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  background-color: var(--active-grey);
  padding: 8px;
  margin-bottom: 24px;

  &__column {
    flex: 1;
    justify-content: center;
    display: flex;
  }

  &__value {
    font-weight: 500;

    &--user {
      text-decoration: underline;
    }
  }
}
