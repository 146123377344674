.form {
  .widgetWrap {
    margin-bottom: 15px;
    position: relative;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  :global {
    h1 {
      margin-top: 0;
      font-size: 20px;
    }

    input[type='text']:disabled {
      color: #aaa;
    }

    .babylon-component-library__Checkboxgroup__checkbox__label:before {
      top: 3px;
    }

    .babylon-component-library__Checkboxgroup__checkbox__label:after {
      top: 6px;
    }

    /* TODO: fix it in the babylon component library */
    .babylon-component-library__Textarea__label {
      font-size: 14px;
      margin-bottom: 8px;
    }

    .babylon-component-library__Textarea__textarea {
      border: 1px solid #dfdfdf;
      padding-top: 8px;
      padding-bottom: 8px;

      &:focus {
        border-color: #319bf5;
      }
    }

    .alert {
      > * > label,
      > * > * > label {
        color: lighten(#f00, 10);
      }

      > * > * > input,
      > * > * > textarea,
      > * > * > .Select-control,
      > * > * > .widget-border {
        border-color: lighten(#f00, 20);
      }
    }
  }

  .checkbox {
    display: none;

    & + label {
      padding-left: 25px;
      font-size: 16px;
    }

    & + label:before {
      content: '';
      display: block;
      border-width: 1px;
      border-style: solid;
      height: 16px;
      width: 16px;
      border-radius: 2px;
      position: absolute;
      left: 0;
      top: 3px;
      border-color: #ccc;
      transition: border-color 0.15s linear, background-color 0.15s linear;
    }

    & + label:hover:before {
      border-color: #7e5ced;
    }

    & + label:after {
      content: '';
      opacity: 0;
      display: block;
      background: transparent;
      transform: rotate(45deg) scale(1);
      position: absolute;
      left: 5px;
      top: 6px;
      border-radius: 1px;
      width: 5px;
      height: 8px;
      border: 2px solid #fff;
      border-top: 0;
      border-left: 0;
      transition: opacity 0.15s linear;
    }

    &:checked + label:before {
      border-color: #7e5ced;
      background-color: #7e5ced;
    }

    &:checked + label:after {
      opacity: 1;
    }
  }
}

.buttonBar {
  text-align: right;

  button {
    margin-left: 10px;

    &:last-of-type {
      background: #fff;
    }
  }
}
