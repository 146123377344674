.PageErrorMessage {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 600px;
    max-width: 100%;
  }

  &__errorMessage {
    display: block;
    color: var(--error-color);
  }

  &__reloadButton {
    margin-top: 15px;
  }

  &__reloadButtonText {
    display: block;
  }
}
