.drugAlertForm {
  display: block;
  padding: 40px 40px 40px 30px;

  .buttonRow {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      min-width: 120px;
      margin-left: 10px;
    }
  }

  .shrink > div:last-child {
    padding-top: 37px;
    flex: none;
  }
}
