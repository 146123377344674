.snackbar {
  left: calc(50% + 100px);

  &__icon {
    display: flex;
    fill: var(--white);
  }
}

.searchResultItem {
  background: var(--white);
  line-height: 24px;
  transition: all 0.1s ease-out;
}

.searchResultItem--expanded {
  box-shadow: 0 10px 15px 3px #ebf2f9;
  position: relative;
  transform: scale(1.015);
}

.searchResultItem__family {
  display: none;
  margin-top: 20px;
}

.searchResultItem__family--expanded {
  display: block;
}

li.core-ui-filter-summary-heading__metaItem span:last-child {
  color: var(--color-text-grey);
}

.core-ui-filter-summary-heading__metaText {
  font-size: 24px;
}
