$stepper-bg-highlight-color: #2bbbbb;
$stepper-text-highlight-color: #637280;
$stepper-base-color: #c5d0de;
$stepper-icon-text-color: #fff;

.Stepper {
  display: flex;
  padding: 0;

  .Step {
    position: relative;
    flex: 1;
    padding: 0 0.5rem;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &__icon {
      fill: $stepper-base-color;
    }

    &__label {
      display: none;
      margin-top: 16px;
      color: $stepper-base-color;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.5;
      text-align: center;

      // TODO: use react-container-queries or similar to hide based on component width instead of screen width
      @media (min-width: 576px) {
        display: block;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__connector {
      position: absolute;
      top: 12px;
      right: calc(50% + 20px);
      left: calc(-50% + 20px);
      display: none;
      border-top: 1px solid $stepper-base-color;

      // TODO: use react-container-queries or similar to hide based on component width instead of screen width
      @media (min-width: 576px) {
        display: block;
      }
    }

    &__iconText {
      font-size: 0.75rem;
      fill: $stepper-icon-text-color;
    }

    &_highlight {
      .Step__icon {
        fill: $stepper-bg-highlight-color;
      }

      .Step__label {
        color: $stepper-text-highlight-color;
        font-weight: 500;
      }
    }
  }
}
