:root {
  --preferred-border-color: #97b4b5;
}

.Tag {
  border-radius: 4px;
  border-style: solid;
  border-width: 1.5px;
  display: inline-block;
  font-size: 12px;
  line-height: 1.3rem;
  overflow: hidden;
  padding: 0 4px;
  max-width: 125px;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
}

.TagType__default {
  background-color: var(--preferred-color);
  border-color: var(--preferred-border-color);
  color: var(--text);

  &.TagVariant__outline {
    background-color: var(--white);
    border-color: var(--preferred-border-color);
  }

  &.TagVariant__solid {
    background-color: var(--petroleum-teal);
    border-color: var(--petroleum-teal);
    color: var(--white);
  }
}

.TagType__success {
  background-color: var(--off-white);
  border-color: var(--success-color);
  color: var(--text);

  &.TagVariant__outline {
    background-color: var(--white);
    border-color: var(--success-color);
  }

  &.TagVariant__solid {
    background-color: var(--success-color);
    border-color: var(--success-color);
    color: var(--white);
  }
}

.TagType__warning {
  background-color: var(--off-white);
  border-color: var(--warning-color);
  color: var(--text);

  &.TagVariant__outline {
    background-color: var(--white);
    border-color: var(--warning-color);
  }

  &.TagVariant__solid {
    background-color: var(--warning-color);
    border-color: var(--warning-color);
    color: var(--white);
  }
}

.TagType__error {
  background-color: var(--off-white);
  border-color: var(--error-color);
  color: var(--text);

  &.TagVariant__outline {
    background-color: var(--white);
    border-color: var(--error-color);
  }

  &.TagVariant__solid {
    background-color: var(--error-color);
    border-color: var(--error-color);
    color: var(--white);
  }
}
