.moreDetails {
  border-top: 3px solid rgba(255, 255, 255, 0.8);
  color: var(--text-color);
  line-height: 24px;
  padding: 12px 32px 28px;

  &__block {
    margin-bottom: 24px;

    &:empty {
      display: none;
    }
  }
}

.snackbar {
  &__icon {
    display: flex;
    fill: var(--white);
  }
}
