.fillContent {
  width: 100%;
}

.pharmacistsDataGrid {
  width: 95%;
  height: 100%;
  margin: auto;
}

.pharmacistsTitle {
  padding: 1rem;
}
