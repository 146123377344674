/* stylelint-disable at-rule-no-unknown */
@value transitionDuration: 300ms;

.transitionWrapper {
  color: inherit;
}

.transitionWrapper-enter {
  opacity: 0;
}

.transitionWrapper-enter-active {
  opacity: 1;
  transition: opacity transitionDuration;
}

.transitionWrapper-exit {
  opacity: 1;
}

.transitionWrapper-exit-active {
  opacity: 0;
  transition: opacity transitionDuration;
}

.transitionWrapper-exit-done {
  display: none;
}
