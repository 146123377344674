@import 'styles/variables';

.Messages {
  padding: 8px;

  &__dateHeader {
    color: var(--babylon-violet);
    font-weight: 500;
  }

  &__sender {
    margin-top: 32px;
    font-weight: 500;
    color: var(--grey-900);

    &--user {
      text-decoration: underline;
    }
  }
}

.Message {
  display: flex;
  flex-direction: row;
  margin-top: 8px;

  &__time {
    color: var(--grey-600);
    margin-right: 24px;
  }

  &__text {
    color: var(--grey-800);
  }
}
