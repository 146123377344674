@import '../constants.scss';

.tabGroup {
  position: relative;
  @include font();

  .tabList {
    display: flex;
    flex-direction: row;
    position: relative;
    border-bottom: 2px solid $secondary-light;

    .tab {
      height: 52px;
      line-height: 52px;
      cursor: pointer;
      user-select: none;
      transition: opacity 400ms;
      color: $outline-dark;
      outline: none;

      &:focus {
        outline: none;
      }

      &:not(:last-child) {
        margin-right: 45px;
      }

      &:not(.active) {
        opacity: 0.8;
      }

      &.disabled {
        opacity: 0.3;
        pointer-events: none;
      }
    }
  }

  .pointer {
    height: 2px;
    width: 40px;
    border-radius: 1px;
    background: $outline-dark;
    transition: all 450ms;
  }

  .tabPanel {
    padding-top: 20px;
    position: relative;
  }
}
