.CopyIcon {
  text-align: right;
  margin-right: 10px;
}

.CopyButton {
  &__button {
    display: flex;
    align-items: center;
    cursor: pointer;

    > button {
      cursor: pointer;
      text-decoration: none;
      color: var(--petroleum-teal);
      background: none;
      border: none;
    }
  }
}
