@import '../../../styles/variables';

$menu-bg-color: #fff;

.core-ui-kebab {
  position: relative;
  width: 32px;

  &__button {
    svg {
      display: block;
    }

    &:active,
    &--active {
      border-color: var(--primary-color);
    }

    &:focus &,
    &:hover &,
    &--active & {
      &__icon {
        fill: var(--primary-color);
      }
    }
  }

  &__menu {
    background-color: $menu-bg-color;
    box-shadow: 0 2px 8px 0 rgba(40, 40, 36, 0.3);
    border-radius: 4px;
    display: none;
    margin-top: 4px;
    position: absolute;
    right: 0;
    top: 100%;
    width: 220px;
    z-index: 10;

    &-item {
      background-color: inherit;
      border: 0;
      border-radius: 4px;
      color: var(--grey-100);
      cursor: pointer;
      display: block;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.4;
      text-align: left;
      text-decoration: none;
      padding: 4px 8px;
      width: 100%;

      &:hover,
      &:focus {
        background-color: rgba($primary-color, 0.1);
        color: var(--grey-100);
      }

      &:disabled {
        color: var(--button-secondary-disabled-color);

        &:hover,
        &:focus {
          background-color: inherit;
          cursor: not-allowed;
        }
      }
    }

    &--opened {
      display: block;
    }
  }
}
