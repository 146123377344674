.CallList {
  &__noCalls {
    color: var(--error-color);
  }

  &__list {
    list-style: none;
    margin: 0;
    padding-left: 0;

    & > li {
      display: block;
    }
  }
}
