.RelatedPersonCard {
  &__wrapper {
    margin: 56px 0 12px 12px;
    position: relative;

    header div {
      display: flex;
    }
  }

  &__noContent {
    margin-bottom: 16px;
  }
}
