.primaryText {
  font-size: 10px;
  text-anchor: middle;
  font-kerning: none;
  transform: translateY(0.35em);

  &--withSecondary {
    transform: translateY(0.15em);
  }
}

.secondaryText {
  font-size: 4px;
  text-anchor: middle;
  transform: translateY(1.4em);
}
