@import '../constants.scss';

.switchContainer {
  cursor: pointer;
  width: 38px;
  min-width: 38px;
  height: 22px;
  background-color: $secondary-light;
  border-radius: 22px;
  transition: background-color 0.2s ease-in-out;

  &.checked {
    background-color: $primary;
  }
  &.disabled {
    cursor: not-allowed;
  }

  .switch {
    transform: translate3d(2px, 2px, 0);
    transition: transform 0.2s ease-in-out;
    background: white;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    left: 1px;
    top: 1px;

    &.checked {
      transform: translate3d(100%, 2px, 0);
    }
  }
}
