.phoneInputWrapper {
  display: flex;
  flex-direction: column;

  label:empty {
    display: none;
  }

  > div {
    flex: 1;
  }

  @media only screen and (max-width: 380px) {
    flex-wrap: wrap;

    > div {
      margin-right: 0;
      width: 100%;
    }

    button {
      width: 100%;
      margin-top: 10px;
    }
  }
}

.submitButton {
  margin: 1.5rem 0 0 0;
}

// TODO: remove styles and migrate to a phone input component
// in web-platform-ui once built
.v2 {
  select,
  input,
  .selectDisplay {
    font-family: var(--dna-fonts-body);
    border-color: var(--dna-colors-light-functional-graphic);
    color: var(--dna-text-body);

    &:focus {
      border-color: var(--dna-colors-light-brand-primary);

      + .selectDisplay {
        border-color: var(--dna-colors-light-brand-primary);
      }
    }
  }
}
