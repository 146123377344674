@import '../../util/constants.scss';

.optionList {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  & > div {
    width: 100%;

    &:first-child {
      button {
        margin-top: 0.5rem;
      }
    }
  }

  button {
    margin: 1rem 0 0;
    white-space: break-spaces;
  }

  @include fromTablet {
    & > div {
      width: auto;
    }

    button {
      width: auto;
      margin: 0.5rem 1rem 0 0;
    }
  }
}
