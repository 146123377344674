.disclaimer {
  border-radius: 1rem;
  padding: 1rem 1.25rem;
  margin-bottom: 2rem;
}

.possibleCauses {
  margin-bottom: 2rem;
}

.softOutcome {
  margin-top: 0.75rem;
}

.softOutcomePara {
  margin: 0.75rem 0;
}

.triageDisclaimer {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}
