.pagination {
  button {
    margin: 0;
    padding: 0;
    width: 50px;
    height: 27px;
    line-height: 26px;
    background: #fff;
    border: 1px solid #dfe7eb;
    border-right: 0;
    color: #45576e;
    font-size: 14px;
    font-weight: 200;
    cursor: pointer;

    &:first-of-type {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-of-type {
      border-right: 1px solid #dfe7eb;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .currentPage {
    color: #343a46;
    font-weight: 400;
  }

  .spinner {
    margin-right: 10px;
  }
}
