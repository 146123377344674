@import '@babylon/design-tokens/all.scss';

.card {
  align-items: flex-start;
  background-color: var(--active-grey);
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.header {
  margin-bottom: 0.75rem;
}

.tag {
  background-color: $white100;
  border: 1.5px solid;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 1rem;
  padding: 0.25rem;
  text-transform: uppercase;

  &_none_collected {
    border-color: $red120;
  }

  &_partially_collected {
    border-color: $orange100;
  }

  &_all_collected {
    border-color: $green-d20;
  }
}
