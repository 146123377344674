.Messages {
  &__outcome {
    border: 1px solid var(--grey-200);
    border-radius: 8px;
    margin-top: 30px;
    margin-bottom: 16px;
    padding: 24px;
  }

  &__outcomeHeading {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;

    h3 {
      padding-right: 10px;
    }
  }
}
