.Alert__AddNew__description {
  margin: 10px 0 20px 0;
  min-width: 400px;
}

.Alert__AddNew__actions {
  display: flex;
  justify-content: flex-end;
}

.Alert__AddNew__save_button {
  margin-right: 0;
}
