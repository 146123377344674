$color-text-light: #6b7785;
$color-text-light-2: #acb9c6;
$border-color: #dfe7ec;
$teal-color: #2bbbbb;
$highlight-color: #e8f0fb;

.core-ui-gp-practice-postcode-lookup {
  &__label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 10px 0;
    padding: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: $color-text-light;

    @media (max-width: 600px) {
      flex-direction: column;
    }
  }

  &__labelText {
    display: block;
  }

  &__labelInfoText {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: $color-text-light-2;
    font-weight: 300;
  }

  &__wrapper {
    position: relative;

    &--focussed {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
    }

    &--popup-open {
      box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.12);
    }
  }

  &__inputWrapper {
    border-radius: 2px;
    border: 1px solid $border-color;
    padding: 8px 35px 8px 10px;
    background-color: white;
  }

  &__inputIcon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }

  &__input {
    display: block;
    width: 100%;
    outline: none;
    font-size: 13px;
    line-height: 1;
    border: none;
    margin: 0;
    appearance: none;
  }

  &__resultsWrapper {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 150px;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 100;
    background-color: white;
    border: 1px solid $border-color;
    border-top: none;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.12);
    display: none;

    .core-ui-gp-practice-postcode-lookup__wrapper--focussed & {
      display: block;
    }
  }

  &__resultList {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__resultItem {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $border-color;
    padding: 9px 0;

    &:last-of-type {
      border-bottom-color: transparent;
    }

    &:hover {
      cursor: pointer;
      background-color: $highlight-color;
    }
  }

  &__resultText {
    display: block;
    padding: 0 10px;
    font-size: 13px;
    line-height: 18px;

    &--name {
      width: 30%;
    }

    &--postcode {
      width: 20%;
    }

    &--address {
      width: 50%;
    }
  }

  &__message {
    display: block;
    padding: 9px 10px;
    color: $color-text-light;
    font-size: 14px;
    line-height: 18px;
  }

  &__loaderWrapper {
    padding: 15px;
    text-align: center;
  }

  &__loader {
    color: $teal-color;
    font-size: 15px;
    text-indent: -9999em;
    overflow: hidden;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin: 15px auto;
    position: relative;
    transform: translateX(-5px) translateZ(0);
    animation: postcode-lookup-loader-animation 1.7s infinite ease,
      postcode-lookup-loader-round-animation 1.7s infinite ease;
  }

  &__loadingMessage {
    display: block;
    padding: 9px 10px;
    color: $color-text-light;
    font-size: 14px;
    line-height: 18px;
  }
}

@keyframes postcode-lookup-loader-animation {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }

  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }

  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }

  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes postcode-lookup-loader-round-animation {
  0% {
    transform: translateX(-5px) rotate(0deg);
  }

  100% {
    transform: translateX(-5px) rotate(360deg);
  }
}
