@import '../constants.scss';

.show-password {
  width: auto;
  height: 23px;
  padding: 0;

  position: absolute;
  right: 0;
  top: -3px;

  background-color: transparent;
  border: 0;
  outline: 0;

  line-height: 20px;
  color: $icon-color;

  cursor: pointer;
}

.input-wrapper svg {
  position: absolute;
  right: 16px;
  bottom: 16px;
}

.input--loading {
  padding-right: 48px;
}
