//TODO - use color variables in here
.core-tag {
  display: inline-block;
  margin: 0 4px 4px 0;
  padding: 0 5px;
  height: auto;
  border: 0;
  border-radius: 3px;

  font-size: 10px;
  line-height: 16px;
  font-weight: 600;
  white-space: nowrap;
  text-transform: uppercase;

  list-style: none;
  align-self: baseline; //For when parent is flex

  button {
    color: currentColor;

    svg path {
      fill: currentColor;
    }
  }

  &--colored {
    color: #fff;
  }

  &--no-uppercase {
    text-transform: none;
  }

  &--no-margin {
    margin: 0;
  }

  &__close-button {
    appearance: none;
    border: none;
    background-color: transparent;
    padding: 0;
    outline: none;
    opacity: 0.8;
    margin: 0 0 0 4px;
    line-height: 1;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;

    &:hover {
      opacity: 1;
    }

    &:active {
      opacity: 0.9;
    }

    &__icon {
      display: inline-block;
      width: 12px;
      height: 10px;
    }
  }

  &--style-clinical-blue {
    background-color: #e9f0fa;
    color: #3861bf;
  }

  &--style-clinical-red {
    background-color: rgba(255, 71, 67, 0.15);
    color: #ff4743;
  }

  &--style-clinical-green {
    background-color: #e0f5f5;
    color: #2bbbbb;
  }

  &--style-clinical-orange {
    background-color: rgba(#ffa800, 0.15);
    color: #ffa800;
  }

  &--style-white-on-orange {
    background-color: #ff9e01;
    color: white;
  }

  &--style-highlight-violet {
    background-color: var(--tag-highlight-violet-color);
    color: var(--tag-highlight-violet-text-color);
  }

  &--style-white-on-green {
    background-color: #2bbbbb;
    color: #fff;
  }

  &--style-green-on-white {
    background-color: #fff;
    color: #2bbbbb;
  }

  &--style-white-on-type-grey {
    background-color: #637280;
    color: #fff;
  }

  &--style-light-teal {
    background-color: #d9fbfc;
    color: #282824;
    border: 1px solid #282824;
  }

  &--style-white-on-light-grey {
    background-color: var(--grey-50);
    color: #fff;
  }

  &--style-white-on-red {
    background-color: #f17d7a;
    color: #fff;
  }

  &--style-white-on-black {
    background-color: #333;
    color: #fff;
  }

  &--style-black-on-grey {
    background-color: rgba(99, 114, 128, 0.12);
    color: #353a46;
  }

  &--style-reverse-grey {
    background-color: rgba(99, 114, 128, 0.12);
    color: #637280;
  }

  &--style-confirmation-green {
    background-color: #e4fae8;
    color: var(--green-100);
  }
}
