.ModalContent {
  width: 464px;

  & > p {
    width: 368px;
  }

  & > footer {
    margin-top: 40px;
  }

  &__closeButton {
    align-self: flex-end;
    background-color: var(--active-grey);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    height: 40px;
    padding: 0;
    width: 40px;
  }

  &__Action {
    min-width: 112px;

    &:first-child {
      margin-right: 16px;
    }
  }
}
