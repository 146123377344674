.title {
  margin-bottom: 2rem;
}

.description {
  margin-bottom: 1.5rem;
}

.acceptDescription {
  margin-bottom: 1rem;
}

.acceptTitle {
  margin-bottom: 0.25rem;
}

.checkbox {
  margin-bottom: 1.25rem;
}

.buttons {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-top: 1.75rem;
}
