:global(.modalOpen) {
  overflow: hidden;
}

.modalOverlay {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: var(--zindex-overlay);
  background: rgba(255, 255, 255, 0.5);
}

.popupDialog {
  width: auto;
  height: 100%;
  padding: 20px;
  padding-right: 40px;
}

.popup {
  display: none;
  opacity: 0;
  position: fixed;
  width: 50%;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  border: 1px solid var(--shadow-edge);
  box-shadow: 0 4px 9px var(--shadow);
  overflow-y: auto;
  transition: all 0.25s ease-out;

  > div {
    padding: 30px;
  }
}

.popup-enter {
  opacity: 0;
  display: block;
  transform: translateX(200px);
}

.popup-enter-done {
  display: block;
  opacity: 1;
}

.popup-exit {
  transition: all 0.25s ease-in;
  display: block;
  opacity: 0;
  transform: translateX(200px);
}

.popup-exit-done {
  display: none;
  opacity: 0;
}
