.Banner {
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

.BannerButton {
  flex: 1;
  cursor: pointer;
  text-align: left;
  color: var(--white);
  border: none;
  background-color: var(--transparent);
  align-self: stretch;
  padding-left: 15px;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50rem;
  background: #f7fafb;
  display: flex;
  flex-direction: column;
  padding: 3rem;
}

.divider {
  margin-bottom: 1rem;
}

.textFieldWrapper {
  padding: 1rem 0;
}

.formLabel {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
}

.buttonWrapper {
  padding-top: 1.5rem;
  margin-left: auto;
}

.fillContent {
  width: 100%;
}

.pharmaciesDataGrid {
  width: 95%;
  height: 100%;
  margin: auto;
}

.flexRowContainer {
  display: flex;
  flex-direction: row;
}

.filterInputContainer {
  display: flex;
  flex: 1;

  > .textField {
    margin: 8px;
  }
}

.flexContainer {
  display: flex;
  flex: 1;
}

.filterInputLabel {
  margin: 4px;
}

.accordionDetails {
  padding: 0 0.5rem 1rem;
  display: flex;
}

.pharmaciesTitle {
  padding: 1rem;
}
