.errorMessage {
  position: fixed;
  color: #ff4743;
  border-radius: 3px;
  text-align: center;
  top: 40%;
  left: 0;
  padding-left: 250px;
  width: 100%;
}

.spinnerContainer {
  height: calc(100vh - 45px);
}
