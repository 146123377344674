.EntriesHistory {
  &__wrapper {
    margin-top: 48px;
  }
}

.ConversationActions {
  margin: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top: 1px solid var(--grey-110);

  &__printButton {
    background-color: var(--dark-purple);

    &:hover {
      background-color: var(--dark-purple);
    }
  }
}
