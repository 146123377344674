@import '../../../../styles/variables.scss';

.consultationGrid {
  width: 100%;
}

.groupTitle {
  margin-bottom: 8px;
}

.emailLink {
  padding: 0;
  font-size: 16px;
  line-height: 20px;
  word-break: break-word;
}
