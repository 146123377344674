@import '../global-constants';

$toggle-background: var(--medkit-toggle-background, $surface);
$toggle-border-weight: var(--medkit-toggle-border-weight, 2px);
$toggle-border-color: var(--medkit-toggle-border-color, $slategrey);
$toggle-size: var(--medkit-toggle-size, 30px);
$toggle-border-radius: var(
  --medkit-toggle-border-radius,
  calc(
    (#{$toggle-size} + #{$toggle-border-weight} / 2) - #{$toggle-border-weight}
  )
);
$toggle-height: calc(#{$toggle-size} + #{$toggle-border-weight});
$toggle-width: calc(#{$toggle-size} * 1.8);
$toggle-padding: var(--medkit-toggle-padding, 0);

$toggle-checked-background: var(
  --medkit-toggle-checked-background,
  var(--medkit-primary, $primary)
);
$toggle-disabled-background: var(--medkit-toggle-disabled-background, $surface);

$toggle-switch-background-color: var(
  --medkit-toggle-switch-background-color,
  $background
);
$toggle-switch-size: var(
  --medkit-toggle-switch-size,
  calc(#{$toggle-size} - #{$toggle-border-weight})
);

$toggle-switch-position-checked: calc(
  100% - (#{$toggle-size} - #{$toggle-border-weight})
);

$toggle-switch-position-checked-hover: calc(
  90% - (#{$toggle-size} - #{$toggle-border-weight})
);

$toggle-switch-position-disabled: var(
  --medkit-toggle-switch-position-disabled,
  0
);

.toggle {
  position: relative;
  width: $toggle-width;
  height: $toggle-height;
  padding-top: 0;
  padding-right: $toggle-border-weight;
  padding-bottom: 0;
  padding-left: $toggle-border-weight;
  border: 0;
  border-radius: $toggle-border-radius;
  overflow: hidden;
  background: transparent;
  box-shadow: inset 0 0 0 $toggle-border-weight $toggle-border-color;
  cursor: pointer;

  &[disabled] {
    background: $toggle-disabled-background;
  }

  &__switch {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: $toggle-switch-size;
    height: $toggle-switch-size;
    border-radius: $toggle-border-radius;
    background-color: $toggle-switch-background-color;
    box-shadow: 0 1px 4px 0 $toggle-border-color,
      0 3px 8px 0 $toggle-border-color;
    transition: left 280ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

    [aria-checked='false']:hover:not(:focus):not([disabled]) & {
      left: 10%;
      transition-duration: 400ms;
    }

    [aria-checked='true'] & {
      left: $toggle-switch-position-checked;
    }

    [aria-checked='true']:hover:not(:focus):not([disabled]) & {
      left: $toggle-switch-position-checked-hover;
    }

    [disabled] & {
      left: $toggle-switch-position-disabled;
    }

    &::before {
      position: absolute;
      top: 0;
      right: 50%;
      z-index: -1;
      display: block;
      width: 250%;
      height: 100%;
      background-color: $toggle-checked-background;
      content: '';
    }
  }
}
