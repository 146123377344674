.ConversationFilter {
  background-color: var(--active-grey);
  display: flex;
  margin-bottom: 10px;
}

.ConversationFilter_item {
  background-color: var(--white);
  padding: 8px;
  margin: 10px;
  height: 26px;
  border-radius: 38px;
  display: flex;
  align-items: center;
}

.ConversationFilter_link {
  font-size: 14px;
  color: var(--text);
  text-decoration: none;

  &:hover {
    color: var(--text);
  }
}

.ConversationFilter_item_active {
  background-color: var(--babylon-violet);
  padding: 8px;
  margin: 10px;
  height: 26px;
  border-radius: 38px;
  display: flex;
  align-items: center;
}

.ConversationFilter_link_active {
  font-size: 14px;
  color: var(--white);
  text-decoration: none;

  &:hover {
    color: var(--white);
  }
}

.conversation__viewMore {
  margin-bottom: 25px;
}
