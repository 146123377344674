$text-color-active: var(--babylon-violet);

.wrapper {
  display: flex;
  align-items: center;
  column-gap: 12px;
  line-height: 1;
}

.toggleSwitch {
  --medkit-toggle-border-color: var(--babylon-violet);
  --medkit-toggle-size: 24px;

  &[disabled] {
    --medkit-toggle-border-color: var(--grey-200);
    cursor: not-allowed;
  }
}

.active {
  color: $text-color-active;
}

.timezone {
  font-size: 12px;
  line-height: 1em;
  text-align: right;
  min-height: 1em;
}
