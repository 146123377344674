@import '@babylon/design-tokens/all.scss';

.toggleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: none;
  margin-bottom: 1.75rem;

  .toggleOn {
    background-color: var(--border-success-green);
    border-color: var(--border-success-green);
    box-shadow: none;
    position: relative;
  }

  .toggleOn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0.125rem;
    height: 0.75rem;
    background-color: var(--white);
    margin-left: 0.8rem;
    margin-top: 0.6rem;
  }

  .toggleOff {
    background-color: var(--neutral-grey);
    border-color: var(--neutral-grey);
  }

  .toggleOff::before {
    content: '';
    position: absolute;
    width: 0.75rem;
    height: 0.75rem;
    border: 0.125rem solid var(--white);
    border-radius: 6rem;
    top: 0;
    right: 0;
    margin-right: 0.4rem;
    margin-top: 0.6rem;
  }
}
