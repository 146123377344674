.dim {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog {
  background: #fff;
  display: flex;
  flex-direction: column;
  width: 600px;
  padding: 30px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.5);
  margin-bottom: 10%;
  border-radius: 4px;
  text-align: center;
}

.buttonRow {
  justify-content: center;

  button {
    width: 120px;
    margin: 0 5px;
  }
}
