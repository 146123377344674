.popup {
  transition: transform 5s ease-in;
  display: block;
  position: fixed;
  width: 50%;
  top: 0;
  right: 0;
  bottom: 0;
  background: #f8fafb;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
  overflow-y: auto;

  > div {
    padding: 30px 50px 30px 30px;
  }
}

.exited .popup,
.exiting .popup {
  transform: translateX(100%) translateX(20px);
}

.entered .popup,
.entering .popup {
  transform: translateX(20px);
}

.exiting .popup,
.entering .popup {
  transition: transform 0.3s ease-in;
}

.exited .dim,
.exiting .dim {
  opacity: 0;
  pointer-events: none;
}

.entered .dim,
.entering .dim {
  opacity: 1;
}

.exiting .dim,
.entering .dim {
  transition: opacity 0.3s ease-in;
}
