.drugEdit {
  :global {
    .widget-name {
      display: inline-block;
      width: calc(100% - 112px);
      margin-right: 20px;
    }

    .widget-generic {
      display: inline-block;
    }

    .widget-pharmaceuticalForm {
      display: inline-block;
      vertical-align: top;
      width: calc(50% - 10px);
    }
    .widget-strength {
      display: inline-block;
      width: calc(50% - 10px);
      margin-left: 20px;
    }
  }
}

.detailsView {
  display: flex;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  background: #fff;

  ul {
    flex-grow: 1;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 200px;
    background: #f8fafb;
    overflow: hidden;
    overflow-y: auto;
    border-top-left-radius: 3px;
  }

  li {
    background: #fff;
    list-style: none;
    margin: 0;
    padding: 15px;
    border-bottom: 1px solid #dfe7eb;
    position: relative;
    cursor: default;
    font-size: 14px;
    font-weight: 300;

    &:first-of-type {
      border-top-left-radius: 3px;
    }

    &:after {
      content: '\f054';
      font-family: Icons;
      display: block;
      font-size: 16px;
      position: absolute;
      height: 20px;
      line-height: 20px;
      top: calc(50% - 10px);
      right: 10px;
      color: #637280;
    }

    &:hover {
      background: #f8fafb;

      &:after {
        color: darken(#637280, 10);
      }
    }
  }

  :global {
    li.selected {
      background-color: #e9f0fb;
    }

    .list-wrapper {
      display: flex;
      border-right: 1px solid #dfe7eb;
      flex-direction: column;
    }

    .toolbar-wrapper {
      margin-top: -1px;
      height: 30px;
      background: #fff;
      padding: 3px 0 0 15px;
      border-top: 1px solid #dfe7eb;
      border-bottom-left-radius: 3px;

      button {
        white-space: nowrap;
        margin: 0;
        padding: 0;
        background: transparent;
        border: 0;
        font-size: 12px;
        font-weight: 200;
        line-height: 2em;
        color: #919191;
        cursor: pointer;
        display: block;

        i {
          color: #666;
        }

        &:hover {
          color: #484848;
          i {
            color: #484848;
          }
        }

        &:active {
          color: #000;
          i {
            color: #000;
          }
        }
      }
    }

    .details-wrapper {
      flex-grow: 1;
      background-color: #f8fafb;
      padding: 30px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
}
