$spinner-size-base: 16px;

$spinner-size-sm: $spinner-size-base;
$spinner-size-md: $spinner-size-base * 1.5;
$spinner-size-lg: $spinner-size-base * 2;

.core-spinner {
  display: block;

  &__body {
    display: block;
    position: relative;
    animation-iteration-count: infinite;
    animation-name: infinite-spinning;
    animation-timing-function: linear;
    align-items: stretch;
    animation-duration: 0.75s;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    circle {
      stroke: var(--spinner-color);
    }
  }

  &--small &__body {
    width: $spinner-size-sm;
    height: $spinner-size-sm;
  }

  &__body,
  &--medium &__body {
    width: $spinner-size-md;
    height: $spinner-size-md;
  }

  &--large &__body {
    width: $spinner-size-lg;
    height: $spinner-size-lg;
  }

  &__background-circle {
    opacity: 0.2;
  }

  &__foreground-circle {
    stroke-dasharray: 80;
    stroke-dashoffset: 60;
    opacity: 1;
  }

  &--centered {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}

@keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
