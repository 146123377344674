.listView {
  width: 100%;
  border-collapse: collapse;

  th {
    > div {
      white-space: nowrap;
      margin-top: 6px;
    }

    input {
      margin: 0;
      padding: 6px;
      border: 1px solid green;
      border-radius: 4px;
      outline: 0;
      box-sizing: border-box;
    }
  }

  th,
  td {
    vertical-align: top;
    padding: 5px 20px;
    border-bottom: 1px solid #dfe7eb;
    font-size: 16px;
    text-align: left;
  }

  td {
    font-weight: 300;
  }

  .sortButton {
    border: 0;
    margin: 0;
    padding: 0;
    margin-left: 10px;
    color: #919191;

    &:hover {
      color: #484848;
    }

    &:active {
      color: #000;
    }
  }

  .spinner {
    margin-left: 10px;
  }

  .hidden {
    visibility: hidden;
  }

  td.actions {
    width: 1%;
  }

  div.actions {
    button {
      white-space: nowrap;
      margin: 0;
      padding: 0;
      background: transparent;
      border: 0;
      font-size: 12px;
      font-weight: 200;
      line-height: 2em;
      color: #919191;
      cursor: pointer;
      display: block;

      i {
        color: #666;
      }

      &:hover {
        color: #484848;
        i {
          color: #484848;
        }
      }

      &:active {
        color: #000;
        i {
          color: #000;
        }
      }
    }
  }
}
