@import '../BaseInput/BaseInput.variables';
@import '../../global-mixins.scss';

.Input {
  &__icon {
    padding: 2px;
    border: 0;
    background: transparent;
    cursor: pointer;
    display: flex;

    &:focus {
      @include medkitFocusOutline;
    }
  }

  &__iconImage {
    fill: $label-color;
  }
}
