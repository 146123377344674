.core-ui-tooltip__svg {
  display: block;
  font-size: inherit;
}

.core-ui-tooltip__icon-wrapper {
  display: inline-flex;
}

.rc-tooltip {
  opacity: 1;
  background-color: transparent;
  pointer-events: none;
  transition: opacity 100ms;
  max-width: 300px;

  * {
    // stylelint-disable-next-line
    color: #fff !important;
  }

  .rc-tooltip-inner {
    padding: 6px 10px;
    background-color: var(--tooltip-background);
    border: 0;
    min-height: auto;
    border-radius: 4px;
    font-size: 12px;
    line-height: 1.5;
    font-weight: 500;

    &,
    * {
      white-space: normal;
      font-size: 14px;
    }
  }
}

.rc-tooltip-hidden {
  opacity: 0;
}

.tooltip-fade-enter {
  opacity: 0;
}

.tooltip-fade-leave {
  opacity: 1;
}

.tooltip-fade-enter-active {
  opacity: 1;
}

.tooltip-fade-leave-active {
  opacity: 0;
}

.rc-tooltip-placement-top .rc-tooltip-content {
  position: relative;
  top: -5px;

  .rc-tooltip-arrow {
    border-top-color: var(--tooltip-background);
  }
}

.rc-tooltip-placement-left .rc-tooltip-content {
  position: relative;
  left: -5px;

  .rc-tooltip-arrow {
    border-left-color: var(--tooltip-background);
  }
}

.rc-tooltip-placement-right .rc-tooltip-content {
  position: relative;
  left: 5px;

  .rc-tooltip-arrow {
    border-right-color: var(--tooltip-background);
  }
}

.rc-tooltip-placement-bottom .rc-tooltip-content {
  position: relative;
  top: 5px;

  .rc-tooltip-arrow {
    border-bottom-color: var(--tooltip-background);
  }
}
