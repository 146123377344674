.Transactions {
  padding: 30px;

  &__heading {
    padding: 8px 16px;
    color: var(--grey-600);
  }

  &__spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }
}

.ButtonGroup {
  display: flex;
  > * {
    margin-right: 10px;
  }
}

.Button {
  &__blue {
    background-color: var(--babylon-violet);
  }
  &__white {
    background-color: var(--white);
    border-color: var(--babylon-violet);
    color: var(--babylon-violet);
  }
}
