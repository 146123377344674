@import '@babylon/design-tokens/all.scss';

.snackbar {
  z-index: 10001;
}

.icon {
  fill: $white100;
  margin-right: 1rem;
}
