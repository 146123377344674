@import 'constants.scss';

:global {
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  .control-wrapper {
    padding: 0;
    margin: 0;
    @include font();
  }

  .label {
    display: block;
    width: 100%;
    margin-bottom: 8px;
    font-size: 14px;

    &--fixed {
      position: static;
      top: 0;
      left: 0;
    }
  }

  .control {
    height: 48px;

    width: 100%;

    padding-top: 0;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 0;

    font-size: 16px;

    border-width: 1px;
    border-style: solid;
    border-color: $secondary-light;
    border-radius: $border-radius;

    box-sizing: border-box;

    @include font(300);

    &:focus {
      outline: none;
      border-color: $focus-border;
    }

    &--error {
      border-color: $error;
    }

    &--success {
      border-color: $success;
    }

    /* Avoid native red shadow with invalid form fields */
    &:invalid {
      box-shadow: none;
    }

    &:-moz-submit-invalid {
      box-shadow: none;
    }

    &:-moz-ui-invalid {
      box-shadow: none;
    }
  }
}
