.Experiments {
  padding: 10px 20px;

  .Heading {
    margin-bottom: 20px;
  }

  .ExperimentName {
    text-align: left;
  }
}
