.AppointmentCredits {
  & > .AppointmentCreditEntries {
    margin-bottom: 0;
    padding: 0 0 0 40px;
  }

  & > .AppointmentCreditActions {
    border-top: 1px solid var(--grey-110);
    display: flex;
    flex-direction: row-reverse;
    margin: 48px 0 0 40px;
    padding: 40px 0;
  }
}

.TableHeading {
  color: var(--neutral-grey);
  padding: 0 16px 8px;
}

.AppointmentCreditEntry {
  border: 1px solid var(--grey-110);
  border-radius: 8px;
  margin: 8px 0;
  padding: 16px;

  &:last-child {
    margin: 16px 0 0;
  }

  &__ToggleDetails {
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 7px; // width of the svg
    width: 24px;

    // visually hidden text, picked up by the screen-reader
    & > span {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }
  }

  &__Details {
    background: var(--active-grey);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    max-height: 0;
    overflow: hidden;
    padding: 0 16px;
    transition: all 0.15s linear, margin 0.15s linear;

    & > h3 {
      margin-bottom: 8px;
    }

    &Actions {
      align-self: flex-end;
      margin-top: 32px;
    }

    &Remove,
    &Remove:hover {
      &::before {
        background: var(--white);
      }
    }

    &Expanded {
      margin-top: 16px;
      max-height: 300px;
      padding: 16px;
    }
  }
}

.AddCreditButton {
  margin-top: 10px;
}
