.core-popover {
  display: block;
  opacity: 1;
  position: relative;
  background-color: #f8fafb;
  border: solid 1px rgba(223, 231, 235, 0.7);
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
  width: 100%;

  &.core-popover--with-decoration {
    &::after,
    &::before {
      z-index: 2;
      bottom: 100%;
      left: 30px;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &::after {
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: #f8fafb;
      border-width: 10px;
      margin-left: -10px;
    }

    &::before {
      border-color: rgba(194, 225, 245, 0);
      border-bottom-color: #dfe7eb;
      border-width: 11px;
      margin-left: -11px;
    }
  }
}
