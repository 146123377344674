.Comment {
  grid-template-columns: 24px auto;
  grid-column-gap: 12px;
  margin-bottom: 16px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  &__header {
    color: var(--text-color);
    line-height: 22px;
  }
}

.Comment__ViewMore {
  grid-column-start: 2;
}

.Comment__modal {
  min-width: 500px;
  min-height: 70vh;
}

.Comment__list {
  overflow: auto;
  margin-bottom: 10px;
  height: 60vh;
}

.Comments__wrapper {
  margin: 0 0 12px 12px;
  position: relative;
}

.Comment__wrapper {
  border-radius: 12px;
  background-color: var(--off-white);
  padding: 12px;
  width: 100%;
}

.Comment__icon {
  fill: var(--light-grey-color);
  margin-bottom: 16px;
}

.Comment__timeRelative {
  font-weight: 900;
  margin-right: 4px;
}

.Comment__message {
  line-height: 1.13;
  margin: 8px 0 16px;
}

.Comment__AddNew__description {
  margin: 10px 0 20px 0;
  min-width: 400px;
}

.Comment__AddNew__actions {
  display: flex;
  justify-content: flex-end;
}

.Comment__AddNew__cancel_button {
  margin-right: 5px;
}

.Comment__timeline {
  align-items: center;
  flex-shrink: 0;
  margin-right: 10px;
  margin-top: 0;

  &::before {
    background: rgba(99, 114, 128, 0.15);
    bottom: 0;
    content: '';
    left: 12px;
    position: absolute;
    top: 0;
    width: 1px;
  }

  &__iconWrapper {
    position: relative;
    background: var(--white);
  }
}

.loadingBlock {
  border-radius: 8px;
  height: 168px;
}
