@import '../constants.scss';

.input-wrapper {
  padding: 0;
  margin: 0;
  @include font();
}

.label {
  display: block;
  width: 100%;
  margin-bottom: 16px;
}

.textarea {
  min-height: 84px;

  width: 100%;

  line-height: 1.4;

  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;

  @include font(300);
  font-size: 16px;

  border-width: 1px;
  border-style: solid;
  border-color: #b7bfcc;
  border-radius: $border-radius;
  resize: vertical;

  box-sizing: border-box;

  &.autoresize {
    overflow: hidden;
    resize: none;
  }

  &:focus {
    outline: none;
    border-color: $focus-border;
  }

  /* Avoid native red shadow with invalid form fields */
  &:invalid {
    box-shadow: none;
  }

  &:-moz-submit-invalid {
    box-shadow: none;
  }

  &:-moz-ui-invalid {
    box-shadow: none;
  }

  &--error {
    border-color: $error;
  }

  &--success {
    border-color: $success;
  }
}
