@import '../../global-constants';

$background-color: var(--medkit-primary, $primary);
$background-color-hover: var(--medkit-primary-light, $primary-light);
$radio-disabled: var(--medkit-primary-disabled, $grey-200);

$radio-color--inactive: $grey-600;
$radio-color--checked: $background-color;
$radio-button--outline: $background-color-hover;
$radio-margin: rem-calc(12);
$radio-border: rem-calc(4);
$radio-dimension--visible-size: rem-calc(40);
$radio-dimension--touch-target: rem-calc(48);

.Radio {
  display: flex;
  align-items: flex-start;
  font-family: $font;
  cursor: pointer;
  line-height: var(--medkit-text-body-line-height, 1.5rem);
  flex-wrap: wrap;
  margin-bottom: $radio-margin;
  position: relative;
  margin-left: -#{$radio-border};

  &:last-child {
    margin-bottom: 0;
  }

  *,
  *::before,
  *::after {
    box-sizing: content-box;
  }

  &:hover {
    cursor: pointer;
  }

  &_checked,
  &_checked:hover {
    .Radio__circle {
      border-width: rem-calc(4);
      border-color: $radio-color--checked;

      &::after {
        background-color: $radio-color--checked;
      }
    }

    &.Radio_disabled .Radio__circle {
      border-color: $radio-disabled;
    }
  }

  &_disabled,
  &_disabled:hover {
    .Radio__circle {
      border-color: $radio-disabled;
      background-color: transparent;
    }
  }

  &_bordered {
    color: $black100;
    display: flex;
    align-items: center;
    min-height: 3.625rem;
    padding: 0.5rem 1rem;
    border: 1px solid $grey-500;
    border-radius: 0.5rem;
    font-size: 1.0625rem;
    font-family: 'Visuelt', sans-serif;
    font-weight: 400;
    line-height: 1.3;
    margin-top: 0.5rem;
    text-align: left;
    background-color: $light-gray;
    cursor: pointer;
    transition: 150ms ease-in-out;
    transition-property: all;
    transition-property: background, color, opacity, -webkit-filter;
    transition-property: background, color, opacity, filter;
    transition-property: background, color, opacity, filter, -webkit-filter;
    will-change: background, color, opacity, filter;

    &:hover {
      background-color: $solitude;
    }
  }

  &__wrapper {
    height: $radio-dimension--touch-target;
    width: $radio-dimension--touch-target;
    position: relative;
  }

  &__input {
    height: $radio-dimension--touch-target;
    width: $radio-dimension--touch-target;
    margin: 0;
    appearance: none;
    border-radius: calc(#{$radio-dimension--touch-target} / 2);
    box-sizing: border-box;

    &:focus {
      outline: none;
      border: solid rem-calc(4) $radio-button--outline;

      + .Radio__circle {
        border-color: $radio-color--checked;
        border-width: rem-calc(4);
      }
    }
  }

  &__circle {
    position: absolute;
    left: $radio-border;
    top: $radio-border;
    flex-shrink: 0;
    border-radius: calc(#{$radio-dimension--visible-size} / 2);
    width: $radio-dimension--visible-size;
    height: $radio-dimension--visible-size;
    border: rem-calc(2) solid $radio-color--inactive;
    background-color: $sterile-white;
    box-sizing: border-box;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      width: calc(#{$radio-dimension--visible-size} / 2);
      height: calc(#{$radio-dimension--visible-size} / 2);
      display: block;
      content: '';
      background-color: $sterile-white;
      border-radius: calc(#{$radio-dimension--visible-size} / 4);
    }
  }

  &__label {
    padding: rem-calc(12) 0;
    margin-left: $space--m;
    max-width: calc(100% - #{$radio-dimension--touch-target} - #{$space--m});
  }
}
