.fileInputButton {
  align-items: center;
  display: flex;

  &--disabled {
    cursor: not-allowed;
    opacity: 0.4;
    pointer-events: none;
  }
}

.input {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  top: 0;
  left: 0;
}

.label {
  width: 1.25rem;
  height: 1.25rem;
  display: block;
  cursor: pointer;
}
