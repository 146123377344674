.core-ui-filter-summary {
  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1050px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  &__right {
    display: flex;
    flex-direction: row;

    @media (max-width: 1050px) {
      width: 100%;
      justify-content: space-between;
      margin-top: 15px;
    }
  }

  &__rowsPerPageWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__rowsPerPageText {
    display: block;
    margin-right: 10px;
    color: var(--core-ui-filter-summary-show-per-page-text-color);
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
  }

  &__pagination {
    margin-left: 15px;
  }
}
