.errorBlock {
  background: var(--grey-10);
  border: 1px solid rgba(255, 59, 48, 0.4);
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 10px;

  &__heading {
    color: var(--error-color);
  }

  &__code {
    background: #fff;
    border: 1px solid var(--card-border);
    color: var(--grey-100);
    padding: 12px;
  }

  &__nextSteps {
    font-weight: 600;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
