.privacySettingItem {
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 1rem;
}

.spinnerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1rem;
}

.ModalLink {
  border-radius: 1rem;
  padding: 1rem;
  margin-top: 1rem;

  button {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
