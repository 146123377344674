.guestTag {
  max-width: 200px;
  font-weight: 900;
  line-height: 16px;
  margin-bottom: 0;
  margin-right: 8px;
  background-color: var(--clinical-orange-light);
  color: var(--clinical-orange);
  border-color: var(--clinical-orange);
}
