@import '../../../util/constants';

.form {
  min-height: 100%;

  :global(.is-mobile) & {
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  > p {
    margin-top: 0;
  }

  & > * {
    margin-bottom: 1rem;
  }
}

.field {
  display: block;
  padding: 0;

  :global .babylon-component-library__asFormControl__errors__item {
    font-size: 12px;
    margin-top: 5px;
    letter-spacing: normal;
  }

  :global .babylon-component-library__asFormControl__errors {
    margin: 10px 0 0 0;
  }

  :global .babylon-component-library__asFormControl__control__tip {
    font-size: 12px;
    margin-top: 5px;
    color: #353a46;
    font-weight: 300;
  }

  :global .control-wrapper > label,
  :global .input-wrapper > label {
    font-size: 14px;
    display: block;
    margin-bottom: 5px;
    font-weight: 300;
  }

  span:first-child {
    font-size: 14px;
    display: block;
    margin-bottom: 5px;
    font-weight: 300;
  }
}

p.field {
  margin: 0;
  white-space: pre-wrap;
}

.error {
  margin: 0 0 10px;
  display: block;
  letter-spacing: normal;
  color: $error;
  font-size: 14px;
  text-align: left;
}
