.core-snackbar-transition--enter {
  opacity: 0;
}

.core-snackbar-transition--enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.core-snackbar-transition--exit {
  opacity: 1;
}

.core-snackbar-transition--exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.core-snackbar.core-snackbar-transition--exit-done {
  display: none;
}

.core-snackbar {
  display: flex;
  align-items: center;
  min-width: 300px;
  max-width: calc(100vw - 16px);
  overflow-wrap: break-word;
  min-height: 45px;
  position: absolute;
  border-radius: 4px;
  font-size: 14px;
  margin: 20px;
  z-index: 1000;
  padding: 12px;
}

.core-snackbar--center {
  left: 50%;
  transform: translate(-50%, 0);
  margin: 20px 0;
}

.core-snackbar--left {
  left: 0;
}

.core-snackbar--right {
  right: 0;
}

.core-snackbar--top {
  top: 0;
}

.core-snackbar--bottom {
  bottom: 0;
}

.core-snackbar--fill {
  left: 0;
  right: 0;
  min-width: 0;
  transform: translate(0, 0);
}

.core-snackbar--fixed {
  position: fixed;
}

.core-snackbar--primary {
  background-color: var(--primary-color);
  color: white;
}

.core-snackbar--secondary {
  background-color: white;
  border: 1px solid var(--button-secondary-border-color);
  color: black;

  .core-snackbar__close-svg {
    path {
      fill: currentColor;
    }
  }
}

.core-snackbar--warning {
  background-color: var(--warning-color);
  color: white;
}

.core-snackbar--error {
  background-color: var(--error-color);
  color: white;
}

.core-snackbar--success {
  background-color: var(--success-color);
  color: white;
}

.core-snackbar__icon {
  display: flex;
  margin-right: 8px;
}

.core-snackbar__message {
  margin-right: 12px;
  font-weight: bold;
  overflow: auto;
}

.core-snackbar__children {
  margin-right: 12px;
  overflow: auto;
}

.core-snackbar__close-button {
  background-color: transparent;
  border: none;
  outline: none;
  color: inherit;
  padding: 0;
  margin-left: auto;
  cursor: pointer;
  height: 19px;
  width: 19px;
}

.core-snackbar__close-svg {
  width: 19px;
  height: 19px;

  path {
    fill: currentColor;
  }
}
