@mixin createInputIntentClass($intent) {
  .core-input--#{$intent} {
    border: 1px solid var(--input-#{$intent}-border-color);

    &.core-input--focused {
      box-shadow: 0 0 1px 2px var(--input-#{$intent}-active-outline-color);
    }
  }
}

.core-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 260px;
  font-size: 14px;
  border: 1px solid var(--input-border-color);
  border-radius: 3px;
  color: var(--input-color);
  background: var(--input-background);

  input {
    display: block;
    flex: 1;
    width: 100%;
    font-size: 14px;
    font-stretch: 100%;
    font-weight: 400;
    letter-spacing: normal;
    background: transparent;
    margin: 0;
    outline: 0;
    border: 0;
    color: var(--input-color);

    padding: 7px;
    line-height: 16px;

    text-size-adjust: 100%;
    box-sizing: border-box;

    &::placeholder {
      // fix bug with normalize.css lineheight + placeholder
      line-height: normal;
    }
  }

  &__prefix-icon,
  &__suffix-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__prefix-icon {
    padding-left: 7px;
  }

  &__suffix-icon {
    padding-right: 7px;
  }

  .core-spinner svg circle {
    // stylelint-disable-next-line declaration-no-important
    stroke: var(--input-color) !important;
  }

  &--fill {
    width: 100%;
    max-width: 100%;
  }

  &--disabled {
    opacity: 0.6;
    pointer-events: none;

    * {
      pointer-events: none;
    }
  }

  &--hover {
    border: 1px solid var(--input-hover-border-color);
  }

  &--focused {
    box-shadow: 0 0 0 2px var(--input-active-outline-color);
    border: 1px solid var(--input-active-border-color);
  }

  ::-webkit-input-placeholder {
    font-weight: 400;
    color: var(--input-placeholder-color);
  }

  ::-moz-placeholder {
    font-weight: 400;
    color: var(--input-placeholder-color);
  }

  :-ms-input-placeholder {
    font-weight: 400;
    color: var(--input-placeholder-color);
  }

  :-moz-placeholder {
    font-weight: 400;
    color: var(--input-placeholder-color);
  }
}

@include createInputIntentClass('error');
@include createInputIntentClass('warning');
@include createInputIntentClass('success');
