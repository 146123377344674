.ReferralInfo {
  display: flex;
  flex-direction: row;

  &__moreDetailsSpecialism {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__moreDetailsSpecialismCategory {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
