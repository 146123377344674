.Banner {
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

.BannerButton {
  flex: 1;
  cursor: pointer;
  text-align: left;
  color: var(--white);
  border: none;
  background-color: var(--transparent);
  align-self: stretch;
  padding-left: 15px;
}
