.core-divider {
  display: block;
  position: relative;
  width: 100%;
  background: transparent;
  text-align: center;
  font-size: 14px;

  span {
    position: relative;
    background: #fff;
    z-index: 2;
    padding: 0 1em;
    line-height: 2em;
  }

  &::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    top: 50%;
    left: 0;
    content: ' ';
    border-top: 1px solid #dfe7eb;
    z-index: 1;
  }

  &--left {
    text-align: left;

    span {
      margin-left: 3em;
    }
  }

  &--right {
    text-align: right;

    span {
      margin-right: 3em;
    }
  }

  &--no-content {
    span {
      opacity: 0;
    }
  }
}
